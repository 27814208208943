import {put, takeEvery, debug, createAction} from '../../lib'
import {REQUEST_COMPLETE} from '../state'
import {
  REQUEST_IDENTITY_WORKSPACE_COLLABORATOR_LIST_FETCH,
  REQUEST_IDENTITY_WORKSPACE_LIST_FETCH,
  REQUEST_PROJECT_LIST_FETCH,
  REQUEST_BATCH_ADHERENCE,
  REQUEST_BATCH_FETCH,
} from '..'

export const HANDLE_LIST_FETCH = 'HANDLE_LIST_FETCH'
export const doHANDLE_LIST_FETCH = createAction(HANDLE_LIST_FETCH)

export const handleListFetchActionCreators = {
  doHANDLE_LIST_FETCH,
}

export function* handleListFetch({payload}: any) {
  debug('saga*handleListFetch')

  const {fromType, fromPayload, data} = payload
  if (
    fromType === REQUEST_BATCH_ADHERENCE ||
    fromType === REQUEST_BATCH_FETCH ||
    fromType === REQUEST_IDENTITY_WORKSPACE_LIST_FETCH ||
    fromType === REQUEST_PROJECT_LIST_FETCH ||
    fromType === REQUEST_IDENTITY_WORKSPACE_COLLABORATOR_LIST_FETCH
  ) {
    if (data?.page?.next) {
      fromPayload.page = data?.page?.next
      yield put({
        type: fromType,
        payload: fromPayload,
      })
    }
  }
}

export function* handleListFetchSaga() {
  yield takeEvery(REQUEST_COMPLETE, handleListFetch)
}
