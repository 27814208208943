import ReactSelect from 'react-select'
import {lighten} from '../../lib/color_util'
import {selectTheme} from '../../store'

/* A styled select menu powered be [react-select](https://react-select.com/home),
 * read the docs for usage */
export const Select = (props) => {
  const {color, pad, fontSize} = selectTheme()
  return (
    <ReactSelect
      {...props}
      components={{
        IndicatorSeparator: () => null,
        ...props.components,
      }}
      styles={{
        singleValue: (provided, state) => ({
          ...provided,
          color: color.textIcon.secondary,
          fontSize: fontSize.h7,
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          svg: {
            fill:
              state.isFocused || state.isSelected ? color.primary : state.isDisabled ? color.disabled : color.grey_400,
          },
        }),
        container: (provided, state) => ({
          ...provided,
          outline: 'none',
          border: 'none',
        }),
        control: (provided, state) => ({
          ...provided,
          outline: `none`,
          background: state.isDisabled && color.white,
          border: `1px solid ${state.isFocused ? color.primary : color.disabled}`,
          // 'boxShadow': state.isFocused ? `0px 2px 2px ${color.primary}` : 'none',
          borderRadius: pad.small,
          height: '100%',
          ':hover': {
            border: `1px solid ${color.primary}`,
            // boxShadow: `0px 2px 2px ${color.primary}`,
            borderRadius: pad.small,
          },
        }),
        multiValue: (provided, state) => ({
          ...provided,
          background: lighten(color.primary, 40),
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          paddingRight: pad.small,
          paddingLeft: pad.small,
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          background: lighten(color.attention, 20),
        }),
        option: (provided, state) => ({
          ...provided,
          whiteSpace: 'nowrap',
          color: state.isDisabled ? color.grey_160 : state.isSelected ? color.white : color.black,
          background: state.isSelected
            ? state.isFocused
              ? lighten(color.primary, 10)
              : color.primary
            : state.isFocused
            ? lighten(color.primary, 40)
            : 'none',
          ':hover': {
            color: state.isDisabled ? color.grey_160 : color.primary,
            cursor: state.isDisabled ? 'default' : 'pointer',
          },
        }),
      }}
    />
  )
}
