import {isEmpty} from 'lodash'
import ReactGridLayout, {ReactGridLayoutProps, Layout} from 'react-grid-layout'

import {CoordinatedMatrix, MatrixIndex} from '../model/matrix'
import {getColumnNames, getRowNames, mapMatrix} from '../helper/matrix'
import {Table, TableValue} from '../model/table'
import {mapTable} from '../helper/table'
import {MatrixCoordinate} from '../model/coordinate'

type LayoutConfig = Partial<Omit<Layout, 'i' | 'x' | 'y'>>
type Child = ReactGridLayoutProps['children']

export interface GridProps<T> extends ReactGridLayoutProps {
  items: CoordinatedMatrix<T>
  itemLayout?: LayoutConfig
  renderItem: (item: T, index: MatrixIndex) => Child
  legendLayout?: LayoutConfig
  renderLegend?: (legend: string, index: MatrixIndex) => Child
}

export const Grid = <T,>(props: GridProps<T>) => {
  const {items, itemLayout, renderItem, legendLayout, renderLegend, ...gridProps} = props
  const children: Child[] = []

  if (renderLegend) {
    const key = (name: string, axis: string) => [axis, 'legend', name].join('_')
    // render row legends
    getRowNames(items).forEach((row, rowIndex) => {
      const index: MatrixIndex = {
        rowIndex,
        columnIndex: 0,
      }
      children.push(
        <div key={key(row, 'row')} data-grid={{x: index.rowIndex, y: index.columnIndex, w: 1, h: 1, ...legendLayout}}>
          {renderLegend(row, index)}
        </div>,
      )
    })
    // render column legends
    getColumnNames(items).forEach((column, columnIndex) => {
      const index: MatrixIndex = {
        rowIndex: 0,
        columnIndex,
      }
      children.push(
        <div
          key={key(column, 'column')}
          data-grid={{x: index.rowIndex, y: index.columnIndex, w: 1, h: 1, ...legendLayout}}
        >
          {renderLegend(column, index)}
        </div>,
      )
    })
  }

  // render items
  const hasLegends = !isEmpty(children)
  mapMatrix(items, (item, {rowIndex, columnIndex}) => {
    const index: MatrixIndex = {
      rowIndex: hasLegends ? rowIndex + 1 : rowIndex,
      columnIndex: hasLegends ? columnIndex + 1 : columnIndex,
    }
    return (
      <div
        key={JSON.stringify(item.coord)}
        data-grid={{x: index.rowIndex, y: index.columnIndex, w: 1, h: 1, ...itemLayout}}
      >
        {renderItem(item, index)}
      </div>
    )
  }).forEach((row) => children.push(...row))

  return <ReactGridLayout {...gridProps}>{children}</ReactGridLayout>
}

export interface DataGridProps extends ReactGridLayoutProps {
  table: Table
  cellLayout?: LayoutConfig
  renderCell: (cell: TableValue, coord: MatrixCoordinate, index: MatrixIndex) => Child
  headerLayout?: LayoutConfig
  renderHeader?: (header: string, index: MatrixIndex) => Child
}

export const DataGrid = (props: DataGridProps) => {
  const {table, cellLayout, renderCell, headerLayout, renderHeader, ...gridProps} = props
  const children: Child[] = []

  const rowNames = table.getIndex().toArray()
  const columnNames = table.getColumnNames()

  if (renderHeader) {
    const key = (name: string, axis: string) => [axis, 'header', name].join('_')
    // render row headers
    rowNames.forEach((row, rowIndex) => {
      const index: MatrixIndex = {
        rowIndex,
        columnIndex: 0,
      }
      children.push(
        <div key={key(row, 'row')} data-grid={{x: index.rowIndex, y: index.columnIndex, w: 1, h: 1, ...headerLayout}}>
          {renderHeader(row, index)}
        </div>,
      )
    })
    // render column headers
    columnNames.forEach((column, columnIndex) => {
      const index: MatrixIndex = {
        rowIndex: 0,
        columnIndex,
      }
      children.push(
        <div
          key={key(column, 'column')}
          data-grid={{x: index.rowIndex, y: index.columnIndex, w: 1, h: 1, ...headerLayout}}
        >
          {renderHeader(column, index)}
        </div>,
      )
    })
  }

  // render cells
  const hasHeaders = !isEmpty(children)
  mapTable(table, (cell, coord) => {
    const rowIndex = rowNames.indexOf(coord.row)
    const columnIndex = columnNames.indexOf(coord.column)
    const index: MatrixIndex = {
      rowIndex: hasHeaders ? rowIndex + 1 : rowIndex,
      columnIndex: hasHeaders ? columnIndex + 1 : columnIndex,
    }
    return (
      <div
        key={[coord.row, coord.column].join('_')}
        data-grid={{x: index.rowIndex, y: index.columnIndex, w: 1, h: 1, ...cellLayout}}
      >
        {renderCell(cell, coord, index)}
      </div>
    )
  }).forEach((row) => children.push(...row))

  return <ReactGridLayout {...gridProps}>{children}</ReactGridLayout>
}
