import {useState, useEffect, useMemo, Dispatch, SetStateAction, ChangeEvent} from 'react'
import {v, useForm, debug, RIF, useCurrentWorkspaceState} from '../../lib'
import {selectTheme, selectAuth, createDispatchActions, selectWorkspaces, selectRequestComplete} from '../../store'
import {Button, ButtonCancel, Textarea, LabelBox} from '..'
import {ParticipantSelected} from '../../model'
import {NotificationDefaultIcon, WarningIcon} from '../../asset/image'

const schema = v.object({
  title: v.string().optional(),
  content: v.string().optional(),
})

export interface ParticipantNotificationPopPropsT {
  notificationList: ParticipantSelected[]
  displayNotificationDiscardPop: boolean
  setDisplayNotificationPop: Dispatch<SetStateAction<boolean>>
  setDisplayNotificationDiscardPop: Dispatch<SetStateAction<boolean>>
  setDisplayNotificationInfoPop: Dispatch<SetStateAction<boolean>>
  setNotificationInfo: Dispatch<SetStateAction<{[key: string]: any}>>
}

export const ParticipantNotificationPop = (props: ParticipantNotificationPopPropsT) => {
  const {color, fontWeight} = selectTheme()

  const {
    notificationList,
    displayNotificationDiscardPop,
    setDisplayNotificationPop,
    setDisplayNotificationInfoPop,
    setDisplayNotificationDiscardPop,
    setNotificationInfo,
  } = props

  const {doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const accessToken = selectAuth()?.accessToken

  const workspacesState = selectWorkspaces()
  const { workspaceId } = useCurrentWorkspaceState()
  const role: string = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  const [msgTitle, setMsgTitle] = useState('')
  const [msgContent, setMsgContent] = useState('')

  const turnOffPushAmount: number = notificationList?.filter((item: any) => item.pushEnable === false)?.length
  const participantIdList: string[] = useMemo(() => {
    const array: string[] = []
    notificationList?.map((item) => array.push(item.participantId))
    return array
  }, [notificationList])

  const {register, handleSubmit} = useForm({schema})

  // double check
  useEffect(() => {
    if (role === 'viewer') return setDisplayNotificationPop(false)
  }, [role])

  // TODO: TS with react-hook-form
  const onSubmit = (data: any) => {
    debug('ParticipantPopup.onSubmit', {data})
    if (!participantIdList) return
    doREQUEST_PARTICIPANT_LIST_SEND_PUSH_NOTIFICATION({
      setRequestId,
      payload: {
        accessToken,
        participantIdList,
        title: msgTitle,
        content: msgContent,
      },
    })
  }

  // TODO: TS with react-hook-form
  const onError = (error: any) => {
    debug('ParticipantNotificationPop.onError', {error})
  }

  useEffect(() => {
    if (!requestComplete?.success) return
    setNotificationInfo({
      message: {
        msgTitle,
        msgContent,
      },
      response: requestComplete.payload.payload,
    })
    setDisplayNotificationPop(false)
    setDisplayNotificationInfoPop(true)
  }, [requestComplete])

  // press ESC to close pop, re-write hook cause 2 layer popup shouldn't close one time
  useEffect(() => {
    if (displayNotificationDiscardPop) return
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) setDisplayNotificationPop(false)
    }
    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
  }, [displayNotificationDiscardPop])

  return (
    <>
      {/* page container */}
      <div
        css={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',
          overflowY: 'scroll',
          background: '#00000040',
          zIndex: 102,
        }}
      >
        <main
          css={{
            margin: '50px auto',
            width: '910px',
            minHeight: '670px',
            background: color.white,
            borderRadius: '5px',
            borderShadow: '0px 4px 10px 0px #0000001A',
            display: 'flex',
          }}
        >
          {/* left */}
          <section
            css={{
              width: '31%',
              minHeight: '100%',
              background: color.green,
              padding: '50px 30px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div css={{
              position: 'absolute', 
              top: '35px', 
              left: '35px',
            }}>
              <ButtonCancel onClick={() => setDisplayNotificationPop(false)}/>
            </div>

            {/* left top */}
            <div>
              <div css={{display: 'flex', flexDirection: 'column', paddingTop: '30px', lineHeight: '1.857rem'}}>
                <div
                  css={{
                    height: '26px',
                    width: '26px',
                    marginRight: '30px',
                    display: 'inline-block',
                    marginBottom: '8px',
                  }}
                >
                  <img src={NotificationDefaultIcon} width='26' />
                </div>
                <div css={{fontSize: '20px', fontWeight: fontWeight.thick}}>
                  Send notification to {notificationList?.length} participant(s)
                </div>
              </div>
              <div
                css={{
                  fontWeight: fontWeight.regular,
                  marginTop: '20px',
                }}
              >
                The selected participants will see the notification message in the Labfront app and get a
                push-notification on their lock screen if they have notifications turned on in their phone settings.
              </div>
            </div>

            {RIF(
              turnOffPushAmount !== 0,
              <div
                css={{
                  width: '230px',
                  height: '200px',
                  borderRadius: '5px',
                  background: '#FEF6F3',
                  marginTop: '24px',
                  padding: '16px',
                }}
              >
                <img src={WarningIcon} width={20} />
                <div css={{color: color.taskOrange}}>
                  {turnOffPushAmount} participant(s) has turned off the push-notification.
                </div>
                <div css={{color: color.grey_600, marginTop: '8px'}}>
                  This means they will not get an alert on their phone, but they can still see the notification message
                  if they open the Labfront app.
                </div>
              </div>,
            )}
          </section>

          {/* Right */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            css={{
              width: '70%',
              minHeight: '100%',
              background: color.background,
              padding: '80px 50px 30px',
              borderRadius: '0 5px 5px 0',
            }}
          >
            <LabelBox
              {...{
                label: 'Title',
                leftChracters: msgTitle ? 250 - msgTitle?.length : 250,
                required: true,
              }}
            >
              <Textarea
                {...register('title')}
                style={{marginBottom: '35px'}}
                placeholder='Please write a short and concise title here'
                rows={3}
                maxLength={250}
                value={msgTitle}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setMsgTitle(e.target.value)}
              />
            </LabelBox>

            <LabelBox
              {...{
                label: 'Content',
                leftChracters: 1000 - msgContent?.length,
                required: true,
              }}
            >
              <Textarea
                {...register('content')}
                style={{marginBottom: '40px'}}
                rows={18}
                placeholder='Please write the notification content here'
                maxLength={1000}
                value={msgContent}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setMsgContent(e.target.value)}
              />
            </LabelBox>

            <div style={{display: 'flex', justifyContent: 'end'}}>
              <div
                onClick={() => setDisplayNotificationDiscardPop(true)}
                style={{
                  color: color.warning,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: fontWeight.bold,
                  cursor: 'pointer',
                  marginRight: '30px',
                }}
              >
                Discard
              </div>
              <Button type='submit' btnPadding='large' btnColor='primary' disabled={!msgTitle || !msgContent}>
                Send
              </Button>
            </div>
          </form>
        </main>
      </div>
    </>
  )
}
