import { useState, SetStateAction, Dispatch, useMemo } from 'react'
import {
  selectTheme, 
  selectCorrelationVariable,
} from '../../store'
import { Button, ButtonCancel, Select } from '..'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import { darken, _ } from '../../lib'
import { ParticipantItem, DataTypeDisplayNameMap, CorrelationVariableTypeDisplayNameMap } from '../../model'
import { useParams } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { AddGreyIcon, AddBlackIcon } from '../../asset/image'
import { v4 } from 'uuid'
import { RIF } from '../../lib'
import { Variable, VariableDataType, VariableType } from '../../shared/analysis'

interface AnalysisSideBarProps {
  participantList: ParticipantItem[]
  onParticipantSelect: (participant: ParticipantItem) => void
  setDuration: Dispatch<SetStateAction<DateObject[]>>
  duration: DateObject[]
  currentParticipantId: string
  resolution: string
  setResolution: Dispatch<SetStateAction<string>>
  displayingVariableList: Variable[]
  setDisplayingVariableList: Dispatch<SetStateAction<Variable[]>>
}

export const AnalysisSideBar = (props: AnalysisSideBarProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    participantList,
    onParticipantSelect,
    duration,
    setDuration,
    displayingVariableList,
    setDisplayingVariableList,
  } = props
  
  const projectId = useParams().projectId ?? ''
  const correlationVariableList: Variable[] = selectCorrelationVariable(projectId)
  const [dataSetOptions, setDataSetOptions] = useState<Record<string, string>[]>([])
  const [dataSetValue, setDataSetValue] = useState<Record<string, string> | null>(null)
  
  const [tempVariableList, setTempVariableList] = useState<Variable[]>(displayingVariableList)
  const [resolutionValue, setResolutionValue] = useState<Record<string, string>>({value: 'daily', label: 'Daily'})
  const resolutionOptions = [
    {value: 'daily', label: 'Daily'},
    {value: 'weekly', label: 'Weekly'},
    {value: 'monthly', label: 'Monthly'},
  ]

  const convertParticipantItemToDataSetOption = (participant: ParticipantItem) => {
    return {value: participant.id, label: participant.insignia}
  }

  const handleUpdateSettings = () => {
    setDisplayingVariableList(tempVariableList)
  }

  const handleChangeDataSet = (e: Record<string, string>) => {
    const participantId = e.value
    const participant = _.find(participantList, ['id', participantId])
    if (!participant) return
    onParticipantSelect(participant)
    setDataSetValue(convertParticipantItemToDataSetOption(participant))
  }

  const handleChangeDuration = (e: DateObject[]) => {
    if (_.isArray(e) && e.length === 2) {
      setDuration(e)
    }
  }

  const handleChangeResolution = (e: Record<string, string>) => {
    setResolutionValue(e)
  }

  const addVariableBlock = () => {
    setTempVariableList([
      ...tempVariableList, 
      _.sample(correlationVariableList) as Variable
    ])
  }

  useMemo(() => {
    if (!participantList.length) return
    const participantOptionList = participantList.map(convertParticipantItemToDataSetOption)
    if (_.isEqual(dataSetOptions, participantOptionList)) return
    setDataSetValue(convertParticipantItemToDataSetOption(participantList[0]))
    setDataSetOptions(participantList.map(convertParticipantItemToDataSetOption))
  }, [participantList])

  useMemo(() => {
    if (displayingVariableList.length === 0) return
    setTempVariableList(displayingVariableList)
  }, [displayingVariableList])

  return (
    <div css={{
      width: '256px',
      height: 'calc(100vh - 56px)',
      backgroundColor: color.white,
      padding: '16px',
      borderRight: `1px solid ${color.borderLight}`,
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* data set section */}
      <div css={{
        paddingBottom: '24px',
        marginBottom: '24px',
        borderBottom: `1px solid ${color.border._80}`,
      }}>
        <div css={{display: 'flex', marginBottom: '8px',}}>
          <p css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginRight: '4px',
          }}>Data Set</p>
          {/* <Tooltip content={''}/> */}
        </div>
        <div css={{
          width: '100%',
          borderRadius: '5px',
          border: `1px solid ${color.border._160}`,
          backgroundColor: color.surface.grey.light,
          padding: '12px',
          marginBottom: '8px',
        }}>
          <p css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginBottom: '4px',
          }}>Participant or Group</p>
          <Select 
            value={dataSetValue} 
            options={dataSetOptions} 
            onChange={handleChangeDataSet}
            css={{
              marginBottom: '16px',
            }}
          />
          <p css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginBottom: '4px',
          }}>Duration</p>
          <DatePicker
            value={duration}
            onChange={handleChangeDuration}
            range={true}
            rangeHover={true}
            format='MMM D, YYYY'
            maxDate={new Date()}
            style={{
              padding: '6px 12px',
              width: '100%',
              height: '32px',
              fontSize: fontSize.h7,
              fontWeight: fontWeight.medium,
              border: `1px solid ${color.grey_160}`,
              borderRadius: '5px',
              color: color.textIcon.secondary,
              cursor: 'pointer',
            }}
          />
        </div>
        <button css={{
          width: '100%',
          borderRadius: '3px',
          border: 'none',
          backgroundColor: color.surface.grey.background,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: color.textIcon.secondary,
          cursor: 'pointer',
          fontSize: fontSize.h8,
          padding: '4px',
        }}>Compare with another data set</button>
      </div>

      {/* resolution section */}
      <div css={{
        paddingBottom: '24px',
        marginBottom: '24px',
        borderBottom: `1px solid ${color.border._80}`,
      }}>
        <div css={{display: 'flex', marginBottom: '8px',}}>
          <p css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginRight: '4px',
          }}>Resolution</p>
          {/* <Tooltip content={''}/> */}
        </div>
        <Select 
          value={resolutionValue} 
          options={resolutionOptions} 
          onChange={handleChangeResolution}
        />
      </div>

      {/* variable section */}
      <div css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginBottom: '24px',
      }}>
        <div css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}>
          <div css={{display: 'flex'}}>
            <p css={{
              fontSize: fontSize.h7,
              color: color.textIcon.secondary,
              marginRight: '4px',
            }}>Variables</p>
            {/* <Tooltip content={''}/> */}
          </div>
          <ButtonAddVariable {...{
            disabled: tempVariableList.length === 3,
            addVariableBlock,
            setTempVariableList,
          }}/>
        </div>
        <Scrollbars style={{
          flex: 1,
        }}>
          {tempVariableList.map((variable, index) => (
            <VariableBlock {...{
              key: v4(),
              variable,
              tempVariableList,
              setTempVariableList,
              index,
            }}/>
          ))}
        </Scrollbars>
      </div>
      <Button disabled={_.isEqual(displayingVariableList, tempVariableList)} onClick={handleUpdateSettings}>Update Settings</Button>
    </div>
  )
}

interface VariableItemProps {
  variable: Variable
  tempVariableList: Variable[]
  setTempVariableList: Dispatch<SetStateAction<Variable[]>>
  index: number
}

const VariableBlock = (props: VariableItemProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {variable, tempVariableList, setTempVariableList, index} = props

  const projectId = useParams().projectId ?? ''
  const correlationVariableList = selectCorrelationVariable(projectId) || []

  const [dataTypeOptions, setDataTypeOptions] = useState<Record<string, string>[]>([])
  const [dataTypeValue, setDataTypeValue] = useState<Record<string, string>>({
    value: variable.dataType, 
    label: DataTypeDisplayNameMap[variable.dataType]
  })

  const getVariableDisplayName = ({dataType, variableType}: {dataType: VariableDataType, variableType: VariableType}): string => {
    const displayNameMap = CorrelationVariableTypeDisplayNameMap[dataType] as Record<VariableType, string>
    return displayNameMap ? displayNameMap[variableType] : variableType
  }
  type VariableTypeItem = Variable & {
    value: string,
    label: string,
  }
  const [variableTypeOptions, setVariableTypeOptions] = useState<Record<string, VariableTypeItem[]>>({})
  const [variableTypeValue, setVariableTypeValue] = useState<VariableTypeItem>({
    value: variable.variableType, 
    label: getVariableDisplayName(variable),
    ...variable,
  })

  const handleClose = () => {
    setTempVariableList((prev) => {
      const newVariableList = [...prev]
      newVariableList.splice(index, 1)
      return newVariableList
    })
  }

  const getVariableCode = () => {
    const charCode = 'A'.charCodeAt(0)
    return String.fromCharCode(charCode + index)
  }

  useMemo(() => {
    if (correlationVariableList.length === 0) return
    const tempDataTypeOptions: Record<string, string>[] = []
    const tempVariableTypeOptions: Record<string, VariableTypeItem[]> = {}
    correlationVariableList.forEach((variable: Variable) => {
      tempDataTypeOptions.push({value: variable.dataType, label: DataTypeDisplayNameMap[variable.dataType] || variable.dataType})
      if (!tempVariableTypeOptions[variable.dataType]) tempVariableTypeOptions[variable.dataType] = []
      tempVariableTypeOptions[variable.dataType].push({
        ...variable,
        value: variable.variableType, 
        label: getVariableDisplayName(variable),
      })
    })
    setDataTypeOptions(_.uniq(_.uniqBy(tempDataTypeOptions, 'value')))
    setVariableTypeOptions(tempVariableTypeOptions)
  }, [correlationVariableList])

  useMemo(() => {
    if (!Object.keys(variableTypeOptions).length) return
    setVariableTypeValue(variableTypeOptions[dataTypeValue.value][0])
  }, [dataTypeValue])

  useMemo(() => {
    if (!Object.keys(variableTypeOptions).length) return
    setTempVariableList((prev) => {
      const newVariableList = [...prev]
      newVariableList.splice(index, 1, _.omit(variableTypeValue, ['value', 'label']) as Variable)
      return newVariableList
    })
  }, [variableTypeValue])

  return (
    <div css={{
      width: '100%',
      padding: '12px',
      borderRadius: '5px',
      border: `1px solid ${color.border._80}`,
      backgroundColor: color.surface.grey.light,
      marginBottom: '8px',
    }}>
      <div css={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '8px',
      }}>
        <div css={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <p css={{
            width: '12px',
            height: '12px',
            lineHeight: '12px',
            borderRadius: '50%',
            fontSize: fontSize.h8,
            fontWeight: fontWeight.thick,
            color: color.white,
            backgroundColor: color.surface.dark,
            textAlign: 'center',
            marginRight: '4px',
          }}>{getVariableCode()}</p>  
          <p css={{
            fontSize: fontSize.h7,
            color: color.textIcon.light,
          }}>Variable {getVariableCode()}</p>
        </div>
        {RIF(
          tempVariableList.length > 2,
          <ButtonCancel 
            size='small' 
            onClick={handleClose} 
            bgColor={color.surface.grey.light}
          />
        )}
      </div>
      <Select 
        options={dataTypeOptions} 
        value={dataTypeValue} 
        onChange={setDataTypeValue}
        css={{
          marginBottom: '8px',
        }}
      />
      <Select 
        options={variableTypeOptions[dataTypeValue.value] || []} 
        value={variableTypeValue}
        onChange={setVariableTypeValue}
      />
    </div>
  )
}

interface ButtonAddVariableProps {
  disabled: boolean
  addVariableBlock: () => void
}

const ButtonAddVariable = (props: ButtonAddVariableProps) => {
  const {color, fontSize} = selectTheme()

  const {disabled, addVariableBlock} = props

  return (
    <button onClick={addVariableBlock} disabled={disabled} css={{
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: color.surface.grey.background,
      padding: '4px 8px 4px 4px',
      cursor: disabled ? 'default' : 'pointer',
      ':hover': {
        backgroundColor: disabled ? color.surface.grey.background : darken(color.surface.grey.background, 10),
      },
    }}>
      <img src={disabled ? AddGreyIcon : AddBlackIcon} width={14}/>
      <p css={{
        color: disabled ? color.disabled : color.textIcon.secondary,
        marginLeft: '4px',
        fontSize: fontSize.h8,
      }}>Add</p>
    </button>
  )
}