import {Chart} from '@antv/g2/lib'
import {TimeSeriesDataSource} from '../../../model/chart'
import {useEffect, useState} from 'react'
import {
  selectParticipantTimeSeriesData,
} from '../../../store'

import _ from 'lodash'

import {getTimeSeriesDataFromDb} from '../../../store/db/db_data_getter'
import {CommonTaskDataMarker, GenericTimeSeriesDataChartConfig, TimeSeriesDataChart} from './time_series_data_chart'
import {timeConvert, TimeConvertType} from '../../../lib'
import {sliceTimeSeriesDataByPlotTimeRange} from './time_series_data_chart'
import { VisualizerGraphDataType } from '../../../shared/db'

export interface DailyTimeSeriesChartProps {
  height: string
  width: string
  participantId: string
  plotDataRange?: [Date, Date]
  commonTaskDataMarkerList?: CommonTaskDataMarker[]
}

export interface GenericDailyTimeSeriesDataChartProps {
  dataType: VisualizerGraphDataType
  config: GenericTimeSeriesDataChartConfig
  participantId: string
  plotDataRange?: [Date, Date]
  commonTaskDataMarkerList?: CommonTaskDataMarker[]
  chartReadyCallback?: (chart: Chart) => void
}

export const DailyTimeSeriesDataChart = (props: GenericDailyTimeSeriesDataChartProps) => {
  const {dataType, config, participantId, plotDataRange, commonTaskDataMarkerList, chartReadyCallback} = props

  const [timeSeriesData, setTimeSeriesData] = useState<TimeSeriesDataSource[]>([])
  const particpantDataSource = selectParticipantTimeSeriesData()?.[participantId]?.data?.[dataType]

  const fetchOneDayDataFromDbToPlot = async (yymmddIndex: number, plotDataRange: [Date, Date]) => {
    const dbContent = await getTimeSeriesDataFromDb(participantId, dataType, yymmddIndex)
    if (dbContent?.data) {
      const plotStartTime = plotDataRange[0].getTime()
      const plotEndTime = plotDataRange[1].getTime()
      const newPlotData = sliceTimeSeriesDataByPlotTimeRange(dbContent.data, [plotStartTime, plotEndTime])
      setTimeSeriesData(newPlotData)
    } else {
      setTimeSeriesData([])
    }
  }

  const fetchMultiDaysFromDbToPlot = async (yymmddRangePair: [number, number], plotDataRange: [Date, Date]) => {
    const rangeStartYYMMDD = yymmddRangePair[0]
    const rangeEndYYMMDD = yymmddRangePair[1]
    const plotStartTime = plotDataRange[0].getTime()
    const plotEndTime = plotDataRange[1].getTime()

    const headDbContent = particpantDataSource?.[rangeStartYYMMDD]
      ? await getTimeSeriesDataFromDb(participantId, dataType, rangeStartYYMMDD)
      : null
    const headSection = headDbContent?.data
    const tailDbContent = particpantDataSource?.[rangeEndYYMMDD]
      ? await getTimeSeriesDataFromDb(participantId, dataType, rangeEndYYMMDD)
      : null
    const tailSection = tailDbContent?.data

    let newPlotData: TimeSeriesDataSource[] = []

    // Head section data
    if (headSection) {
      newPlotData = sliceTimeSeriesDataByPlotTimeRange(
        headSection,
        [plotStartTime, plotEndTime],
      )
    }
    // Middle section data
    let nextDate = yymmddIndexToDate(rangeStartYYMMDD)
    nextDate.setDate(nextDate.getDate() + 1)
    let nextYYMMDD = dateToYYMMDDIndex(nextDate)
    while (nextYYMMDD !== rangeEndYYMMDD) {
      const middleDbContent = particpantDataSource?.[nextYYMMDD]
        ? await getTimeSeriesDataFromDb(participantId, dataType, nextYYMMDD)
        : null
      if (middleDbContent) {
        const middleData = middleDbContent.data
        newPlotData = newPlotData.concat(middleData)
        nextDate = new Date(nextDate)
        nextDate.setDate(nextDate.getDate() + 1)
        nextYYMMDD = dateToYYMMDDIndex(nextDate)
      }
    }

    // Tail section data
    if (tailSection) {
      const tailData = sliceTimeSeriesDataByPlotTimeRange(tailSection, [plotStartTime, plotEndTime])
      newPlotData = newPlotData.concat(tailData)
    }

    setTimeSeriesData(newPlotData)
  }

  useEffect(() => {
    if (plotDataRange === undefined) return

    const rangeStartYYMMDD = dateToYYMMDDIndex(plotDataRange[0])
    const rangeEndYYMMDD = dateToYYMMDDIndex(plotDataRange[1])

    if (!particpantDataSource) {
      setTimeSeriesData([])
    } else if (rangeStartYYMMDD === rangeEndYYMMDD) {
      if (particpantDataSource?.[rangeStartYYMMDD]) {
        fetchOneDayDataFromDbToPlot(rangeStartYYMMDD, plotDataRange)
      } else {
        setTimeSeriesData([])
      }
    } else if (rangeEndYYMMDD > rangeStartYYMMDD) {
      fetchMultiDaysFromDbToPlot([rangeStartYYMMDD, rangeEndYYMMDD], plotDataRange)
    }
  }, [particpantDataSource, plotDataRange])

  // console.log('render time series chart')
  return (
    <>
      <TimeSeriesDataChart {...{config, timeSeriesData, plotDataRange, commonTaskDataMarkerList, chartReadyCallback}} />
    </>
  )
}

const dateToYYMMDDIndex = (date: Date): number => {
  const yymmddString = timeConvert({
    time: date.getTime(),
    type: TimeConvertType.localizedUtcCustomFormat,
    arg: 'yyMMdd',
  }) as string
  return +yymmddString
}

const yymmddIndexToDate = (yymmddIndex: number): Date => {
  const year = Math.floor(yymmddIndex / 10000)
  const month = Math.floor((yymmddIndex % 10000) / 100) - 1
  const day = yymmddIndex % 100
  return new Date(Date.UTC(2000 + year, month, day))
}


