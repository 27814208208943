import {useEffect, useMemo, useState} from 'react'
import {VisualizerGraphDataType} from '../../../shared/db'
import {
  createDispatchActions,
  selectAdherenceData,
  selectAuth,
  selectMethod,
  selectParticipantTimeSeriesData,
  selectProjectSettings,
} from '../../../store'
import {GraphDataTypeMap} from '../../../components/charts/utils/utils'
import _ from 'lodash'
import {TimeSeriesDataLoaderProps, filterVisualizerSidebarSetting, convertToYYMMIndex} from './utils'
import {useCurrentProjectState} from '../../custom_hooks'

export const GarminConnectDataLoader = (props: TimeSeriesDataLoaderProps) => {
  const {participantId, loadDataYYMMDDIndex} = props
  const {projectId} = useCurrentProjectState()
  const projectSettings = projectId ? selectProjectSettings()[projectId] : undefined
  const method = selectMethod()
  const garminConnectEnabled: boolean = method?.garminConnectEnable
  const adherenceData = projectId ? selectAdherenceData()?.[projectId] : undefined
  const [requestId, setRequestId] = useState(null)
  const [requestGarminConnectDigestId, setRequestGarminConnectDigestId] = useState(null)
  const accessToken = selectAuth()?.accessToken
  const particpantTimeSeriesData = selectParticipantTimeSeriesData()
  const currentParticipantTimeSeriesData = useMemo(() => {
    return particpantTimeSeriesData[participantId]
  }, [particpantTimeSeriesData, participantId])

  const {doREQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH, doREQUEST_PROJECT_DATA_DIGEST}: any = createDispatchActions()

  const selectedGarminConnectDataList = useMemo<VisualizerGraphDataType[]>(() => {
    if (garminConnectEnabled && projectSettings?.visualizerSidebarSetting.TimeSeries) {
      const scope = [
        VisualizerGraphDataType.GarminConnectStress,
        VisualizerGraphDataType.GarminConnectHeartRate,
        VisualizerGraphDataType.GarminConnectRestingHR,
        VisualizerGraphDataType.GarminConnectBodyBattery,
        VisualizerGraphDataType.GarminConnectSleepStaging,
        VisualizerGraphDataType.GarminConnectActivityEpochs,
        VisualizerGraphDataType.GarminConnectSteps,
        VisualizerGraphDataType.GarminConnectCalories,
      ]
      const graphDataList = filterVisualizerSidebarSetting(projectSettings?.visualizerSidebarSetting.TimeSeries, scope)
      if (!graphDataList.includes(VisualizerGraphDataType.GarminConnectHeartRate)) {
        // always include VisualizerGraphDataType.GarminConnectHeartRate for default task time line data
        graphDataList.push(VisualizerGraphDataType.GarminConnectHeartRate)
      }
      return graphDataList
    } else {
      return []
    }
  }, [garminConnectEnabled, projectSettings?.visualizerSidebarSetting.TimeSeries])

  useEffect(() => {
    // attempt to load Garmin Connect data
    if (selectedGarminConnectDataList.length) {
      const requestDigestYymmIndexList: number[] = []
      for (const yymmddIndex of loadDataYYMMDDIndex) {
        const yymmIndex = convertToYYMMIndex(yymmddIndex)
        if (adherenceData?.projectDataDigest?.coveredYYMMIndex?.includes(yymmIndex)) {
          optimisedFetchGarminConnectData(yymmddIndex)
        } else {
          fetchGarminConnectData(yymmddIndex)
          if (!requestDigestYymmIndexList.includes(yymmIndex)) {
            requestDigestYymmIndexList.push(yymmIndex)
          }
        }
      }

      if (requestDigestYymmIndexList.length > 0) {
        fetchGarminConnectDataDigest(requestDigestYymmIndexList)
      }
    }
  }, [participantId, loadDataYYMMDDIndex, selectedGarminConnectDataList])

  const optimisedFetchGarminConnectData = (yymmddIndex: number) => {
    const garminConnectDataDigest =
      adherenceData?.projectDataDigest?.participantDataDigestMap?.[participantId]?.garminConnectDataDates
    if (garminConnectDataDigest && garminConnectDataDigest.includes(yymmddIndex)) {
      fetchGarminConnectData(yymmddIndex)
    }
  }

  const fetchGarminConnectData = (yymmddIndex: number) => {
    const timeSeriesData = currentParticipantTimeSeriesData?.data
    const garminConnectHookTypeList = selectedGarminConnectDataList
      .filter((graphType) => !timeSeriesData || timeSeriesData[graphType]?.[yymmddIndex] === undefined)
      .map((graphType) => GraphDataTypeMap[graphType])

    if (garminConnectHookTypeList.length > 0) {
      doREQUEST_VISUALIZER_GARMIN_CONNECT_DATA_FETCH({
        setRequestId,
        payload: {
          accessToken,
          participantId: participantId,
          yymmddIndex: yymmddIndex,
          dataTypeList: [...new Set(garminConnectHookTypeList)],
        },
      })
    }
  }

  const fetchGarminConnectDataDigest = (yymmIndexList: number[]) => {
    doREQUEST_PROJECT_DATA_DIGEST({
      setRequestId: setRequestGarminConnectDigestId,
      payload: {
        accessToken,
        projectId,
        yymmIndexList,
        requestTaskData: false,
        requestGarminData: true,
        requestDexcomData: false,
      },
    })
  }
}
