import Joi, {Schema} from 'joi'
import {joiResolver} from '@hookform/resolvers/joi'
import * as uuidLib from 'uuid'
import {UseFormProps, useForm as useFormLib} from 'react-hook-form'
import * as lodashLib from 'lodash'

export * from './calculate_input_css'
export * from './color_util'
export * from './create_action'
export * from './i18n'
export * from './render_if'
export * from './request'
export * from './time_convert'
export * from './sessionStorage'
export * from './custom_hooks/index'
export * from './t'
export * from './data_downloader'
export * from './utility_types'
export * from './chart_data'
export * from './adherence_data_transformer'
export * from './array_replace'

// export const API_URL = process.env.POMELO_API_URL
export const API_URL = process.env.API_URL
export const LOGOUT = 'LOGOUT'

export const v = Joi
export const uuid = uuidLib
export const _ = lodashLib
export const debug = require('debug')('pomelo:')

export * from 'redux-saga/effects'
export * from 'redux-saga' // include "SagaIterator"
export const xdir = (data: unknown) => console.dir(data, {depth: 100})

export {useSelector} from 'react-redux'

export {useTranslation} from 'react-i18next'

export const useForm = (props: {schema?: Joi.AnySchema} & Omit<UseFormProps, 'resolver'> = {}) => {
  const {schema, ...options} = props
  return useFormLib({
    resolver: joiResolver(schema ?? Joi.any(), {abortEarly: false, errors: {label: 'path'}}),
    ...options,
  })
}

export const sortBy = (key: string) => (a: any, b: any) => {
  return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
}

export const snakeToCamel = (string: string) => {
  return string.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
}

export const camelToSnake = (string: string) => {
  return string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const snakeToTitle = (string: string) => {
  const words = string.split('_')
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  return capitalizedWords.join(' ')
}

export const checkIsValidUUID = (string: string) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(string)
}

export const assertTotalSchema = ({payload, schema}: {payload: any; schema: Schema<any>}) => {
  return v.assert(payload, schema, {abortEarly: false})
}

export const assertPartialSchema = ({payload, schema}: {payload: any; schema: Schema<any>}) => {
  // TODO @slugbyte should this return a default payload or throw and error?
  return v.assert(payload, schema, {abortEarly: false, allowUnknown: true})
}
