import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  select,
  SagaIterator,
} from '../../lib'
import { Workspace } from '../../model'
import {doWORKSPACE_LIST_SET, doREQUEST_COMPLETE, doREQUEST_ERROR, doPURCHASE_SET} from '../state'

export const REQUEST_IDENTITY_WORKSPACE_LIST_FETCH = 'REQUEST_IDENTITY_WORKSPACE_LIST_FETCH'
export const doREQUEST_IDENTITY_WORKSPACE_LIST_FETCH = createDoRequestAction(REQUEST_IDENTITY_WORKSPACE_LIST_FETCH)

export const requestIdentityWorkspaceListFetchActionCreators = {
  doREQUEST_IDENTITY_WORKSPACE_LIST_FETCH,
}

export function* requestIdentityWorkspaceListFetch({payload}: any): SagaIterator {
  debug('saga*requestIdentityWorkspaceListFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().exist(),
        page: v.number().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {page, accessToken} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-workspace-list-fetch`,
    accessToken,
    data: {
      page,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_IDENTITY_WORKSPACE_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-workspace-list-fetch',
        ...result,
        error,
      }),
    )
  }

  const ownIdentityId = yield select((state) => state?.profile?.identityId)
  const sortedList = result.payload.sort((a: any, b: any) => {
    if (a.identityId === ownIdentityId) return -1
    if (b.identityId === ownIdentityId) return 1
    return 0
  })

  for (const workspace of sortedList) {
    yield put(doPURCHASE_SET({
      workspaceId: workspace.id,
      purchaseList: workspace.purchaseList,
    }))
  }
  
  yield put(doWORKSPACE_LIST_SET(sortedList))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_IDENTITY_WORKSPACE_LIST_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/identity-workspace-list-fetch',
      ...result,
    }),
  )
}

export function* identityWorkspaceListFetchSaga() {
  yield takeEvery(REQUEST_IDENTITY_WORKSPACE_LIST_FETCH, requestIdentityWorkspaceListFetch)
}
