import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const REQUEST_ANALYTIC_CORRELATION_INDIVIDUAL = 'REQUEST_ANALYTIC_CORRELATION_INDIVIDUAL'
export const doREQUEST_ANALYTIC_CORRELATION_INDIVIDUAL = createDoRequestAction(REQUEST_ANALYTIC_CORRELATION_INDIVIDUAL)

export const requestAnlyticCorreltionIndividualActionCreators = {
  doREQUEST_ANALYTIC_CORRELATION_INDIVIDUAL,
}

export function* requestAnlyticCorreltionIndividual({payload, type}: {
  payload: any, 
  type: string,
}): SagaIterator {
  debug('saga*requestAnlyticCorreltionIndividual')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        yymmddIndexStart: v.number(),
        yymmddIndexEnd: v.number(),
        variables: v.array().items(v.object()).required(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {accessToken, participantId, yymmddIndexStart, yymmddIndexEnd, variables} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/analytic-graph-correlation-individual-fetch`,
    accessToken,
    data: {
      participantId,
      yymmddIndexStart,
      yymmddIndexEnd,
      variables,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: type,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/analytic-graph-correlation-individual-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: type,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v1/web/analytic-graph-correlation-individual-fetch',
      ...result,
    }),
  )
}

export function* anlyticCorreltionIndividualSaga() {
  requestAnlyticCorreltionIndividual
  yield takeEvery(REQUEST_ANALYTIC_CORRELATION_INDIVIDUAL, requestAnlyticCorreltionIndividual)
}
