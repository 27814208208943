import {v, useSelector, createAction, assertPartialSchema, setSessionStorage} from '../../../lib'

// SELECTOR
export const selectAuth = () => {
  return useSelector((state) => state?.auth || null)
}
export const selectAccessToken = () => {
  return useSelector((state) => state?.auth?.accessToken || null)
}
export const selectRefreshToken = () => {
  return useSelector((state) => state?.auth?.refreshToken || null)
}
// ACTIONS
export const AUTH_SET = 'AUTH_SET'
export const doAUTH_SET = createAction(AUTH_SET)

export const LOGOUT = 'LOGOUT'
export const doLOGOUT = createAction(LOGOUT)

export const authActionCreators = {
  doAUTH_SET,
  doLOGOUT,
}

// REDUCER
export const authDefaultState = {
  accessToken: null,
  refreshToken: null,
}

export const authReducer = (state = {...authDefaultState}, {type, payload}) => {
  switch (type) {
    case AUTH_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          accessToken: v.string().exist(),
          refreshToken: v.string().exist(),
        }),
      })
      setSessionStorage({auth: payload})
      return payload
    case LOGOUT:
      return authDefaultState
    default:
      return state
  }
}
