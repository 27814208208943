import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doADHERENCE_DIGEST_DAY_DETAIL_SET} from '../state'

export const REQUEST_PARTICIPANT_DAILY_DATA_DIGEST = 'REQUEST_PARTICIPANT_DAILY_DATA_DIGEST'
export const doREQUEST_REQUEST_PARTICIPANT_DAILY_DATA_DIGEST = createDoRequestAction(
  REQUEST_PARTICIPANT_DAILY_DATA_DIGEST,
)

export const requestParticipantDailyDataDigestActionCreators = {
  doREQUEST_REQUEST_PARTICIPANT_DAILY_DATA_DIGEST,
}

export function* requestParticipantDailyDataDigest({payload}: any): SagaIterator {
  debug('saga*requestParticipantDailyDataDigest')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid(),
        projectId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        yymmddIndex: v.number(),
        requestGarminDirectData: v.bool(),
        requestGarminConnectData: v.bool(),
        requestDexcomData: v.bool(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    participantId,
    accessToken,
    yymmddIndex,
    requestGarminDirectData,
    requestGarminConnectData,
    requestDexcomData,
  } = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v2/web/participant-daily-data-digest`,
    accessToken,
    data: {
      participantId,
      yymmddIndex,
      requestGarminDirectData,
      requestGarminConnectData,
      requestDexcomData,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_DAILY_DATA_DIGEST,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v2/web/participant-daily-data-digest',
        ...result,
        error,
      }),
    )
  }

  // console.log('participant-daily-data-digest', result.payload)

  yield put(
    doADHERENCE_DIGEST_DAY_DETAIL_SET({
      projectId: payload.projectId,
      ...result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PARTICIPANT_DAILY_DATA_DIGEST,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v2/web/participant-daily-data-digest',
      ...result,
    }),
  )
}

export function* participantDailyDataDigestSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_DAILY_DATA_DIGEST, requestParticipantDailyDataDigest)
}
