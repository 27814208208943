import {createAction, useSelector} from '../../../lib'

export const REQUEST_ERROR = 'REQUEST_ERROR'
export const doREQUEST_ERROR = createAction(REQUEST_ERROR)

export const REQUEST_COMPLETE = 'REQUEST_COMPLETE'
export const doREQUEST_COMPLETE = createAction(REQUEST_COMPLETE)

export const RESET_ERROR = 'RESET_ERROR'
export const doRESET_ERROR = createAction(RESET_ERROR)

export const selectRequestComplete = (requestId) =>
  useSelector((state) => {
    return state.requestState[requestId] || null
  })

export const selectRequestState = () =>
  useSelector((state) => {
    return state.requestState
  })

export const requestStateActionCreators = {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doRESET_ERROR,
}

const requestStateDefaultState = {}
export const requestStateReducer = (state = requestStateDefaultState, {type, payload}) => {
  let requestId
  switch (type) {
    case REQUEST_ERROR: {
      if (!payload.requestId) {
        throw new Error('REQUEST_ERROR payload must have requestId')
      }
      requestId = payload.requestId
      const refreshTokenError = payload?.payload?.serverError === 'jwt expired'
      return {
        ...state,
        [requestId]: {
          success: false,
          payload,
          refreshTokenError,
        },
        hasError: !refreshTokenError && requestId,
      }
    }
    case REQUEST_COMPLETE:
      if (!payload.requestId) {
        throw new Error('REQUEST_COMPLETE payload must have requestId')
      }
      requestId = payload.requestId
      return {
        ...state,
        [requestId]: {
          success: true,
          payload,
        },
      }
    case RESET_ERROR:
      return {...state, hasError: null}
    default:
      return state
  }
}
