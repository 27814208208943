import {
  useState,
  useEffect,
  createRef,
} from "react"
import { useParams } from "react-router-dom"
import { 
  selectTheme,
  selectDataDownloadStatus,
  DataDownloadState,
} from "../../store"
import Lottie from 'lottie-web'
import preparingDataAnimation from '../../asset/lotties/data_download_animation.json'
import {RIF, useCurrentProjectState} from '../../lib'

export const PopupPreparingDataDownload = () => {
  const { color, fontSize, fontWeight } = selectTheme()
  const animRef: any = createRef()

  const { projectId } = useCurrentProjectState()
  const dataDownloadStatus = selectDataDownloadStatus()

  const [progressPercentage, setProgressPercentage] = useState<string>('0%')

  useEffect(() => {
    Lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: preparingDataAnimation,
    })
    return () => Lottie.stop()
  }, [])

  useEffect(() => {
    if (!(projectId && Object.keys(dataDownloadStatus).includes(projectId))) {
      return
    }
    const status = dataDownloadStatus[projectId]
    const progress = status.job?.progress
    if (status.state !== DataDownloadState.Started || !progress) {
      return
    }
    const percentageProgress = progress.completed / progress.total * 100
    setProgressPercentage(`${percentageProgress}%`)
  }, [dataDownloadStatus])

  return (
    <div css={{
      position: 'fixed',
      width: 'calc(100vw - 354px)',
      minWidth: '1086px',
      height: 'calc(100vh - 50px)',
      top: '50px',
      left: '0',
      background: 'rgba(247, 248, 248, .8)',
      zIndex: '100',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '40px',
        borderRadius: '5px',
        background: color.white,
        width: '440px',
      }}>
        {RIF(
          progressPercentage === '0%',
          <div ref={animRef} css={{width: '100%'}}/>
        )}
        {RIF(
          progressPercentage !== '0%',
          <div css={{
            width: '100%',
            padding: '64px 32px 24px',
          }}>
            <div css={{
              width: '100%',
              background: color.grey_160,
              height: '8px',
              position: 'relative',
            }}>
              <div css={{
                width: progressPercentage,
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                background: color.primary,
              }}/>
            </div>
          </div>
        )}
        <p css={{
          marginTop: '24px',
          marginBottom: '8px',
          fontWeight: fontWeight.thick,
          fontSize: fontSize.h5,
          textAlign: 'center',
        }}>Preparing your data download</p>
        <p css={{
          fontSize: fontSize.h7,
          fontWeight: fontWeight.medium,
          color: color.grey_600,
          textAlign: 'center',
        }}>This may take a while. While you&apos;re waiting, check out some useful resources for your data analysis.</p>
      </div>
    </div>
  )
}