import { QueryKey } from '../mongo/localdate_analysis_data'

export interface AnalysisServiceRequest<T = unknown> {
  metadata: T
  [key: string]: unknown
}

export interface SleepRequest extends AnalysisServiceRequest<QueryKey> {
  types: ('sleep_wake' | 'sleep_score')[]
  zero_crossing_counts: SleepRequestZeroCrossingCount[]
  sleep_score_config?: SleepScoreConfig
  sleep_wake_config?: SleepWakeConfig
}

export interface SleepRequestZeroCrossingCount {
  t: number
  zcc: number
}

export interface SleepScoreConfig {
  kernel?: number[]
  kernel_scalar?: number
}

export interface SleepWakeConfig {
  threshold?: number
}

export interface HRVRequest extends AnalysisServiceRequest<QueryKey> {
  bbi: HRVRequestBBI[]
  config?: HRVConfig
}

export interface HRVRequestBBI {
  t: number
  bbi: number
}

export interface HRVConfig {
  valid_threshold?: number
  resolution?: '5min'
}

export enum CorrelationAlgorithmMethod {
  Pearson = 'pearson',
  Kendall = 'kendall',
  Spearman = 'spearman',
}

export enum CorrelationAlgorithmPreprocessing {
  Normalize = 'normalize',
  Standardize = 'standardize',
}

export interface CorrelationAlgorithmConfig {
  method?: CorrelationAlgorithmMethod
  preprocessing?: CorrelationAlgorithmPreprocessing
}

export interface CorrelationGraphConfig {
  color_discrete_sequence: string[]
}

export interface CorrelationConfig {
  algorithm?: CorrelationAlgorithmConfig
  graph?: CorrelationGraphConfig
}

type CorrelationRequestVariableData = (number | null)[]

export type CorrelationRequestVariable = [
  string,
  CorrelationRequestVariableData,
]

export interface IndividualCorrelationRequest extends AnalysisServiceRequest {
  variables: CorrelationRequestVariable[]
  config?: CorrelationConfig
}
