import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doDEVELOPER_TOKEN_DELETE} from '../state'

export const REQUEST_DEVELOPER_TOKEN_DELETE = 'REQUEST_DEVELOPER_TOKEN_DELETE'
export const doREQUEST_DEVELOPER_TOKEN_DELETE = createDoRequestAction(REQUEST_DEVELOPER_TOKEN_DELETE)

export const requestDeveloperTokenDeleteActionCreators = {
  doREQUEST_DEVELOPER_TOKEN_DELETE,
}

export function* requestDeveloperTokenDelete({payload}: any): SagaIterator {
  debug('saga*requestDeveloperTokenDelete')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        developerTokenId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {accessToken, developerTokenId} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/developer-token-delete`,
    accessToken,
    data: {
      developerTokenId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_DEVELOPER_TOKEN_DELETE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/developer-token-delete',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doDEVELOPER_TOKEN_DELETE({
      id: developerTokenId,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_DEVELOPER_TOKEN_DELETE,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v1/web/developer-token-delete',
      ...result,
    }),
  )
}

export function* developerTokenDeleteSaga() {
  yield takeEvery(REQUEST_DEVELOPER_TOKEN_DELETE as any, requestDeveloperTokenDelete)
}
