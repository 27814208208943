import {useEffect, useMemo, useState} from 'react'
import {VisualizerGraphDataType} from '../../../shared/db'
import {createDispatchActions, selectProjectSettings} from '../../../store'
import {GraphDataTypeMap} from '../../../components/charts/utils/utils'
import _ from 'lodash'
import {filterVisualizerSidebarSetting} from './utils'
import {StreamTaskEvent} from '../../../components/charts/task_timeline_chart'
import {useCurrentProjectState} from '../../custom_hooks'

export interface StreamDataLoaderProps {
  timeRange: [number, number]
  eventList: StreamTaskEvent[]
}

export const StreamDataLoader = (props: StreamDataLoaderProps) => {
  const {timeRange, eventList} = props
  const {projectId} = useCurrentProjectState()
  const projectSettings = projectId ? selectProjectSettings()[projectId] : undefined
  const [requestId, setRequestId] = useState(null)
  const {doREQUEST_STREAM_DATA}: any = createDispatchActions()

  const selectedStreamDataList = useMemo<VisualizerGraphDataType[]>(() => {
    if (projectSettings?.visualizerSidebarSetting.TimeSeries) {
      const scope = [
        VisualizerGraphDataType.GarminDirectAccelerometerRaw,
        VisualizerGraphDataType.MovesenseIMU,
        VisualizerGraphDataType.MovesenseECGWaveform,
        VisualizerGraphDataType.MovesenseECGRR,
      ]
      return filterVisualizerSidebarSetting(projectSettings?.visualizerSidebarSetting.TimeSeries, scope)
    }
    return []
  }, [projectSettings?.visualizerSidebarSetting.TimeSeries])

  useEffect(() => {
    // attemp to load stream data
    const dataTypeList = selectedStreamDataList.map((graphType) => GraphDataTypeMap[graphType])
    if (eventList && selectedStreamDataList.length > 0) {
      eventList.forEach((taskEvent) => {
        doREQUEST_STREAM_DATA({
          setRequestId,
          payload: {
            dataTypeList,
            timeRange: timeRange,
            streamTaskEvent: taskEvent,
          },
        })
      })
    }
  }, [selectedStreamDataList, timeRange, eventList])
}
