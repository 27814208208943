import {CorrelationGraphItem} from '../model/correlation_graph'
import {CorrelationMatrixProps} from '../model/correlation_matrix'
import {Grid} from '../layout/grid'
import {isMatch} from 'lodash'

const CorrelationMatrixLegend = (props: {identifier: string; displayName: string; selected: boolean}) => {
  const dark = '#4d505c'
  const light = '#f0f0f3'
  // TODO: layout & style
  return (
    <div
      style={{
        color: props.selected ? light : dark,
        backgroundColor: props.selected ? dark : light,
      }}
    >
      {[props.identifier, props.displayName].join(' ')}
    </div>
  )
}

export const CorrelationMatrix = <I extends CorrelationGraphItem<I['data']>>(props: CorrelationMatrixProps<I>) => {
  // TODO: layout & style
  return (
    <Grid
      margin={[8, 8]}
      items={props.items}
      itemLayout={{static: true}}
      renderItem={(item) => {
        const selected = props.selected ? isMatch(props.selected, item.coord) : false
        return <div style={selected ? {borderColor: '#9a9ba2'} : {}}>{props.renderItem(item)}</div>
      }}
      legendLayout={{static: true}}
      renderLegend={(identifier, index) => {
        const selected =
          index.rowIndex === 0 ? props.selected?.row : index.columnIndex === 0 ? props.selected?.column : undefined
        return (
          <CorrelationMatrixLegend
            identifier={identifier}
            displayName={props.displayName(identifier)}
            selected={selected === identifier}
          />
        )
      }}
    />
  )
}
