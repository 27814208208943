import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {doREQUEST_ERROR, doPROJECT_TAG_LIST_ADD, doREQUEST_COMPLETE} from '../state'

export const REQUEST_PROJECT_TAG_CREATE = 'REQUEST_PROJECT_TAG_CREATE'
export const doREQUEST_PROJECT_TAG_CREATE = createDoRequestAction(REQUEST_PROJECT_TAG_CREATE)

export const requestProjectTagCreateActionCreators = {
  doREQUEST_PROJECT_TAG_CREATE,
}

export function* requestProjectTagCreate({payload}: any): SagaIterator {
  debug('saga*requestProjectTagCreate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        tags: v
          .array()
          .items(
            v
              .object({
                color: v.string().hex().length(6).exist(),
                value: v.string().max(250).exist(),
              })
              .exist(),
          )
          .exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {accessToken, projectId, tags} = payload
  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/project-tag-create`,
    accessToken,
    data: {
      projectId,
      tags,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_TAG_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-tag-create',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doPROJECT_TAG_LIST_ADD({
      id: projectId,
      tagList: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* projectTagCreateSaga() {
  yield takeEvery(REQUEST_PROJECT_TAG_CREATE, requestProjectTagCreate)
}
