import {call} from 'redux-saga/effects'
import axios from 'axios'

export const baseRequest = async (options) => {
  try {
    let accessToken = options.accessToken
    if (accessToken) {
      delete options.accessToken
      options.headers = {Authorization: `Bearer ${accessToken}`}
    }
    const response = await axios.request(options)
    let statusCode = response.status
    let success = statusCode >= 200 && statusCode < 300

    let data = response.data || {type: 'NONE', payload: null}
    let {type, payload} = data

    return {
      success,
      statusCode,
      data,
      type,
      payload,
      axiosResponse: response,
      error: null,
    }
  } catch (error) {
    let success = false
    let response = error?.response || null
    let statusCode = response?.status || null
    let data = response?.data || {type: 'NONE', payload: null}
    let {type, payload} = response.data

    error.success = success
    error.statusCode = statusCode
    error.data = data
    error.type = type
    error.payload = payload

    delete error.response
    error.axiosResponse = response

    return {
      success,
      statusCode,
      data,
      type,
      payload,
      axiosResponse: response,
      error,
    }
  }
}

export const request = (options) => call(() => baseRequest(options))
