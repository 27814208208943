import {v, assertPartialSchema, createAction, useSelector} from '../../../lib'

import {DexcomIntegration} from '../../../model'

// 用enum來定義actionType的型別避免因typo造成錯誤
export enum ActionType {
  WORKSPACE_SET = 'WORKSPACE_SET',
  WORKSPACE_NAME_SET = 'WORKSPACE_NAME_SET',
  WORKSPACE_LIST_SET = 'WORKSPACE_LIST_SET',
  WORKSPACE_DELETE = 'WORKSPACE_DELETE',
  CURRENT_WORKSPACE_SET = 'CURRENT_WORKSPACE_SET',
  WORKSPACE_ROLE_LIST_SET = 'WORKSPACE_ROLE_LIST_SET',
  WORKSPACE_DEXCOM_INTEGRATION_LIST_SET = 'WORKSPACE_DEXCOM_INTEGRATION_LIST_SET',
  WORKSPACE_DEXCOM_INTEGRATION_SET = 'WORKSPACE_DEXCOM_INTEGRATION_SET',
  WORKSPACE_DEXCOM_INTEGRATION_DELETE = 'WORKSPACE_DEXCOM_INTEGRATION_DELETE',
}

export const doWORKSPACE_SET = createAction(ActionType.WORKSPACE_SET)
export const doWORKSPACE_NAME_SET = createAction(ActionType.WORKSPACE_NAME_SET)
export const doWORKSPACE_LIST_SET = createAction(ActionType.WORKSPACE_LIST_SET)
export const doWORKSPACE_DELETE = createAction(ActionType.WORKSPACE_DELETE)
export const doCURRENT_WORKSPACE_SET = createAction(ActionType.CURRENT_WORKSPACE_SET)
export const doWORKSPACE_ROLE_LIST_SET = createAction(ActionType.WORKSPACE_ROLE_LIST_SET)
export const doWORKSPACE_DEXCOM_INTEGRATION_LIST_SET = createAction(ActionType.WORKSPACE_DEXCOM_INTEGRATION_LIST_SET)
export const doWORKSPACE_DEXCOM_INTEGRATION_SET = createAction(ActionType.WORKSPACE_DEXCOM_INTEGRATION_SET)
export const doWORKSPACE_DEXCOM_INTEGRATION_DELETE = createAction(ActionType.WORKSPACE_DEXCOM_INTEGRATION_DELETE)

export interface WorkspacesState {
  [key: string]: any
  currentWorkspaceId: string
  roleInWorkspaces: Record<string, any>
}

// 這邊需要定義RootState這樣selector才能正常運作
interface RootState {
  workspaces: WorkspacesState
}

export const selectWorkspaces = () => {
  return useSelector((state: RootState) => state.workspaces)
}

export const workspacesActionCreators = {
  doWORKSPACE_SET,
  doWORKSPACE_LIST_SET,
  doWORKSPACE_DELETE,
  doCURRENT_WORKSPACE_SET,
  doWORKSPACE_ROLE_LIST_SET,
  doWORKSPACE_NAME_SET,
}

export const workspacesDefaultState: WorkspacesState = {
  roleInWorkspaces: {},
  currentWorkspaceId: '',
}

// 用union的方式來定義action並搭配reducer中的case語句可以防止未知type或錯誤的參數被傳入
type Action =
  | {
      type: ActionType.WORKSPACE_SET
      payload: {
        id: string
        name: string
        identityId: string
        participantLimit: number
        projectCollaboratorList: any[]
      }
    }
  | {
      type: ActionType.WORKSPACE_NAME_SET
      payload: {
        id: string
        name: string
      }
    }
  | {
      type: ActionType.WORKSPACE_DELETE
      payload: {
        id: string
      }
    }
  | {
      type: ActionType.WORKSPACE_LIST_SET
      payload: {
        id: string
        name: string
        identityId: string
        participantLimit: number
      }[]
    }
  | {
      type: ActionType.CURRENT_WORKSPACE_SET
      payload: {
        id: string
      }
    }
  | {
      type: ActionType.WORKSPACE_ROLE_LIST_SET
      payload: {
        workspaceId: string
        type: string
      }[]
    }
  | {
      type: ActionType.WORKSPACE_DEXCOM_INTEGRATION_LIST_SET
      payload: {
        workspaceId: string
        dexcomIntegrationList: {
          dexcomApp?: any
          id: string
          name: string
          description: string
          investigator: string
          organization: string
          countries: string
          contactDescription: string
        }[]
      }
    }
  | {
      type: ActionType.WORKSPACE_DEXCOM_INTEGRATION_SET
      payload: {
        workspaceId: string
        dexcomApp?: any
        id: string
        name: string
        description: string
        investigator: string
        organization: string
        countries: string
        contactDescription: string
      }
    }
  | {
    type: ActionType.WORKSPACE_DEXCOM_INTEGRATION_DELETE
    payload: {
      workspaceId: string
      dexcomIntegrationId: string
    }
  }

/* Handle state changing of default workspace */
export const workspacesReducer = (
  state = workspacesDefaultState, 
  {type, payload}: Action
): WorkspacesState => {
  const newState: WorkspacesState = {...state}
  const roleInWorkspaces: Record<string, any> = state.roleInWorkspaces
  let targetIndex

  switch (type) {
    case ActionType.WORKSPACE_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          name: v.string().exist(),
          identityId: v.string().uuid().exist(),
          participantLimit: v.number().integer().exist(),
          projectCollaboratorList: v.array().allow(null).optional(),
        }),
      })

      newState[payload.id] = {
        ...newState[payload.id],
        ...payload
      }

      return {...newState}

    case ActionType.WORKSPACE_NAME_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          name: v.string().exist(),
        }),
      })

      newState[payload.id].name = payload.name

      return {...newState}

    case ActionType.WORKSPACE_DELETE:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })

      delete newState[payload.id]

      return {...newState}

    case ActionType.WORKSPACE_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.array().items(
          v.object({
            id: v.string().uuid().exist(),
            name: v.string().exist(),
            identityId: v.string().uuid().exist(),
            participantLimit: v.number().integer().exist(),
          }),
        ),
      })

      payload.map((workspace: {id: string}) => {
        newState[workspace.id] = {
          ...newState[workspace.id],
          ...workspace
        }
      })

      return {...newState}

    case ActionType.CURRENT_WORKSPACE_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().allow('sharedWithMe', 'favorites').exist(),
        }),
      })

      return {...state, currentWorkspaceId: payload.id}

    case ActionType.WORKSPACE_ROLE_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.array().items(
          v.object({
            workspaceId: v.string().uuid().exist(),
            type: v.string().exist(),
          }),
        ),
      })

      payload.forEach((workspace: {workspaceId: string; type: string}) => {
        roleInWorkspaces[workspace.workspaceId] = workspace.type
      })

      return {...state, roleInWorkspaces}

    case ActionType.WORKSPACE_DEXCOM_INTEGRATION_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          workspaceId: v.string().uuid().exist(),
          dexcomIntegrationList: v.array().exist().items(
            v.object({
              id: v.string().uuid().exist(),
              dexcomApp: v.object().allow(null).optional(),
              name: v.string().exist(),
              description: v.string().exist(),
              investigator: v.string().exist(),
              organization: v.string().exist(),
              countries: v.string().exist(),
              contactDescription: v.string().exist(),
            })
          ),
        })
      })

      newState[payload.workspaceId] = {
        ...newState[payload.workspaceId],
        dexcomIntegrationList: [...payload.dexcomIntegrationList]
      }
      return {...newState}
    case ActionType.WORKSPACE_DEXCOM_INTEGRATION_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          workspaceId: v.string().uuid().exist(),
          dexcomApp: v.object().allow(null).optional(),
          name: v.string().exist(),
          description: v.string().exist(),
          investigator: v.string().exist(),
          organization: v.string().exist(),
          countries: v.string().exist(),
          contactDescription: v.string().exist(),
        })
      })

      if (!newState[payload.workspaceId].dexcomIntegrationList) {
        newState[payload.workspaceId].dexcomIntegrationList = [payload]
        return {...newState}
      }

      targetIndex = newState[payload.workspaceId].dexcomIntegrationList.findIndex(
        (item: DexcomIntegration) => item.id === payload.id
      )

      if (targetIndex !== -1) {
        newState[payload.workspaceId].dexcomIntegrationList[targetIndex] = payload
      } else {
        newState[payload.workspaceId].dexcomIntegrationList.push(payload)
      }

      return {...newState}

    case ActionType.WORKSPACE_DEXCOM_INTEGRATION_DELETE:
      assertPartialSchema({
        payload,
        schema: v.object({
          workspaceId: v.string().uuid().exist(),
          dexcomIntegrationId: v.string().exist(),
        })
      })

      targetIndex = newState[payload.workspaceId].dexcomIntegrationList.findIndex(
        (item: DexcomIntegration) => item.id === payload.dexcomIntegrationId
      )

      if (targetIndex !== -1) {
        newState[payload.workspaceId].dexcomIntegrationList.splice(targetIndex, 1)
      }

      return {...newState}

    default:
      return {...state}
  }
}
