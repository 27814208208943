import {put, takeEvery, debug, select, createAction, SagaIterator, call} from '../../lib'
import {RefreshTokenAction} from '../../lib/auth/refresh_token_action'

import {doAUTH_SET, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT = 'REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT'
export const doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT = createAction(REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT)

export const requestIdentityRefreshTokenSubmitActionCreators = {
  doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
}

export function* requestIdentityRefreshTokenSubmit({payload}: any): SagaIterator {
  debug('saga*requestIdentityRefreshTokenSubmit')

  const refreshToken = yield select((state) => state?.auth?.refreshToken)

  const result = yield call(() => RefreshTokenAction.instance.execute(refreshToken))

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not success')
    debug('saga*requestIdentityRefreshTokenSubmit request error', error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-refresh-token-submit',
        ...result,
      }),
    )
  }

  yield put(
    doAUTH_SET({
      accessToken: result.payload.accessToken,
      refreshToken: result.payload.refreshToken,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* identityRefreshTokenSubmitSaga() {
  yield takeEvery(REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT, requestIdentityRefreshTokenSubmit)
}
