import {useState, useEffect,ReactElement, Dispatch, SetStateAction} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {RIF, useCurrentWorkspaceState, _, useCurrentProjectState, useRoleInProject} from '../../lib'

import {
  selectTheme,
  selectWorkspaces,
  selectProjectData,
  selectProfile,
  selectAuth,
  selectCollaborators,
  selectRequestComplete,
  createDispatchActions,
} from '../../store'

import {
  ProjectSwitchBar,
  Avatars,
  TextLink,
  ButtonReverse,
  PopupResetProject,
  PopupDeleteProject,
  CollaboratorPage,
  SaveBar,
  DataDownloadPage,
} from '..'

import {
  Collaborator,
  Workspace,
} from '../../model'

export const ProjectSettingsPage = () => {
  const {color, fontWeight, fontSize} = selectTheme()
  const navigate = useNavigate()
  const {
    doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH, 
  }: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const accessToken = selectAuth().accessToken
  const { workspaceId, workspace: currentWorkspace }  = useCurrentWorkspaceState()
  const collaboratorsState = selectCollaborators()
  const { projectId, project } = useCurrentProjectState()
  const role = useRoleInProject()

  const [displayCollaboratorsPage, setDisplayCollaboratorsPage] = useState(false)
  const [displayResetProjectPopup, setDisplayResetProjectPopup] = useState(false)
  const [displayDeleteProjectPopup, setDisplayDeleteProjectPopup] = useState(false)
  const [collaboratorList, setCollaboratorList] = useState<Collaborator[]>([])
  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)
  
  useEffect(() => {
    if (collaboratorsState[workspaceId ?? '']) {
      const collaboratorList: Collaborator[] = Object.values(collaboratorsState[workspaceId ?? ''])
      const owner: Collaborator = {
        id: currentWorkspace?.ownerProfile?.id ?? '',
        identityId: currentWorkspace?.ownerProfile?.identityId,
        workspaceId: workspaceId ?? '',
        type: 'owner',
        profile: {...currentWorkspace?.ownerProfile}
      }
      collaboratorList
        .splice(0, 0, owner)
        .filter(c => {
          if (['owner', 'admin'].includes(c.type)) return true
          if (c.type === 'project_collaborator') {
            if (_.find(c.projectCollaboratorList, ['projectId', projectId])) return true
            // if (findResult?.type === 'projectAdmin') return true
          }
          return false
        })
      setCollaboratorList(collaboratorList)
    } else if (workspaceId && !collaboratorsState[workspaceId]) {
      doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH({
        setRequestId,
        payload: {
          accessToken,
          workspaceId,
          projectId,
        }
      })
    }
  }, [collaboratorsState, workspaceId])

  return (
    <>
      {RIF(
        displayResetProjectPopup,
        <PopupResetProject closeAction={setDisplayResetProjectPopup}/>
      )}
      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage {...{
          closeAction: () => {setRenderDataDownloadPage(false)}
        }}/>
      )}
      {RIF(
        displayDeleteProjectPopup,
        <PopupDeleteProject projectName={project?.name ?? ''} closeAction={setDisplayDeleteProjectPopup}/>
      )}
      {RIF(
        displayCollaboratorsPage,
        <CollaboratorPage setDisplayCollaboratorsPage={setDisplayCollaboratorsPage}/>
      )}
      {RIF(
        project?.status !== 'draft',
        <ProjectSwitchBar {...{setRenderDataDownloadPage}} projectPanel='Settings'/>
      )}
      <div
        css={{
          width: '100vw',
          minHeight: project?.status === 'draft' ? '100vh' : 'calc(100vh - 106px)',
          backgroundColor: color.background,
          padding: project?.status === 'draft' ? '90px 0 40px' : '40px 0',
          position: 'relative',
        }}
      >
        {RIF(
          project?.status === 'draft',
          <SaveBar closeAction={()=>navigate(`/project_setup/${projectId}`)} hideButton/>
        )}
        <p css={{
          position: 'absolute',
          top: project?.status === 'draft' ? '80px' : '30px',
          left: '30px',
          fontWeight: fontWeight.thick,
          fontSize: fontSize.h3,
        }}>Settings</p>
        <SettingsBlock
          btnTextAction={() => setDisplayCollaboratorsPage(true)}
          title='Collaborators'
          btnText='Manage Collaborators'
          showBtnText={true}
        >
          <div css={{color: color.grey_400, marginTop: '10px'}}>
            Total <span>{collaboratorList.length}</span> Collaborators.
          </div>
          <Avatars css={{marginTop: '20px', marginLeft: '0'}} {...{users: collaboratorList}} />
        </SettingsBlock>
        {RIF(
          ['owner', 'admin', 'editor', 'projectAdmin'].includes(role) &&
          project?.status !== 'draft',
          <SettingsBlock
            title='Reset Project'
            showBtnText={false}
          >
            <p css={{
              margin: '8px 0 24px',
              color: color.grey_400,
            }}>This action will disconnect all participants and delete all their collected data.</p>
            <ButtonReverse 
              onClick={() => setDisplayResetProjectPopup(true)}
              btnColor='warning'
            >Reset Project To Draft</ButtonReverse>
          </SettingsBlock>
        )}
        {RIF(
          ['owner', 'admin', 'projectAdmin'].includes(role),
          <SettingsBlock
            title='Delete Project'
            showBtnText={false}
          >
            <p css={{
              margin: '8px 0 24px',
              color: color.grey_400,
            }}>This will remove all project settings, content and previously collected data. This action cannot be undone.</p>
            <ButtonReverse 
              onClick={() => setDisplayDeleteProjectPopup(true)}
              btnColor='warning'
            >Delete Project</ButtonReverse>
          </SettingsBlock>
        )}
      </div>
    </>
  )
}

interface SettingsBlockProps {
  title: string
  btnText?: string
  btnTextAction?: () => void
  showBtnText?: boolean,
  children?: ReactElement | ReactElement[]
}

const SettingsBlock = (props: SettingsBlockProps) => {
  const {color, fontWeight} = selectTheme()
  const {title, btnText, btnTextAction, showBtnText, ...rest} = props
  return (
    <div
      css={{
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        background: color.white,
        margin: '0 auto 14px',
        padding: '30px',
        width: '592px',
      }}
    >
      <div css={{display: 'flex', justifyContent: 'space-between', alignItems: 'start'}}>
        <div css={{fontSize: '16px', fontWeight: fontWeight.bold}}>{title}</div>
        {RIF(
          showBtnText,
          <div onClick={btnTextAction} css={{color: color.primary, fontWeight: fontWeight.bold, cursor: 'pointer'}}>
            {btnText}
          </div>,
        )}
      </div>
      {/* pass HTML here */}
      <div {...rest} />
    </div>
  )
}
