import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doDATA_DOWNLOAD_STATUS_SET, DataDownloadStatusData} from '../state'

export const REQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH = 'REQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH'
export const doREQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH = createDoRequestAction(
  REQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH,
)

export const requestProjectDataUpdateMetadataFetchActionCreators = {
  doREQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH,
}

interface RequestProjectDataUpdateMetadataFetchPayload {
  payload: {
    accessToken: string
    requestId: string
    projectId: string
  }
  type: string
}

export function* requestProjectDataUpdateMetadataFetch({
  payload,
}: RequestProjectDataUpdateMetadataFetchPayload): SagaIterator {
  debug('saga*requestProjectDataUpdateMetadataFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {projectId, accessToken} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-data-update-metadata-fetch`,
    accessToken,
    data: {
      projectId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-data-update-metadata-fetch',
        ...result,
        error,
      }),
    )
  }
  const {job, lastUpdate} = result.payload as Omit<DataDownloadStatusData, 'projectId' | 'duplicated'>
  const dataDownloadStatus: DataDownloadStatusData = {
    projectId,
    job,
    lastUpdate,
    duplicated: null,
  }
  yield put(doDATA_DOWNLOAD_STATUS_SET(dataDownloadStatus))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v1/web/project-data-update-metadata-fetch',
      ...result,
    }),
  )
}

export function* ProjectDataUpdateMetadataFetchSaga() {
  yield takeEvery(REQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH, requestProjectDataUpdateMetadataFetch)
}
