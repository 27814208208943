import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doPROFILE_EMAIL_UPDATE} from '../state'

export const EMAIL_UPDATE_SUBMIT = 'EMAIL_UPDATE_SUBMIT'
export const doEMAIL_UPDATE_SUBMIT = createDoRequestAction(EMAIL_UPDATE_SUBMIT)

export const emailUpdateSubmitActionCreators = {
  doEMAIL_UPDATE_SUBMIT,
}

export function* emailUpdateSubmit({payload}: any): SagaIterator {
  debug('saga*emailUpdateSubmit')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        verificationCode: v.number().exist(),
        email: v.string().email({tlds: {allow: false}}).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {email, verificationCode, accessToken} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-email-update-submit`,
    accessToken,
    data: {
      verificationCode,
      email,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: EMAIL_UPDATE_SUBMIT,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-email-update-submit',
        ...result,
        error,
      }),
    )
  }

  yield put(doPROFILE_EMAIL_UPDATE({email}))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* emailUpdateSubmitSaga() {
  yield takeEvery(EMAIL_UPDATE_SUBMIT, emailUpdateSubmit)
}
