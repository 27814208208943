import {sortBy, sortedUniq} from 'lodash'
import {MatrixCoordinate, TableCoordinate} from '../model/coordinate'
import {Table, TableMap, TableValue} from '../model/table'

export const mapTable = <R>(table: Table, transformer: (value: TableValue, coord: MatrixCoordinate) => R): R[][] =>
  table
    .toPairs()
    .map(([row, values]) => Object.entries(values).map(([column, value]) => transformer(value, {row, column})))

export const getDurations = (tables: TableMap): TableCoordinate['duration'][] =>
  sortedUniq(
    sortBy(
      Array.from(tables.keys()).map(({duration}) => duration),
      (duration) => duration?.[0],
    ),
  )
