import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {RIF, setSessionStorage, useCurrentWorkspaceState, _, useCurrentProjectState, useRoleInProject} from '../../lib'

import {
  selectAuth,
  createDispatchActions,
  selectTheme,
  selectCollaborators,
  selectWorkspaces,
  selectRequestComplete,
  selectProfile,
} from '../../store'

import {InviteCollaboratorIcon, WarningIcon} from '../../asset/image'

import {
  SaveBar, 
  Button, 
  SearchBar, 
  ManageCollaboratorPopup, 
  ManageProjectCollaboratorPopup,
  RemoveCollaboratorPopup, 
  CollaboratorList,
} from '..'

import { Collaborator } from '../../model'
import {current} from '@reduxjs/toolkit'

interface CollaboratorPageProps {
  setDisplayCollaboratorsPage?: Dispatch<SetStateAction<boolean>>
}

export const CollaboratorPage = (props: CollaboratorPageProps) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()

  const {
    doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH, 
    doREQUEST_WORKSPACE_COLLABORATOR_DELETE
  }: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const [requestCollaboratorsListId, setRequestCollaboratorsListId] = useState(null)
  const requestCollaboratorsListComplete = selectRequestComplete(requestCollaboratorsListId)
  const [requestWorkspaceCollaboratorDeleteId, setRequestWorkspaceCollaboratorDeleteId] = useState(null)
  const requestWorkspaceCollaboratorDeleteComplete = selectRequestComplete(requestWorkspaceCollaboratorDeleteId)
  const accessToken = selectAuth().accessToken
  const collaboratorsState = selectCollaborators()
  const workspacesState = selectWorkspaces()
  const userProfile = selectProfile()
  const { projectId } = useCurrentProjectState()
  const ownWorkspace = Object.values(workspacesState).find((item) => item.identityId === userProfile?.identityId)
  const { workspaceId, workspace: currentWorkspace} = useCurrentWorkspaceState()
  
  const navigate = useNavigate()
  const role = useRoleInProject()
  const [showingManageCollaboratorPopup, setShowingManageCollaboratorPopup] = useState(false)
  const [editingCollaborator, setEditingCollaborator] = useState<Collaborator | null>(null)
  const [removingCollaborator, setRemovingCollaborator] = useState<any>({})
  const [collaboratorsAmount, setCollaboratorsAmount] = useState(0)
  const [fetchCollaboratorList, setFetchCollaboratorList] = useState(false)
  const [searchingPhrase, setSearchingPhrase] = useState('')
  const profile = selectProfile()
  useEffect(() => {
    if (workspaceId && !collaboratorsState[workspaceId]) return
    if (currentWorkspace?.ownerProfile.identityId === profile.identityId) {
      return
    }
    const collaboratorList: Collaborator[] = Object.values(collaboratorsState[workspaceId ?? ''] ?? {})
    setCollaboratorsAmount(collaboratorList.length)
  }, [workspaceId, currentWorkspace, collaboratorsState])

  const removeCollaborator = () => {
    doREQUEST_WORKSPACE_COLLABORATOR_DELETE({
      setRequestId: setRequestWorkspaceCollaboratorDeleteId,
      payload: {
        accessToken,
        workspaceCollaboratorId: removingCollaborator.id,
        workspaceId,
      },
    })
  }

  const handleClose = () => {
    if (props.setDisplayCollaboratorsPage) props.setDisplayCollaboratorsPage(false)
  }

  useEffect(() => {
    if (!requestWorkspaceCollaboratorDeleteComplete?.success) return
    if (removingCollaborator.profile.email === userProfile.email) {
      setSessionStorage({currentWorkspaceId: ownWorkspace?.id})
      navigate(0)
    } else {
      setRemovingCollaborator({})
    }
  }, [requestWorkspaceCollaboratorDeleteComplete])

  useEffect(() => {
    if (fetchCollaboratorList && workspaceId) {
      const payload = {
        accessToken,
        workspaceId,
        projectId,
      }
      if (!projectId) delete payload.projectId 

      doREQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH({
        setRequestId: setRequestCollaboratorsListId,
        payload,
      })
      setFetchCollaboratorList(false)
    }
  }, [fetchCollaboratorList, workspaceId])

  return (
    <div
      data-testid='collaborator_page'
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 103,
        width: '100vw',
        minHeight: '100vh',
        padding: '84px 30px',
        background: color.background,
        height: '100vh',
        overflowY: 'scroll',
      }}
    >
      <SaveBar closeAction={handleClose} hideButton/>
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: pad.small,
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p
            css={{
              fontSize: '20px',
              fontWeight: fontWeight.thick,
            }}
          >
            Collaborators
          </p>
          {/* {RIF(userPermission === 'viewer', */}
          {/* {RIF(
            ['viewer', 'editor'].includes(userPermission?.type ?? 'viewer'),
            <div
              css={{
                padding: '8px 16px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '5px',
                backgroundColor: 'rgba(241, 144, 110, 0.2)',
                marginLeft: '10px',
              }}
            >
              <img width={24} src={WarningIcon} css={{marginRight: '8px'}} />
              <p css={{fontWeight: fontWeight.medium}}>
                Sorry - Your current access level does not allow you to manage collaborators.
              </p>
            </div>,
          )} */}
        </div>
        {/* {RIF(userPermission !== 'viewer', */}
        {RIF(
          ['owner', 'admin', 'projectAdmin'].includes(role) ,
          <Button
            onClick={() => setShowingManageCollaboratorPopup(true)}
            btnPadding='medium'
            children={
              <span
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  width='18'
                  src={InviteCollaboratorIcon}
                  css={{
                    marginRight: pad.small,
                  }}
                />
                <p
                  css={{
                    fontWeight: fontWeight.thick,
                    color: color.white,
                  }}
                >
                  Invite Collaborator(s)
                </p>
              </span>
            }
          />,
        )}
      </div>
      {/* {RIF(userPermission !== 'viewer', */}
      {/* {RIF(
        !['viewer', 'editor'].includes(userPermission),
        <p
          css={{
            fontWeight: fontWeight.medium,
            marginBottom: pad.xl,
          }}
        >
          You can manage collaborators by changing their workspace access and permissions here.
        </p>,
      )} */}
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '32px',
        }}
      >
        <p
          css={{
            fontWeight: fontWeight.medium,
            marginRight: pad.medium,
          }}
        >
          {collaboratorsAmount + 1} Collaborator(s)
        </p>
        {/* TODO: search function and unify with participant adherence page */}
        <SearchBar onChange={setSearchingPhrase} placeholder='Search Collaborators' />
      </div>
      <p css={{
        fontWeight: fontWeight.thick,
        fontSize: fontSize.h6,
        marginBottom: '8px',
      }}>
        Workspace Member
      </p>
      <p css={{
        color: color.textIcon.light,
        marginBottom: '16px',
      }}>Members have access to all projects within the workspace</p>
      <CollaboratorList
        {...{
          workspaceId: workspaceId ?? '',
          setRemovingCollaborator,
          searchingPhrase,
          setShowingManageCollaboratorPopup,
          setEditingCollaborator,
        }}
      />
      <p css={{
        fontWeight: fontWeight.thick,
        fontSize: fontSize.h6,
        marginBottom: '8px',
      }}>
        Project Contributor
      </p>
      <p css={{
        color: color.textIcon.light,
        marginBottom: '16px',
      }}>Collaborators do not have access to the workspace, only to the specific projects specified.</p>
      <CollaboratorList
        {...{
          workspaceId: workspaceId ?? '',
          setRemovingCollaborator,
          searchingPhrase,
          setShowingManageCollaboratorPopup,
          setEditingCollaborator,
          type: 'projectCollaborator'
        }}
      />
      {RIF(
        showingManageCollaboratorPopup && !role.includes('project'),
        <ManageCollaboratorPopup
          {...{
            setShowing: setShowingManageCollaboratorPopup,
            workspaceId: workspaceId ?? '',
            setFetchCollaboratorList,
            editingCollaborator,
            setEditingCollaborator,
          }}
        />,
      )}
      {RIF(
        showingManageCollaboratorPopup && role.includes('project'),
        <ManageProjectCollaboratorPopup
          {...{
            setShowingManageProjectCollaboratorPopup: setShowingManageCollaboratorPopup,
            workspaceId: workspaceId ?? '',
            setFetchCollaboratorList,
            editingCollaborator,
            setEditingCollaborator,
          }}
        />,
      )}
      {RIF(
        removingCollaborator.id,
        <RemoveCollaboratorPopup
          {...{
            collaborator: removingCollaborator,
            setCollaborator: setRemovingCollaborator,
            removeAction: removeCollaborator,
          }}
        />,
      )}
    </div>
  )
}
