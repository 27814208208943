import {cloneDeep} from 'lodash'
import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {DB_NAME, DbOperationResult} from '../../db'
import {DailyDataType} from '../../db/db_data_setter'

export enum ParticipantDailyDataActionType {
  HANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT = 'HANDLE_PARTICIPANT_DAILY_SUMMARY_DATA_DB_RESULT',
}

export const doHANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT = createAction(
  ParticipantDailyDataActionType.HANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT,
)

export type dateDataState = Record<number, boolean>
type TypeDataDateState = Record<string, dateDataState>

export interface ParticipantDailyData {
  [participantId: string]: TypeDataDateState
}

interface RootState {
  participantDailyData: ParticipantDailyData
}
/* selector */
export const selectParticipantDailyData = (participantId: string) => {
  return useSelector((state: RootState) => state.participantDailyData[participantId])
}

export const handleParticipantDailyDataDbResultActionCreators = {
  doHANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT,
}

export const participantDailyDataDefaultState: ParticipantDailyData = {}

type Action = {
  type: ParticipantDailyDataActionType.HANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT
  payload: {
    dbOperationResultList: DbOperationResult<DailyDataType>[]
  }
}

export const participantDailyDataReducer = (
  state: ParticipantDailyData = participantDailyDataDefaultState,
  {type, payload}: Action,
) => {
  const newState = cloneDeep(state)
  switch (type) {
    case ParticipantDailyDataActionType.HANDLE_PARTICIPANT_DAILY_DATA_DB_RESULT: {
      assertPartialSchema({
        payload,
        schema: v.object({
          dbOperationResultList: v.array().items(
            v.object({
              dbName: v.string().required(),
              participantId: v.string().uuid().required(),
              dataType: v.string().required(),
              yymmddIndex: v.number().required(),
              operation: v.string().required(),
              result: v.bool().required(),
            }),
          ),
        }),
      })

      const {dbOperationResultList} = payload
      dbOperationResultList.forEach((dbResult) => {
        const {dbName, participantId, dataType, yymmddIndex, result} = dbResult
        if (dbName == DB_NAME.DailyData) {
          if (!newState[participantId]) {
            newState[participantId] = {}
          }
          const participantData = newState[participantId]
          if (participantData) {
            if (dataType) {
              if (!participantData[dataType]) {
                participantData[dataType] = {}
              }
              const typeData = participantData[dataType]
              if (typeData) {
                typeData[yymmddIndex] = result
              }
            }
          }
        }
      })
      return newState
    }
    default:
      return newState
  }
}
