import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  selectTheme,
  selectAuth,
  createDispatchActions,
  selectProfile,
  selectQuestionnaires,
} from '../../store'
import {
  SaveBar, 
  QuestionnaireContentList,
  PopupConfirmDiscardQuestionnaireChanges,
} from '..'
import { 
  ContentListItem, 
  ContentListItemCategory, 
  Question, 
  Section, 
} from '../../model'
import {_, RIF, useCurrentProjectState} from '../../lib'

export const QuestionnairePage = () => {
  const {pad, color} = selectTheme()
  const {
    doQUESTIONNAIRES_RESTORE,
    doQUESTIONNAIRES_BACKUP_SET,
    doQUESTIONNAIRES_EDITING_STATUS_RESET,
    doQUESTIONNAIRES_EDITING_STATUS_SET,
    doQUESTIONNAIRES_SET,
  }: any = createDispatchActions()
  const accessToken = selectAuth().accessToken
  const profile = selectProfile()

  const navigate = useNavigate()
  const { projectId } = useCurrentProjectState()
  const questionnairesState = selectQuestionnaires()
  const questionnaireId = questionnairesState?.editing?.editingQuestionnaireId
  const [questionnaireName, setQuestionnaireName] = useState<string>('Untitled Questionnaire')
  const [renderPopupConfirmDiscardQuestionnaireChanges, setRenderPopupConfirmDiscardQuestionnaireChanges] = useState<boolean>(false)

  useEffect(() => {
    if (!questionnaireId) return
    const findEditingQuestionnaireResult = _.find(
      questionnairesState.questionnaireList,
      ['id', questionnaireId]
    )
    if (!findEditingQuestionnaireResult?.name) return
    setQuestionnaireName(findEditingQuestionnaireResult.name)
  }, [questionnaireId])

  const checkIfClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      !!questionnairesState?.editing?.editingBlock && 
      e.currentTarget.id === 'questionnaireBackground'
    ) {
      doQUESTIONNAIRES_EDITING_STATUS_SET({
        editingBlock: null
      })
    }
  }

  useEffect(() => {
    if (!accessToken && !profile?.id) return navigate('/')
    if (!projectId) return navigate('/project')
  }, [])

  const saveQuestionnaire = () => {
    const sectionList: Section[] = []
    let currentSectionId: string
    let questionList: Question[] = []
    const findEditingQuestionnaireResult = _.find(
      questionnairesState.questionnaireList,
      ['id', questionnaireId]
    )
    const editingQuestionnaireCopy = JSON.parse(JSON.stringify(findEditingQuestionnaireResult))
    editingQuestionnaireCopy.contentList.map((item: ContentListItem, index: number, array: ContentListItem[]) => {
      if (
        item.category === ContentListItemCategory.Section && 
        currentSectionId !== item.id
      ) {
        if (currentSectionId) {
          const updatedSection = updateSection(currentSectionId, questionList)
          sectionList.push(updatedSection)
        }
        currentSectionId = item.id
        questionList = []
      } else if (item.category === ContentListItemCategory.Question) {
        const findQuestionResult = _.find(
          questionnairesState.questionList,
          ['id', item.id]
        )
        const questionCopy = JSON.parse(JSON.stringify(findQuestionResult))
        delete questionCopy.id
        delete questionCopy.sectionId
        questionList.push(questionCopy)
      }
      if (index === array.length - 1) {
        const updatedSection = updateSection(currentSectionId, questionList)
        sectionList.push(updatedSection)
      }
    })
    editingQuestionnaireCopy.sectionList = sectionList
    doQUESTIONNAIRES_SET(editingQuestionnaireCopy)
    doQUESTIONNAIRES_BACKUP_SET({
      questionnaireList: questionnairesState.questionnaireList,
      sectionList: questionnairesState.sectionList,
      questionList: questionnairesState.questionList,
    })
    doQUESTIONNAIRES_EDITING_STATUS_RESET()
  }

  const updateSection = (currentSectionId: string, questionList: Question[]) => {
    const findSectionResult = _.find(
      questionnairesState.sectionList,
      ['id', currentSectionId]
    )
    if (!findSectionResult) return
    const sectionCopy = JSON.parse(JSON.stringify(findSectionResult))
    sectionCopy.questionList = questionList
    delete sectionCopy.questionnaireId
    delete sectionCopy.id
    if (!sectionCopy.description || sectionCopy.description === 'Description goes here') {
      sectionCopy.description = ' '
    }

    return sectionCopy
  }

  const handleCloseWithoutSaving = () => {
    if (
      !questionnairesState.editing.viewOnly && 
      !_.isEqual(
        questionnairesState.backup,
        {
          questionnaireList: questionnairesState.questionnaireList,
          sectionList: questionnairesState.sectionList,
          questionList: questionnairesState.questionList,
        }
      )
    ) return setRenderPopupConfirmDiscardQuestionnaireChanges(true)
    closeQuestionnairePage()
  }

  const closeQuestionnairePage = () => {
    doQUESTIONNAIRES_RESTORE()
    doQUESTIONNAIRES_EDITING_STATUS_RESET()
  }

  return (
    <div
      onClick={checkIfClickOutside}
      id='questionnaireBackground'
      css={{
        width: '100vw',
        height: '100vh',
        background: color.background,
        paddingBottom: pad.xl,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99999,
        overflowY: 'scroll',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {RIF(
        renderPopupConfirmDiscardQuestionnaireChanges,
        <PopupConfirmDiscardQuestionnaireChanges {...{
          closeAction: setRenderPopupConfirmDiscardQuestionnaireChanges,
          confirmAction: closeQuestionnairePage,
        }}/>
      )}
      <SaveBar
        {...{
          handleClickSave: saveQuestionnaire,
          closeAction: handleCloseWithoutSaving,
          title: questionnaireName,
          viewOnly: questionnairesState.editing.viewOnly,
        }}
      />
      {/* <SaveBar/> */}
      <main
        css={{
          width: '57.4rem',
          paddingTop: '90px',
        }}
      >
        <QuestionnaireContentList />
        {/* <QuestionnaireSectionList/> */}
      </main>
    </div>
  )
}
