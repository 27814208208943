// import Lottie from 'lottie-web'
// import loadingAnimation from '../../asset/lotties/loading_animation_attention.json'
// import {useState, useEffect, createRef} from 'react'
import {Link, useParams} from 'react-router-dom'
import {RIF, useCurrentProjectState} from '../../lib'
import {
  selectTheme, useSelectSettings,
  // createDispatchActions,
  // selectRequestComplete,
  // selectAuth,
  selectBatchData,
  // selectArchiveStatus,
  selectProjectData,
} from '../../store'
import {
  // DataDownloadSection,
  ButtonReverse,
  //   ToolTipHover,
  //   DataDownloadPopup,
  //   PopupSimple
} from '..'
import { Dispatch, SetStateAction } from 'react'
import {DownloadIcon, DownloadGreyIcon} from '../../asset/image'
// import {RIF, _, useClickOutside} from '../../lib'
import {ParticipantStateType} from '../../model'

// export interface ArchiveStatus {
//   jobStatus: 'never' | 'running' | 'succeeded' | 'failed'
//   latestDataTimestamp: number | null
//   latestBuildTimestamp: number | null
//   downloadAvailable: boolean
// }

export interface ProjectSwitchBarProps {
  projectPanel: string
  setRenderDataDownloadPage: Dispatch<SetStateAction<boolean>>
}

export const ProjectSwitchBar = (props: ProjectSwitchBarProps) => {
  const {pad, color, fontWeight} = selectTheme()

  const {projectPanel, setRenderDataDownloadPage, ...rest} = props

  // const {
  //   doREQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH,
  //   doREQUEST_PROJECT_ARCHIVE_BUILD_METADATA_FETCH,
  // }: any = createDispatchActions()
  // const [requestId, setRequestId] = useState(null)
  // const requestComplete = selectRequestComplete(requestId)
  // const [requestArchiveBuildMetaDataFetchId, setRequestArchiveBuildMetaDataFetchId] = useState(null)
  // const requestArchiveBuildMetaDataFetchComplete = selectRequestComplete(requestArchiveBuildMetaDataFetchId)
  // const accessToken = selectAuth()?.accessToken
  const { projectId, project } = useCurrentProjectState()
  const batchId = project?.batchList?.[0]?.id ?? ''
  const batchData = selectBatchData()
  const participantList: ParticipantStateType[] = batchData?.[batchId]?.participantList || []
  const noParticipants = participantList.length === 0

  // const [downloadAvailable, setDownloadAvailable] = useState(false)
  // const [dataDownloadUrl, setDataDownloadUrl] = useState('')
  // const [jobStatusQueue, setJobStatusQueue] = useState<('running' | 'succeeded')[]>([])
  // const [downloadDataBtnTooltipHover, setDownloadDataBtnTooltipHoverState] = useState(false)
  // const [runningStateTooltipHover, setRunningStateTooltipHoverState] = useState(false)
  // const [displayDataDownloadPopup, setDisplayDataDownloadPopup] = useState(false)
  // const [archiveStatus, setArchiveStatus] = useState<ArchiveStatus | undefined>(undefined)
  // const archiveStatusState = selectArchiveStatus() ?? {}
  // const archiveStatus: ArchiveStatus = selectArchiveStatus()[projectId as string] || {}
  // const {jobStatus, downloadAvailable} = archiveStatus
  // const dataDownloadPopupRef: any = createRef()
  // const downloadDataBtnRef: any = createRef()
  // useClickOutside(dataDownloadPopupRef, () => setDisplayDataDownloadPopup((prev) => !prev), downloadDataBtnRef)
  // const animRef: any = createRef()

  // useEffect(() => {
  //   if (!projectId || !accessToken) return
  //   doREQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH({
  //     setRequestId,
  //     payload: {
  //       accessToken,
  //       projectId,
  //     },
  //   })
  //   doREQUEST_PROJECT_ARCHIVE_BUILD_METADATA_FETCH({
  //     setRequestId: setRequestArchiveBuildMetaDataFetchId,
  //     payload: {
  //       accessToken,
  //       projectId,
  //     },
  //   })
  // }, [])

  // useEffect(() => {
  //   if (!projectId || !accessToken) return
  //   const reqBuildMetadata = setInterval(() => {
  //     doREQUEST_PROJECT_ARCHIVE_BUILD_METADATA_FETCH({
  //       setRequestId: setRequestArchiveBuildMetaDataFetchId,
  //       payload: {
  //         accessToken,
  //         projectId,
  //       },
  //     })
  //   }, 30000) // 30 secs
  //   return () => clearInterval(reqBuildMetadata)
  // }, [])

  // useEffect(() => {
  //   if (!requestArchiveBuildMetaDataFetchComplete?.success) return
  //   const archiveStatusFromBE = requestArchiveBuildMetaDataFetchComplete.payload.data.payload
  //   setArchiveStatus(archiveStatusFromBE)
  // }, [requestArchiveBuildMetaDataFetchComplete])

  // useEffect(() => {
  //   if (_.isEmpty(archiveStatus)) return
  //   // setDownloadAvailable(archiveStatus.downloadAvailable)
  //   if (downloadAvailable) {
  //     doREQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH({
  //       setRequestId,
  //       payload: {
  //         accessToken,
  //         projectId,
  //       },
  //     })
  //   }
  //   if (['running', 'succeeded'].includes(jobStatus as string)) {
  //     const tempJobStatusQueue = [...jobStatusQueue, jobStatus]
  //     const tempJobStatusQueueSet: any = new Set(tempJobStatusQueue)
  //     setJobStatusQueue([...tempJobStatusQueueSet])
  //   }
  // }, [archiveStatus])

  // useEffect(() => {
  //   // if (requestComplete?.payload.statusCode === 404) return
  //   if (!requestComplete?.success) return
  //   setDataDownloadUrl(requestComplete?.payload?.payload?.downloadUrl)
  // }, [requestComplete])

  // useEffect(() => {
  //   Lottie.loadAnimation({
  //     container: animRef.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     animationData: loadingAnimation,
  //   })
  //   return () => Lottie.stop()
  // }, [jobStatus])
  const { betaEnabled } = useSelectSettings()

  return (
    <div
      {...rest}
      css={{
        width: '100%',
        height: '56px',
        boxSizing: 'border-box',
        backgroundColor: color.white,
        marginTop: '50px',
        padding: '0 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `1px solid ${color.grey_100}`,
        position: 'relative',
        zIndex: 98,
        boxShadow: '0px 2px 10px 0px #0000000A',
      }}
    >
      <div
        css={{
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Link to={`/dashboard/${projectId}`}>
          <Tab name='Overview' {...{projectPanel}} />
        </Link>
        <Link to={`/participants/${projectId}`}>
          <Tab name='Participants' {...{projectPanel}} />
        </Link>
        <Link to={`/adherence/${projectId}`}>
          <Tab name='Adherence' {...{projectPanel}} />
        </Link>
        {RIF(
          betaEnabled,
          <Link to={`/data_visualization/${projectId}/${participantList[0]?.id}`}>
            <Tab beta={true} name='Data Visualization' {...{projectPanel}} />
          </Link>,
        )}
        {RIF(
          betaEnabled,
          <Link to={`/data_analysis/${projectId}/${participantList[0]?.id}`}>
            <Tab beta={true} name='Data Analysis' {...{projectPanel}} />
          </Link>,
        )}
        <Link to={`/settings/${projectId}`}>
          <Tab name='Settings' {...{projectPanel}}/>
        </Link>
      </div>
      {/* download section */}
      {/* <DataDownloadSection /> */}
      <ButtonReverse
        onClick={() => setRenderDataDownloadPage(true)}
        disabled={noParticipants}
        btnPadding='medium'
        btnColor='primary'
        css={{position: 'relative'}}
        children={
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              color: noParticipants ? color.disabled : color.primary,
              fontWeight: fontWeight.bold,
            }}
          >
            <img width='20' css={{marginRight: pad.small}} src={noParticipants ? DownloadGreyIcon : DownloadIcon} />
            Download Data
          </div>
        }
      />
    </div>
  )
}

const Tab = (props: {name: string; projectPanel: string; beta?: boolean}) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const active = props.projectPanel === props.name
  return (
    <div
      css={{
        fontWeight: fontWeight.bold,
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '40px',
        cursor: 'pointer',
        color: `${active ? color.primary : color.black}`,
        borderBottom: `${active ? '2px' : '0px'} ${color.primary} solid`,
        ':hover': {
          color: color.primary,
        },
      }}
    >
      {props.name}
      {RIF(
        props.beta,
        <p css={{
          padding: '2px 6px',
          borderRadius: '2px',
          marginLeft: '8px',
          backgroundColor: color.grey_50,
          color: color.grey_400,
          fontWeight: fontWeight.thick,
          fontSize: fontSize.h7,
        }}>Beta</p>
      )}
    </div>
  )
}
