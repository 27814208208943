import {useState, SetStateAction, useEffect, Dispatch} from 'react'
import {CustomizeVisualizerIcon} from '../../asset/image'
import {selectTheme, selectAuth, selectProjectSettings, selectProjectData} from '../../store'
import {Select, Tooltip} from '..'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import {darken, _, useCurrentProjectState} from '../../lib'
import {VisualizerGraphResolution} from '../../shared/db'
import {ParticipantItem} from '../../model'
import {useParams, useNavigate} from 'react-router-dom'
import {VisualizerDurationResolution} from '../../lib/chart_data/model/VisualizerDurationResolution'
interface VisualizerSidebarProps {
  handleRenderCustomizationPopup: () => void
  participantList: ParticipantItem[]
  // allParticipantTagList: ITag[]
  onParticipantClicked: (participantItem: ParticipantItem) => void
  setDateRange: (value: SetStateAction<DateObject[]>) => void
  setGraphDateRange: (value: SetStateAction<number[]>) => void
  // setSelectedDate: (value: SetStateAction<DateObject>) => void
  dateRange: DateObject[]
  // selectedDate: DateObject
  currentParticipantId: string
  resolution: VisualizerGraphResolution | VisualizerDurationResolution
  setResolution: Dispatch<SetStateAction<VisualizerGraphResolution | VisualizerDurationResolution>>
  // setRenderTimeseriesPage: Dispatch<SetStateAction<boolean>>
}

export const VisualizerSideBar = (props: VisualizerSidebarProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    handleRenderCustomizationPopup,
    participantList,
    // allParticipantTagList,
    onParticipantClicked,
    setDateRange,
    setGraphDateRange,
    // setSelectedDate,
    dateRange,
    // selectedDate,
    currentParticipantId,
    resolution,
    setResolution,
    // setRenderTimeseriesPage,
  } = props
  // const [resolution, setResolution] = useState<Record<string, string>>({value: 'daily', label: 'Daily'})
  const navigate = useNavigate()
  const { projectId } = useCurrentProjectState()
  const participantIdFromUrl = useParams().participantId || ''
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined // FIXME: temporary cast to any to avoid invalid key type warning

  const defaultVisualizerSidebarSetting = {
    Daily: [
      'Garmin Connect Heart Rate',
      'Garmin Connect Stress',
      'Garmin Connect Steps',
      'Garmin Connect SleepStaging',
      'Garmin Connect Calories',
      'Garmin Device Wearing Time',
    ],
    MultiDay: [
      'Garmin Connect Heart Rate',
      'Garmin Connect Stress',
      'Garmin Connect Steps',
      'Garmin Connect SleepStaging',
    ],
    TimeSeries: [],
    Weekly: [
      'Garmin Connect Heart Rate',
      'Garmin Connect Stress',
      'Garmin Connect Steps',
      'Garmin Connect SleepStaging',
      'Garmin Connect Calories',
      'Garmin Device Wearing Time',
    ],
    Monthly: [],
    EntireDuration: [],
  }

  const [visualizerContentList, setVisualizerContentList] = useState<string[]>(
    defaultVisualizerSidebarSetting[resolution],
  )

  const [resolutionOption, setResolutionOption] = useState<{
    value: VisualizerGraphResolution | VisualizerDurationResolution
    label: string
  }>({
    value: VisualizerGraphResolution.Daily,
    label: 'Daily',
  })
  const resolutionOptions = [
    // {value: VisualizerGraphResolution.Daily, label: 'Daily'},
    {value: VisualizerGraphResolution.TimeSeries, label: 'Time Series'},
    {value: VisualizerGraphResolution.Daily, label: 'Daily'},
    {value: VisualizerGraphResolution.Weekly, label: 'Weekly'},
    {value: VisualizerDurationResolution.EntireDuration, label: 'Entire Duration'},
    {value: 'Monthly', label: 'Monthly (coming soon)', isdisabled: true},
    // {value: 'timeSeries', label: 'Time Series'}
  ]

  useEffect(() => {
    if (!projectSettings?.visualizerSidebarSetting) return
    if (!resolution) return
    // FIXME: invalid typing of VisualizerDurationResolution?
    if (!projectSettings.visualizerSidebarSetting[resolution]) {
      return setVisualizerContentList(defaultVisualizerSidebarSetting[resolution])
    }
    setVisualizerContentList(
      projectSettings.visualizerSidebarSetting[resolution].map(({type}: {type: string}) =>
        type.replace(/([A-Z])/g, ' $1'),
      ),
    )
  }, [projectSettings, resolution])

  useEffect(() => {
    if (resolutionOption.value !== resolution) setResolution(resolutionOption.value)
  }, [resolutionOption])

  const [dataSetOptions, setDataSetOptions] = useState<Record<string, string>[]>([])

  useEffect(() => {
    if (!participantList.length) return
    const participantOptionList = participantList.map((participant: ParticipantItem) =>
      participantItemToOption(participant),
    )
    if (_.isEqual(participantOptionList, dataSetOptions)) return
    setDataSetOptions(participantOptionList)
  }, [participantList])

  const [currentParticipant, setCurrentParticipant] = useState<Record<string, string> | null>(null)

  useEffect(() => {
    if (!currentParticipantId) return
    const findResult = _.find(participantList, ['id', currentParticipantId])
    if (!findResult) return
    setCurrentParticipant(participantItemToOption(findResult))
  }, [currentParticipantId])

  useEffect(() => {
    if (!dataSetOptions.length) return
    if (currentParticipant) return
    if (participantIdFromUrl) {
      const defaultParticipantItem = _.find(participantList, ['id', participantIdFromUrl])
      if (defaultParticipantItem) {
        return handleDataSetChange(participantItemToOption(defaultParticipantItem))
      }
    }
    handleDataSetChange(participantItemToOption(participantList[0]))
  }, [dataSetOptions])

  const handleDataSetChange = (e: Record<string, string>) => {
    const participantId = e.value
    const participant = _.find(participantList, ['id', participantId])
    if (!participant) return
    onParticipantClicked(participant)
    setCurrentParticipant(participantItemToOption(participant))
  }

  const participantItemToOption = (participant: ParticipantItem) => {
    return {value: participant.id, label: participant.insignia}
  }

  const handleChangeResolution = (e: {
    value: VisualizerGraphResolution | VisualizerDurationResolution
    label: string
  }) => {
    if (e.value === VisualizerGraphResolution.TimeSeries) {
      // setRenderTimeseriesPage(true)
      navigate(`/data_visualization_timeseries/${projectId}/${participantIdFromUrl}`)
    } else if (e.value === VisualizerDurationResolution.EntireDuration) {
      setResolutionOption({value: VisualizerDurationResolution.EntireDuration, label: 'Entire Duration'})
    } else if (e.value === VisualizerGraphResolution.Weekly) {
      setResolutionOption({value: VisualizerGraphResolution.Weekly, label: 'Weekly'})
    } else {
      setResolutionOption({value: VisualizerGraphResolution.Daily, label: 'Daily'})
    }
  }

  return (
    <div
      css={{
        width: '216px',
        height: 'calc(100vh - 56px)',
        backgroundColor: color.white,
        padding: '16px',
        borderRight: `1px solid ${color.borderLight}`,
      }}
    >
      <div
        css={{
          width: '100%',
          paddingBottom: '24px',
          borderBottom: `1px solid ${color.borderLight}`,
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: '8px',
          }}
        >
          <p
            css={{
              fontSize: fontSize.h7,
              color: color.textIcon.secondary,
              marginRight: '4px',
            }}
          >
            Data Set
          </p>
          <Tooltip content='lorem ipsm' />
        </div>
        <Select value={currentParticipant} options={dataSetOptions} onChange={handleDataSetChange} />
      </div>
      <div
        css={{
          width: '100%',
          padding: '24px 0',
          borderBottom: `1px solid ${color.borderLight}`,
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: '8px',
          }}
        >
          <p
            css={{
              fontSize: fontSize.h7,
              color: color.textIcon.secondary,
              marginRight: '4px',
            }}
          >
            Date
          </p>
          <Tooltip content='lorem ipsm' />
        </div>
        <DatePicker
          value={dateRange}
          onChange={(dates) => {
            // if (resolution === 'Weekly' && dates instanceof DateObject) {
            //   const weekLater = dates.add(7, 'days')
            //   setDateRange([dates, weekLater])
            //   setGraphDateRange(
            //     [parseInt(dates.format('YYMMDD')), parseInt(weekLater.format('YYMMDD'))]
            //   )
            //   return
            // }
            if (_.isArray(dates) && dates.length === 2) {
              setDateRange(dates)
              setGraphDateRange(dates.map((date) => parseInt(date.format('YYMMDD'))))
              return
            }
            // if (dates instanceof DateObject) {
            //   setDateRange([dates, dates])
            //   setGraphDateRange([parseInt(dates.format('YYMMDD')), parseInt(dates.format('YYMMDD'))])
            //   setSelectedDate(dates)
            // }
          }}
          range={true}
          rangeHover={true}
          format='MMM D, YYYY'
          maxDate={new Date()}
          style={{
            padding: '6px 12px',
            width: '184px',
            height: '38px',
            fontWeight: fontWeight.medium,
            fontSize: fontSize.h7,
            border: `1px solid ${color.grey_160}`,
            color: 'black',
            cursor: 'pointer',
          }}
        />
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: '8px',
            marginTop: '24px',
          }}
        >
          <p
            css={{
              fontSize: fontSize.h7,
              color: color.textIcon.secondary,
              marginRight: '4px',
            }}
          >
            Resolution
          </p>
          <Tooltip content='lorem ipsm' />
        </div>
        <Select
          value={resolutionOption}
          options={resolutionOptions}
          onChange={handleChangeResolution}
          isOptionDisabled={(option: any) => option.isdisabled}
        />
      </div>
      <div
        css={{
          width: '100%',
          paddingTop: '24px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '8px',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
                marginRight: '4px',
              }}
            >
              Data Cards
            </p>
            <Tooltip content='lorem ipsm' />
          </div>
          <button
            onClick={handleRenderCustomizationPopup}
            css={{
              height: '20px',
              width: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#fff',
              border: '1px solid',
              borderColor: color.grey_160,
              cursor: 'pointer',
              borderRadius: '5px',
              ':hover': {
                borderColor: `${darken(color.grey_160, 10)}`,
                background: color.hover,
              },
            }}
          >
            <img src={CustomizeVisualizerIcon} width={14} />
          </button>
        </div>
        <ul css={{listStyleType: 'disc', paddingLeft: '10px'}}>
          {visualizerContentList.map((type) => (
            <li
              key={type}
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.light,
                marginBottom: '4px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {type}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
