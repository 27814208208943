import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {selectAuth, createDispatchActions, selectRequestComplete, selectProjectData, selectBatchData} from '../../store'

import {PopupConfirmation} from '..'

export interface ProjectResetPopPropsT {
  closeAction: Dispatch<SetStateAction<boolean>>
}

export const PopupResetProject = (props: ProjectResetPopPropsT) => {
  const {closeAction} = props

  const {doREQUEST_PROJECT_RESET}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const accessToken = selectAuth()?.accessToken
  const navigate = useNavigate()
  const projectId = useParams()?.projectId
  const projectData = selectProjectData()
  const batchId = projectData && projectId && projectData?.[projectId]?.batchList?.[0]?.id

  const handleResetProject = () => {
    if (!projectId || !batchId) return
    doREQUEST_PROJECT_RESET({
      setRequestId,
      payload: {
        accessToken,
        projectId,
        batchId,
      },
    })
  }

  useEffect(() => {
    if (!requestComplete?.success) return
    navigate(`/project_setup/${projectId}`)
  }, [requestComplete])

  return (
    <div
      data-testid='project_reset_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PopupConfirmation
        {...props}
        method='Reset'
        name='Project'
        description='Your project will reset to draft with the current setup.'
        term1='I understand that this will disconnect all participants and log them out of the project on the participant app.'
        term2="I understand that this will delete all the participants' information and data."
        buttonText='Reset Project to Draft'
        closeAction={closeAction}
        doAction={handleResetProject}
      />
    </div>
  )
}
