import { IEntityModel } from './base'
import { IGarminDevice } from './garmin_device'
import { IMethod } from './method'
import { IProject } from './project'
import { IQuestionnaire } from './questionnaire'
import { IStopwatchGarminStream } from './stopwatch_garmin_stream'
import { IStopwatchMovesenseStream } from './stopwatch_movesense_stream'
import { ITaskSet } from './task_set'
import { ITimer } from './timer'
import { ITodo } from './todo'
import { IWorkspace } from './workspace'

export enum TaskType {
  Todo = 'todo',
  Timer = 'timer',
  TaskSet = 'task_set',
  GarminDevice = 'garmin_device',
  Questionnaire = 'questionnaire',
  StopwatchGarminStream = 'stopwatch_garmin_stream',
  StopwatchMovesenseStream = 'stopwatch_movesense_stream',
  StopwatchMovesenseLogData = 'stopwatch_movesense_log_data',
}

export type MovesenseTaskType =
  | TaskType.StopwatchMovesenseStream
  | TaskType.StopwatchMovesenseLogData

export enum ReminderType {
  Date = 'date',
  Cron = 'cron',
}
export interface ReminderItem {
  type: ReminderType
  value: string
}

export interface ITask extends IEntityModel {
  index?: number
  enabled?: boolean
  reminderList: ReminderItem[]
  color?: string
  required: boolean
  scheduleRepeat?: boolean
  scheduleDescription?: string

  workspaceId: string
  workspace?: IWorkspace
  projectId: string
  project?: IProject
  methodId: string
  method?: IMethod

  type: TaskType

  todo?: ITodo
  timer?: ITimer
  stopwatchGarminStream?: IStopwatchGarminStream
  taskSet?: ITaskSet
  questionnaire?: IQuestionnaire
  garminDevice?: IGarminDevice
  taskSetParentId?: string
  taskSetParent?: ITaskSet
  stopwatchMovesenseStream?: IStopwatchMovesenseStream

  //TODO: remove this and related implementation, this just exist for the api to be backward compatible for mobile
  //can remove after the majority version of mobile app is newer than 1.0.45
  taskSetId?: string | null
}
