import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {IIdentityProjectSettings} from '../../shared/db'
import {doPROJECT_SETTINGS_SET, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'

export const REQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE = 'REQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE'
export const doREQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE = createDoRequestAction(
  REQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE,
)

export const requestIdentityProjectSettingsUpdateActionCreators = {
  doREQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE,
}

interface Payload {
  type: string
  payload: {
    accessToken: string
    requestId: string
    identityProjectSettingsId: string
    visualizerSidebarSetting?: IIdentityProjectSettings['visualizerSidebarSetting']
  }
}

export function* requestIdentityProjectSettingsUpdate({payload}: Payload): SagaIterator {
  debug('saga*requestIdentityProjectSettingsUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        identityProjectSettingsId: v.string().uuid().exist(),
        visualizerSidebarSetting: v.object().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {accessToken, identityProjectSettingsId, visualizerSidebarSetting} = payload
  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/identity-project-settings-update`,
    accessToken,
    data: {
      identityProjectSettingsId,
      visualizerSidebarSetting,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-project-settings-update',
        ...result,
        error,
      }),
    )
  }

  yield put(doPROJECT_SETTINGS_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* identityProjectSettingsUpdateSaga() {
  yield takeEvery(REQUEST_IDENTITY_PROJECT_SETTINGS_UPDATE, requestIdentityProjectSettingsUpdate)
}
