import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  SagaIterator,
  assertPartialSchema,
  call,
} from '../../lib'
import { insertDexcomDataToDb } from '../db/db_data_setter'
import {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doPARTICIPANT_DEXCOM_DATA_SET,
  doPARTICIPANT_DEXCOM_TIMELINE_DATA_SET,
} from '../state'

export const REQUEST_VISUALIZER_DEXCOM_DATA_FETCH = 'REQUEST_VISUALIZER_DEXCOM_DATA_FETCH'
export const doREQUEST_VISUALIZER_DEXCOM_DATA_FETCH = createDoRequestAction(
  REQUEST_VISUALIZER_DEXCOM_DATA_FETCH,
)

export const requestVisualizerDexcomDataFetchActionCreators = {
  doREQUEST_VISUALIZER_DEXCOM_DATA_FETCH: doREQUEST_VISUALIZER_DEXCOM_DATA_FETCH,
}

export function* requestVisualizerDexcomDataFetch({payload}: any): SagaIterator {
  debug('saga*requestVisualizerDexcomDataFetch')

  try {
    assertPartialSchema({
      payload,
      schema: v.object({
        participantId: v.string().uuid().required(),
        yymmddIndexes: v.array().items(v.number().example(231011)).required(),
        projection: v.object().optional().allow(null),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {accessToken, participantId, yymmddIndexes, projection} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/dexcom-data-fetch`,
    accessToken,
    data: {
      participantId,
      yymmddIndexes,
      projection,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_VISUALIZER_DEXCOM_DATA_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/dexcom-data-fetch',
        ...result,
        error,
      }),
    )
  }

  // must update timeline data first otherwise it won't show on chart
  // don't know the root reason yet
  yield put(
    doPARTICIPANT_DEXCOM_TIMELINE_DATA_SET({
      participantId,
      yymmddIndexes,
      data: result.payload,
    }),
  )

  const dbSetReuslt = yield call(async () => {
    return await insertDexcomDataToDb(
      participantId,
      yymmddIndexes,
      result.payload,
    )
  })
  
  yield put(
    doPARTICIPANT_DEXCOM_DATA_SET({
      participantId,
      yymmddIndexes,
      dbOperationResultList: dbSetReuslt,
    }),
  )
  
  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result.payload,
    }),
  )
}

export function* visualizeDexcomDataFetchSaga() {
  yield takeEvery(REQUEST_VISUALIZER_DEXCOM_DATA_FETCH, requestVisualizerDexcomDataFetch)
}
