import {SetStateAction, Dispatch, useState, useEffect} from 'react'
import CronParser from 'cron-parser'
import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import {Button, ToolTipHover} from '..'
import {TaskReminder} from '../../model'
import {CancelBlackIcon} from '../../asset/image'
import {RadioGroup as ReactRadioGroup, Radio as ReactRadio} from 'react-radio-group'

const dateToCronExpression = (
  time: string | undefined,
  reminderFreq: 'daily' | 'weekly',
  dayWeeks: DayNumberWeekT[],
) => {
  if (!time) return
  const minute = time.split(':')[1]
  const hour = time.split(':')[0]
  const dayWeekSet = new Set(dayWeeks)
  if (reminderFreq === 'daily') return `${minute} ${hour} * * *`
  return `${minute} ${hour} * * ${dayWeeks.length === 0 ? '*' : [...dayWeekSet].join()}`
}

const dateToISOString = (timeString: string | undefined, dateString: string | undefined) => {
  if (!timeString || !dateString) return
  const [year, month, day] = dateString.split('-')
  const [hour, minute] = timeString.split(':')
  const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute)))
  return date.toISOString()
}

const getCurrentDateString = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const ISOToDateTime = (isoString: string | undefined) => {
  if (!isoString) return undefined
  const utcDate = new Date(isoString)
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000
  const date = new Date(utcDate.getTime() + timezoneOffset)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const dateString = `${year}-${month}-${day}`
  const timeString = `${hours}:${minutes}`

  return {dateString, timeString}
}

const cronExpressionToDateTime = (reminderValue: string | undefined) => {
  if (!reminderValue) return undefined
  const cronInterval = CronParser.parseExpression(reminderValue)
  const {dayOfWeek, hour, minute} = cronInterval.fields
  const timeString = `${hour[0].toString().padStart(2, '0')}:${minute[0].toString().padStart(2, '0')}`
  const daysOfWeek = [...dayOfWeek] as DayNumberWeekT[]
  const freq: 'daily' | 'weekly' = dayOfWeek.length >= 7 ? 'daily' : 'weekly'
  return {freq, daysOfWeek, timeString}
}

type DayNumberWeekT = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
export interface AddReminderPopProsT {
  editingReminder: TaskReminder | undefined
  setDisplayReminderPop: Dispatch<SetStateAction<boolean>>
  reminderList: TaskReminder[]
  setReminderList: Dispatch<SetStateAction<TaskReminder[]>>
}

export const AddReminderPop = (props: AddReminderPopProsT) => {
  const {color} = selectTheme()
  const {editingReminder, setDisplayReminderPop, reminderList, setReminderList} = props

  const isEditing = !!editingReminder
  const editingReminderType = isEditing ? (editingReminder.type === 'date' ? 'once' : 'recurrent') : undefined
  const editingReminderISODateTime = editingReminderType === 'once' ? ISOToDateTime(editingReminder?.value) : undefined
  const editingReminderCronDateTime =
    editingReminderType === 'recurrent' ? cronExpressionToDateTime(editingReminder?.value) : undefined
  const editingReminderDate = editingReminderISODateTime?.dateString
  const editingReminderFreq = editingReminderCronDateTime?.freq
  const editingReminderRepeatDays = editingReminderCronDateTime?.daysOfWeek
  const editingReminderTime = editingReminderISODateTime?.timeString || editingReminderCronDateTime?.timeString

  const [reminderType, setReminderType] = useState<'recurrent' | 'once'>(editingReminderType || 'recurrent')
  const [reminderFreq, setReminderFreq] = useState<'daily' | 'weekly'>(editingReminderFreq || 'daily')
  const [reminderRepeatDays, setReminderRepeatDays] = useState<DayNumberWeekT[]>(editingReminderRepeatDays || [])
  const [reminderDate, setReminderDate] = useState<string>(editingReminderDate || getCurrentDateString())
  const [reminderTime, setReminderTime] = useState<string>(editingReminderTime || '10:00')

  const handleChangeType = (e: 'recurrent' | 'once') => {
    setReminderType(e)
    setReminderFreq('daily')
  }

  const handleChangeFreq = (e: 'daily' | 'weekly') => setReminderFreq(e)
  const handleChangeDate = (e: any) => setReminderDate(e.target.value)
  const handleChangeTime = (e: any) => setReminderTime(e.target.value)

  const handleClickSave = () => {
    let submitData = ''
    if (reminderType === 'once') {
      submitData = dateToISOString(reminderTime, reminderDate) as string
      if (!isEditing) {
        setReminderList((prev) => [
          ...prev,
          {
            type: 'date',
            value: submitData,
          },
        ])
      }
      if (isEditing) {
        const targetIndex = reminderList.findIndex((item) => item === editingReminder)
        const tempReminderList = [...reminderList]
        tempReminderList[targetIndex] = {
          type: 'date',
          value: submitData,
        }
        setReminderList(tempReminderList)
      }
    }

    if (reminderType === 'recurrent') {
      submitData = dateToCronExpression(reminderTime, reminderFreq, reminderRepeatDays) as string
      if (!isEditing) {
        setReminderList((prev) => [
          ...prev,
          {
            type: 'cron',
            value: submitData,
          },
        ])
      }
      if (isEditing) {
        const targetIndex = reminderList.findIndex((item) => item === editingReminder)
        const tempReminderList = [...reminderList]
        tempReminderList[targetIndex] = {
          type: 'cron',
          value: submitData,
        }
        setReminderList(tempReminderList)
      }
    }
    setDisplayReminderPop(false)
  }

  return (
    <div
      data-testid='add_reminder_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
      }}
    >
      <div
        css={{
          top: 'calc(50vh - 300px)',
          left: 'calc(50vw - 300px)',
          position: 'fixed',
          width: '560px',
          background: color.white,
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
          padding: '32px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div>
          <img
            onClick={() => setDisplayReminderPop(false)}
            src={CancelBlackIcon}
            width={12}
            height={12}
            css={{cursor: 'pointer'}}
          />
          <div css={{marginTop: '40px', fontWeight: '700', fontSize: '20px'}}>
            {isEditing ? 'Edit' : 'Add an'} Auto-Reminder
          </div>

          {/* reminderType */}
          <div css={{marginTop: '32px'}}>
            <p>Is this reminder recurring or one-time-only?</p>
            <ReactRadioGroup
              name='reminderTypeSelector'
              onChange={handleChangeType}
              css={{display: 'flex', alignItems: 'center', marginTop: '16px'}}
            >
              <label
                key={'recurrent'}
                css={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
              >
                <ReactRadio
                  value={'recurrent'}
                  css={{margin: '0', marginRight: '6px'}}
                  checked={reminderType === 'recurrent'}
                />
                Recurrent
              </label>

              <label
                key={'once'}
                css={{
                  marginLeft: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <ReactRadio value={'once'} css={{margin: '0', marginRight: '6px'}} checked={reminderType === 'once'} />
                Only once
              </label>
            </ReactRadioGroup>
          </div>

          {/* reminderFreq */}
          {RIF(
            reminderType === 'recurrent',
            <div css={{marginTop: '32px'}}>
              <p>How often does this reminder occur?</p>
              <ReactRadioGroup
                name='reminderFreqSelector'
                onChange={handleChangeFreq}
                css={{display: 'flex', alignItems: 'center', marginTop: '16px'}}
              >
                <label
                  key={'daily'}
                  css={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
                >
                  <ReactRadio
                    value={'daily'}
                    css={{margin: '0', marginRight: '6px'}}
                    checked={reminderFreq === 'daily'}
                  />
                  Daily
                </label>

                <label
                  key={'weekly'}
                  css={{
                    marginLeft: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ReactRadio
                    value={'weekly'}
                    css={{margin: '0', marginRight: '6px'}}
                    checked={reminderFreq === 'weekly'}
                  />
                  Weekly
                </label>
              </ReactRadioGroup>
            </div>,
          )}

          {/* reminderRepeatDays */}
          {RIF(
            reminderType === 'recurrent' && reminderFreq === 'weekly',
            <div css={{marginTop: '32px'}}>
              <p>Which day(s) will the reminder occur?</p>
              <div css={{marginTop: '16px', display: 'flex', alignItems: 'center'}}>
                <RepeatDateDot {...{text: 'S', setReminderRepeatDays, value: 0, reminderRepeatDays}} />
                <RepeatDateDot {...{text: 'M', setReminderRepeatDays, value: 1, reminderRepeatDays}} />
                <RepeatDateDot {...{text: 'T', setReminderRepeatDays, value: 2, reminderRepeatDays}} />
                <RepeatDateDot {...{text: 'W', setReminderRepeatDays, value: 3, reminderRepeatDays}} />
                <RepeatDateDot {...{text: 'T', setReminderRepeatDays, value: 4, reminderRepeatDays}} />
                <RepeatDateDot {...{text: 'F', setReminderRepeatDays, value: 5, reminderRepeatDays}} />
                <RepeatDateDot {...{text: 'S', setReminderRepeatDays, value: 6, reminderRepeatDays}} />
              </div>
            </div>,
          )}

          {/* reminderDate */}
          {RIF(
            reminderType === 'once',
            <div css={{marginTop: '32px'}}>
              <p css={{marginBottom: '8px'}}>Select a date</p>
              <input
                data-testid='reminder_date_input'
                onChange={handleChangeDate}
                type='date'
                value={reminderDate}
                min={getCurrentDateString()}
                css={{
                  width: '184px',
                  height: '42px',
                  fontSize: '14px',
                  padding: '12px 16px',
                  borderRadius: '5px',
                  border: `1px solid ${color.grey_600}`,
                  cursor: 'pointer',
                }}
              />
            </div>,
          )}

          {/* reminderTime */}
          <div css={{marginTop: '32px'}}>
            <p css={{marginBottom: '8px'}}>Select a time (Note: Based on the Participant's local time)</p>
            <input
              data-testid='reminder_time_input'
              onChange={handleChangeTime}
              type='time'
              value={reminderTime}
              css={{
                width: '184px',
                height: '42px',
                fontSize: '14px',
                padding: '12px 16px',
                borderRadius: '5px',
                border: `1px solid ${color.grey_600}`,
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        <div css={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '40px'}}>
          <p onClick={() => setDisplayReminderPop(false)} css={{color: color.primary, cursor: 'pointer'}}>
            Cancel
          </p>
          <Button onClick={handleClickSave} data-testid='reminder_save_button' btnPadding='large' type='button'>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export interface RepeatDateDotPropsT {
  text: 'S' | 'M' | 'T' | 'W' | 'F'
  setReminderRepeatDays: Dispatch<SetStateAction<DayNumberWeekT[]>>
  value: DayNumberWeekT
  reminderRepeatDays: DayNumberWeekT[]
}

export const RepeatDateDot = (props: RepeatDateDotPropsT) => {
  const {color} = selectTheme()
  const {text, setReminderRepeatDays, value, reminderRepeatDays} = props
  const match = reminderRepeatDays.some((day) => day === value)
  const [clicked, setClicked] = useState(match || false)
  const [hoverState, setHoverState] = useState(false)

  useEffect(() => {
    if (clicked) setReminderRepeatDays((prev) => [...prev, value])
    if (!clicked) setReminderRepeatDays((prev) => prev.filter((item) => item !== value))
  }, [clicked])

  const convertDayNumberToString = (value: number) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return days[value % 7]
  }

  return (
    <div
      onClick={() => setClicked((prev) => !prev)}
      onMouseOver={() => setHoverState(true)}
      onMouseOut={() => setHoverState(false)}
      css={{
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        border: `1px solid ${clicked ? color.primary : color.grey_100}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        cursor: 'pointer',
        marginRight: '16px',
        background: clicked ? color.primary : hoverState ? color.hover : color.white,
        position: 'relative',
        color: clicked ? color.white : color.black,
      }}
    >
      {text}
      {RIF(
        hoverState,
        <ToolTipHover
          {...{
            title: convertDayNumberToString(value),
            hoverTopPosition: '-130%',
            pseudoTopPosition: '90%',
            pseudoRightPosition: '50%',
          }}
        />,
      )}
    </div>
  )
}
