import { RIF, _ } from '../../lib'
import {
  createDispatchActions,
  selectAuth,
  selectProjectData,
  selectTheme,
  useSelectSettings,
  selectBatchData,
  selectCorrelationVariable,
  selectProjectSettings,
} from '../../store'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { ArrowLeftIcon } from '../../asset/image'
import { DataDownloadPage, AnalysisSideBar, AnalysisMatrixBlock } from '..'
import { IProject, IBatch } from '../../shared/db'
import { ParticipantItem, DataTypeDisplayNameMap, CorrelationVariableTypeDisplayNameMap } from '../../model'
import {DateObject} from 'react-multi-date-picker'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import { v4 } from 'uuid'
import { Variable, VariableDataType, VariableType } from '../../shared/analysis'

export const AnalysisPage = () => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {
    doREQUEST_PROJECT_FETCH, 
    doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
  }: any = createDispatchActions()

  const auth = selectAuth()
  const accessToken = auth?.accessToken
  const {betaEnabled} = useSelectSettings()
  const projectId = useParams().projectId ?? ''
  const projectData = selectProjectData() as Record<string, IProject>
  const project = projectId ? projectData[projectId] : undefined
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined
  const correlationVariableList = selectCorrelationVariable(projectId)
  const batchId = _.head(project?.batchList)?.id
  const batchData = selectBatchData() as Record<string, IBatch>
  const batch = batchId ? batchData[batchId] : undefined
  const participantList = (batch?.participantList ?? []).map(
    ({id, insignia, tagList}): ParticipantItem => ({
      id,
      insignia: insignia ?? '',
      tagList: tagList ?? [],
    }),
  )
  const [duration, setDuration] = useState<DateObject[]>([new DateObject().subtract(7, 'days'), new DateObject()])
  const [currentParticipantId, setCurrentParticipantId] = useState<string>('')
  const [resolution, setResolution] = useState<any>()

  // the actual variable list that will be passed into the correlation graph
  const [displayingVariableList, setDisplayingVariableList] = useState<Variable[]>([])

  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)
  const [requestId, setRequestId] = useState(null)
  
  const onParticipantSelect = (participant: ParticipantItem) => {
    setCurrentParticipantId(participant.id)
  }

  const convertDurationToDataDateRange = (duration: DateObject[]) => {
    return duration.map((date) => parseInt(date.format('YYMMDD')))
  }

  AttemptRequestParticipantStatus({
    requestAdherence: {durationBeforeToday: 6},
  })

  useEffect(() => {
    if (!projectId) return
    if (!project) {
      doREQUEST_PROJECT_FETCH({
        setRequestId,
        payload: {
          accessToken,
          projectId,
        },
      })
    }
    if (!projectSettings) {
      doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH({
        setRequestId,
        payload: {
          accessToken,
        },
      })
    }
    if (!correlationVariableList.length) {
      doREQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH({
        setRequestId,
        payload: {
          accessToken,
          projectId,
        },
      })
    }
  }, [projectId])

  useEffect(() => {
    if (!participantList.length) return
    if (currentParticipantId.length) return
    setCurrentParticipantId(_.head(participantList)?.id || '')
  }, [participantList])

  useMemo(() => {
    if (!correlationVariableList || correlationVariableList.length === 0) return
    // randomly select 2 variables to display
    const initDataTypeList = _
      .sampleSize(correlationVariableList, 2)
    setDisplayingVariableList(initDataTypeList)
  }, [correlationVariableList])

  return RIF(
    betaEnabled,
    <div css={{
      position: 'fixed',
      top: 0,
      left: 0, 
      zIndex: 104,
      width: '100vw',
      height: '100vh',
      backgroundColor: color.background,
    }}>
      {RIF(
        renderDataDownloadPage,
        <DataDownloadPage 
          {...{closeAction: () => setRenderDataDownloadPage(false)}}
        />
      )}

      {/* title bar */}
      <div css={{
        width: '100vw',
        height: '56px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.04)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: color.white,
        padding: '8px 32px 8px 16px',
        zIndex: 99,
        position: 'relative',
      }}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            css={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '8px',
              borderRight: `1px solid ${color.grey_100}`,
            }}
          >
            <Link
              css={{
                width: '28px',
                height: '28px',
                borderRadius: '5px',
                backgroundColor: color.white,
                backgroundImage: `url(${ArrowLeftIcon})`,
                backgroundPosition: 'center',
                backgroundSize: '20px',
                backgroundRepeat: 'no-repeat',
                ':hover': {
                  backgroundColor: color.hover,
                },
              }}
              to={`/adherence/${projectId}`}
            />
          </div>
          <p css={{
            fontWeight: fontWeight.thick,
            marginLeft: '20px',
          }}>
            Data Analysis
          </p>
        </div>
      </div>

      {/* page content */}
      <div css={{display: 'flex'}}>
        <AnalysisSideBar {...{
          participantList,
          onParticipantSelect,
          currentParticipantId,
          duration,
          setDuration,
          resolution,
          setResolution,
          displayingVariableList,
          setDisplayingVariableList,
        }} />
        {/* right part of page content */}
        <div css={{
          padding: '32px 24px',
          flex: 1,
        }}>
          <p css={{
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h3,
            marginBottom: '16px',
          }}>Correlation and Distribution Analysis</p>
          <div css={{
            width: '100%',
            backgroundColor: color.white,
            padding: '16px 32px 32px',
            borderRadius: '5px',
          }}>
            <div css={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '24px',
            }}>
              <div css={{
                height: '16px',
                width: '16px',
                borderRadius: '50%',
                backgroundColor: color.primary,
                marginRight: '8px',
              }}/>
              <p css={{
                fontSize: fontSize.h7,
              }}>{`${_.find(participantList, ['id', currentParticipantId])?.insignia ?? ''} (${duration[0].format('MMM D')} - ${duration[1].format('MMM D')})`}</p>
            </div>
            <div css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              {/* graph */}
              <AnalysisMatrixBlock {...{
                participantId: currentParticipantId,
                dataDateRange: convertDurationToDataDateRange(duration),
                displayingVariableList,
              }}/>
              <div css={{
                height: '100%',
                width: '244px',
                padding: '16px',
                border: `1px solid ${color.border._80}`,
                borderRadius: '5px',
              }}>
                <p css={{
                  fontWeight: fontWeight.thick,
                  marginBottom: '16px',
                }}>Comparison Result</p>
                {displayingVariableList.map((variable, index) => (
                  <ComparisonBlock {...{
                    variable,
                    index,
                    key: v4(),
                  }}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface ComparisonBlockProps {
  variable: Variable
  index: number
}

const ComparisonBlock = (props: ComparisonBlockProps) => {
  const {variable, index} = props
  const {color, fontSize, fontWeight} = selectTheme()

  const getVariableCode = () => {
    const charCode = 'A'.charCodeAt(0)
    return String.fromCharCode(charCode + index)
  }

  const getVariableDisplayName = ({dataType, variableType}: {dataType: VariableDataType, variableType: VariableType}): string => {
    const displayNameMap = CorrelationVariableTypeDisplayNameMap[dataType] as Record<VariableType, string>
    return displayNameMap ? displayNameMap[variableType] : variableType
  }

  return (
    <div css={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    }}>
      {RIF(
        index !== 0,
        <p css={{
          fontSize: fontSize.h7,
          marginRight: '8px',
        }}>vs.</p>
      )}
      <div css={{
        display: 'flex',
        alignItems: 'center',
        padding: '4px',
        borderRadius: '5px',
        backgroundColor: color.surface.grey.dark,
      }}>
        <p css={{
          minWidth: '12px',
          minHeight: '12px',
          lineHeight: '12px',
          borderRadius: '50%',
          fontSize: fontSize.h8,
          fontWeight: fontWeight.thick,
          color: color.white,
          backgroundColor: color.surface.dark,
          textAlign: 'center',
          marginRight: '4px',
        }}>{getVariableCode()}</p>
        <p css={{
          fontSize: fontSize.h7,
          color: color.textIcon.secondary,
        }}>{`${DataTypeDisplayNameMap[variable.dataType]} (${getVariableDisplayName(variable)})`}</p>
      </div>
    </div>
  )
}