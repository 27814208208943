import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {
  doREQUEST_ERROR, 
  doREQUEST_COMPLETE,
  doFAVORITE_LIST_SET,
} from '../state'

export const REQUEST_WORKSPACE_FAVORITE_LIST_FETCH = 'REQUEST_WORKSPACE_FAVORITE_LIST_FETCH'
export const doREQUEST_WORKSPACE_FAVORITE_LIST_FETCH = createDoRequestAction(REQUEST_WORKSPACE_FAVORITE_LIST_FETCH)

export const requestWorkspaceFavoriteListFetchActionCreators = {
  doREQUEST_WORKSPACE_FAVORITE_LIST_FETCH,
}

interface Payload {
  type: string
  payload: {
    accessToken: string
    requestId: string
  }
}

export function* requestWorkspaceFavoriteListFetch({payload}: Payload): SagaIterator {
  debug('saga*requestWorkspaceFavoriteListFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    accessToken,
  } = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/workspace-favorite-list-fetch`,
    accessToken,
    data: {},
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_WORKSPACE_FAVORITE_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/workspace-favorite-list-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doFAVORITE_LIST_SET([...result.payload])
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* workspaceFavoriteListFetchSaga() {
  yield takeEvery(REQUEST_WORKSPACE_FAVORITE_LIST_FETCH, requestWorkspaceFavoriteListFetch)
}
