import {assertPartialSchema, createAction, useSelector, v, setSessionStorage} from '../../../lib'
import Instrumentation from '../../../instrumentation'
import Tracking from '../../../tracking'
import { Profile } from '../../../model'

export enum ProfileActionType {
  PROFILE_SET = 'PROFILE_SET',
  PROFILE_EMAIL_UPDATE = 'PROFILE_EMAIL_UPDATE',
  LOGOUT = 'LOGOUT',
}

export const doPROFILE_SET = createAction(ProfileActionType.PROFILE_SET)
export const doPROFILE_EMAIL_UPDATE = createAction(ProfileActionType.PROFILE_EMAIL_UPDATE)

/* selector */
export const selectProfile = () => {
  return useSelector((state: {profile: Profile}) => state.profile)
}

export const profileActionCreators = {
  doPROFILE_SET,
  doPROFILE_EMAIL_UPDATE,
}

interface ProfileState {
  createdAt?: string | null
  updatedAt?: string | null
  id: string | null
  identityId?: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  title: string | null
  imageUrl: string | null
}

export const profileDefaultState: ProfileState = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  title: null,
  imageUrl: null,
}

type Action =
  | {
      type: ProfileActionType.PROFILE_SET
      payload: {
        id: string
        email: string
        firstName: string
        lastName: string
        title: string | null
        imageUrl: string | null
      }
    }
  | {
      type: ProfileActionType.PROFILE_EMAIL_UPDATE
      payload: {
        email: string
      }
    }
  | {
      type: ProfileActionType.LOGOUT
      payload: null
    }

export const profileReducer = (state = {...profileDefaultState}, {type, payload}: Action): ProfileState => {
  switch (type) {
    case ProfileActionType.PROFILE_SET:
      assertPartialSchema({
        payload,
        schema: v
          .object({
            id: v.string().uuid().optional(),
            email: v
              .string()
              .email({tlds: {allow: false}})
              .optional(),
            firstName: v.string().optional(),
            lastName: v.string().optional(),
            title: v.string().allow('', null).optional(),
            imageUrl: v.string().allow(null).uri().optional(),
          })
          .allow(null),
      })
      setSessionStorage({profile: payload})
      Instrumentation.shared.perform((api) => {
        if (payload.id) {
          api.setUser({
            id: payload.id,
            email: payload.email,
            username: `${payload.firstName} ${payload.lastName}`,
          })
        }
      })
      return payload
    case ProfileActionType.PROFILE_EMAIL_UPDATE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          email: v
            .string()
            .email({tlds: {allow: false}})
            .required(),
        }),
      })
      const newProfileState = {...state, email: payload.email}
      setSessionStorage({profile: newProfileState})
      const userInfo = {
        email: newProfileState.email,
        username: `${newProfileState.firstName} ${newProfileState.lastName}`,
      }
      Instrumentation.shared.perform((api) => {
        if (newProfileState.id) {
          api.setUser({
            id: newProfileState.id,
            ...userInfo,
          })
        }
      })
      Tracking.shared.perform((api) => {
        api.identify(newProfileState.id, userInfo)
      })
      return newProfileState
    }
    case ProfileActionType.LOGOUT:
      return profileDefaultState
    default:
      return state
  }
}
