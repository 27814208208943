import {useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import {WarningIcon} from '../../asset/image'
import {selectTheme, selectRequestComplete, createDispatchActions} from '../../store'
import {v, debug, useForm, useTranslation, RIF} from '../../lib'
import {LabelBox, Legend, Input, Button} from '..'

const schema = v.object({
  email: v.string().required(),
  password: v.string().min(8).required(),
})

export const LoginForm = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {pad, color} = selectTheme()

  const {doREQUEST_IDENTITY_AUTHENTICATE}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)
  const [inputComplete, setInputComplete] = useState(false)
  const [hasError, setHasError] = useState(false)

  const locationState: {email: string | undefined} = useLocation().state as any
  const [emailValue, setEmailValue] = useState('') // order to auto fill up the email to next page
  const {handleSubmit, watch, control} = useForm({schema})

  useEffect(() => {
    if (locationState && locationState?.email) setEmailValue(locationState.email)
    if (watch('email')) setEmailValue(watch('email'))
  }, [locationState, watch('email')])

  const onSubmit = (data: any) => {
    debug('LoginForm.onSubmit', {data})
    doREQUEST_IDENTITY_AUTHENTICATE({
      setRequestId,
      payload: data,
    })
  }

  const onError = (error: any) => {
    debug('LoginForm.onError', {error})
    setHasError(true)
  }

  useEffect(() => {
    if (!requestComplete?.success) return setHasError(true)
    if (requestComplete?.payload?.statusCode === 500) return alert('server error')
    if (requestComplete.payload.type === 'MFA_REQUIRED') return navigate(`/account_setup?${emailValue}`)
    navigate('/project')
  }, [requestComplete])

  useEffect(() => {
    if (emailValue !== '' || watch('password') !== '') setHasError(false)
    if (emailValue !== '' && watch('password')?.length >= 8) return setInputComplete(true)
    setInputComplete(false)
  }, [emailValue, watch('password')])

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      css={{
        marginTop: '50px',
        width: '446px',
        padding: '40px 30px',
        background: color.white,
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
      }}
    >
      <Legend css={{marginBottom: '16px', fontSize: '20px'}}>Log In</Legend>
      <div css={{marginBottom: '40px'}}>
        Don't have an account?
        <Link to='/signup' css={{color: color.primary}}>
          {' '}
          Sign up for free
        </Link>
      </div>
      <LabelBox
        {...{
          label: t('sign_in.form_field_email_label'),
        }}
      >
        <Controller
          control={control}
          name='email'
          render={({field: {name, value, onChange}}) => (
            <Input
              {...{
                name: name,
                value: emailValue,
                onChange: onChange,
              }}
              hasError={hasError}
              type='email'
              placeholder={t('sign_in.form_field_email_placeholder')}
              css={{marginBottom: '27px'}}
            />
          )}
        />
      </LabelBox>
      <LabelBox
        {...{
          label: t('sign_in.form_field_password_label'),
        }}
      >
        <Controller
          control={control}
          name='password'
          render={({field: {name, value, onChange}}) => (
            <Input
              {...{
                name: name,
                value: value,
                onChange: onChange,
              }}
              hasError={hasError}
              type='password'
              placeholder={t('sign_in.form_field_password_placeholder')}
            />
          )}
        />
      </LabelBox>

      <div css={{display: 'flex', alignItems: 'center', marginTop: '50px', position: 'relative'}}>
        {RIF(
          hasError,
          <div css={{display: 'flex', alignItems: 'center', position: 'absolute', top: '-40px'}}>
            <img src={WarningIcon} width='20' />
            <span css={{marginLeft: '8px', color: color.warning}}>Incorrect email and password combination</span>
          </div>,
        )}

        <Button type='submit' btnPadding='large' disabled={!inputComplete || hasError}>
          Log In
        </Button>

        <Link
          to='/account_setup/?forget_password'
          state={{email: emailValue}}
          css={{
            color: color.primary,
            marginLeft: pad.large,
            textDecoration: 'none',
          }}
        >
          {t('sign_in.button_forgot_password')}
        </Link>
      </div>
    </form>
  )
}
