import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'
import {doMETHOD_DELETE_TASK, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const REQUEST_TASK_DELETE = 'REQUEST_TASK_DELETE'
export const doREQUEST_TASK_DELETE = createDoRequestAction(REQUEST_TASK_DELETE)

export const requestTaskDeleteActionCreators = {
  doREQUEST_TASK_DELETE,
}

export function* requestTaskDelete({payload}: any): SagaIterator {
  debug('saga*requestTaskDelete')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().optional(),
        taskId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {taskId, accessToken} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-delete`,
    accessToken,
    data: {
      taskId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_DELETE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/task-delete',
        ...result,
        error,
      }),
    )
  }

  yield put(doMETHOD_DELETE_TASK(payload.taskId))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* taskDeleteSaga() {
  yield takeEvery(REQUEST_TASK_DELETE, requestTaskDelete)
}
