import {useState, useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {selectTheme, createDispatchActions, selectRequestComplete} from '../../store'
import {v, RIF, debug, useForm, useTranslation} from '../../lib'
import {LabelBox, Legend, Input, Button, Checkbox, InputWarning} from '..'

const schema = v.object({
  email: v.string().email({tlds: {allow: false}}).required(),
  password: v.string().min(8).required(),
  termAgreement: v.boolean().not(false),
})

// props for storybook only now
export interface SignupFormProps {
  defaultEmail?: string
  defaultPassword?: string
  defaultTermAgreement?: boolean
}

enum EmailError {
  required = 'required',
  conflict = 'conflict',
  format = 'format',
  valid = 'valid',
}

enum PasswordError {
  required = 'required',
  valid = 'valid',
  length = 'length',
  format = 'format',
}

export const SignupForm = (props: SignupFormProps) => {
  const {t} = useTranslation()
  const {pad, color} = selectTheme()

  /* ------------------ req ------------------ */
  const {doREQUEST_IDENTITY_CREATE}: any = createDispatchActions()
  const [requestId, setRequestId] = useState(null)
  const requestComplete = selectRequestComplete(requestId)

  /* ------------------ basic state ------------------ */
  const navigate = useNavigate()
  const [inputCompleted, setInputComplete] = useState(false)

  // warning message
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [emailErrorMsg, setEmailErrorMsg] = useState('')
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('')
  const [hasTermAgreementRequiredError, setHasTermAgreementRequiredError] = useState(false)

  const {register, control, handleSubmit, formState, watch, trigger} = useForm({schema})
  const formValues = watch()

  useEffect(() => {
    setInputComplete(false)
    setEmailError(null)
    setPasswordError(null)
    if (formValues.email !== '' && formValues.password !== '' && formValues.termAgreement !== false) {
      setInputComplete(true)
    }
  }, [formValues.email, formValues.password, formValues.termAgreement])

  const validateInputData = (data: any) => {
    const passwordRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
    const passwordChecked = passwordRegex.test(data.password)
    if (!passwordChecked) setPasswordError(PasswordError.format)
    const emailSchema = v.string().email({tlds: {allow: false}})
    const {error} = emailSchema.validate(data.email)
    if (error) setEmailError(EmailError.valid)
    if (!passwordChecked || error) return false
    return true
  }

  const onSubmit = (data: any) => {
    debug('SignupForm.onSubmit', {data})
    if (!validateInputData(data)) return
    doREQUEST_IDENTITY_CREATE({
      setRequestId,
      payload: data,
    })
  }

  // handle form validation
  const onError = (error: any) => {
    debug('SignupForm.onError', {error})
    setEmailError('')
    setPasswordError('')
    if (error?.email?.type == 'string.empty') return setEmailError(EmailError.required)
    if (error?.email?.type == 'string.email') return setEmailError(EmailError.valid)
    if (error?.password?.type == 'string.empty') return setPasswordError(PasswordError.required)
    if (error?.password?.type == 'string.min') return setPasswordError(PasswordError.length)
    setHasTermAgreementRequiredError(error?.termAgreement ? true : false)
  }

  // update warning messages in real time after the form has been submitted
  const onChange = async () => {
    if (formState.isSubmitted) {
      await trigger() // trigger the validation
      onError(formState.errors) // run onError with current validation errors
    }
  }

  useEffect(() => {
    setEmailErrorMsg('')
    if (emailError === EmailError.required) return setEmailErrorMsg('Email Required')
    if (emailError === EmailError.valid || emailError === EmailError.format)
      return setEmailErrorMsg('Must be a valid email')
    if (emailError === EmailError.conflict) return setEmailErrorMsg('An account with this email already exists')
  }, [emailError])

  useEffect(() => {
    setPasswordErrorMsg('')
    if (passwordError === PasswordError.required) return setPasswordErrorMsg('Password Required')
    if (passwordError === PasswordError.format) return setPasswordErrorMsg('Must be a valid password')
    if (passwordError === PasswordError.length) return setPasswordErrorMsg('Must be at least 8 characters')
  }, [passwordError])

  useEffect(() => {
    if (!requestComplete) return
    if (requestComplete?.payload?.statusCode === 500) return alert('server error')
    if (requestComplete?.payload?.statusCode === 400) return setEmailError(EmailError.format)
    if (requestComplete?.payload?.statusCode === 410) return setEmailError(EmailError.conflict)
    if (requestComplete?.payload?.statusCode === 200) return navigate(`/account_setup?${watch('email')}`)
  }, [requestComplete])

  return (
    <div css={{width: '386px'}}>
      <form onChange={onChange} onSubmit={handleSubmit(onSubmit, onError)}>
        <Legend css={{marginBottom: '16px', fontSize: '20px'}}>{t('sign_up.sign_up')}</Legend>
        <div css={{marginBottom: '40px'}}>
          Already have an account?&nbsp;
          <a onClick={() => navigate('/')} css={{color: color.primary, cursor: 'pointer'}}>
            Sign in
          </a>
        </div>
        <LabelBox
          {...{
            label: t('sign_up.form_field_email_label'),
            warningMsg: emailErrorMsg,
          }}
        >
          <Input
            {...register('email')}
            css={{marginBottom: '25px', marginTop: '5px'}}
            defaultValue={props.defaultEmail}
            type='email'
            placeholder={t('sign_up.form_field_email_placeholder')}
          />
        </LabelBox>
        <LabelBox
          {...{
            label: t('sign_up.form_field_password_label'),
            warningMsg: passwordErrorMsg,
          }}
        >
          <>
            <Input
              {...register('password')}
              css={{marginBottom: pad.medium, marginTop: '5px'}}
              defaultValue={props.defaultPassword}
              type='password'
              placeholder={t('sign_up.form_field_password_placeholder')}
            />
            <span css={{color: color.grey_400}}>8+ characters. Must include uppercase, lowercase, and a number.</span>
          </>
        </LabelBox>
        <label
          css={{
            marginBottom: pad.medium,
            marginTop: '30px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Controller
            control={control}
            defaultValue={props.defaultTermAgreement || false}
            name='termAgreement'
            render={({field: {name, value, onChange}}) => (
              <Checkbox
                {...{
                  name: name,
                  value: value,
                  onChange: onChange,
                }}
              />
            )}
          />
          <span css={{fontSize: '13px', marginLeft: '5px', cursor: 'pointer'}}>
            I Agree to the&nbsp;
            <a css={{fontSize: '13px', color: color.primary, textDecoration: 'underline'}} href='https://help.labfront.com/terms-of-service'>
              Terms and Conditions
            </a>{' '}
            and&nbsp;
            <a css={{fontSize: '13px', color: color.primary, textDecoration: 'underline'}} href='https://help.labfront.com/privacy-policy'>
              Privacy Policy
            </a>
          </span>

          {RIF(
            !formValues?.termAgreement && hasTermAgreementRequiredError,
            <InputWarning
              message='You must agree to the Terms and Conditions'
              css={{position: 'absolute', top: '20px', left: '20px'}}
            />,
          )}
        </label>
        <Button type='submit' disabled={!inputCompleted} css={{marginTop: '35px'}}>
          {t('sign_up.sign_up')}
        </Button>
      </form>
    </div>
  )
}
