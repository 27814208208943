import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doBATCH_SET} from '../state'

export const REQUEST_BATCH_FETCH = 'REQUEST_BATCH_FETCH'
export const doREQUEST_BATCH_FETCH = createDoRequestAction(REQUEST_BATCH_FETCH)

export const requestBatchFetchActionCreators = {
  doREQUEST_BATCH_FETCH,
}

export function* requestBatchFetch({payload}: any): SagaIterator {
  debug('saga*requestBatchFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        batchId: v.string().uuid().exist(),
        page: v.number().optional(),
        orderBy: v.string().optional(),
        orderDescending: v.boolean(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {batchId, accessToken, page, orderBy, orderDescending} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/batch-fetch`,
    accessToken,
    data: {
      batchId,
      page,
      orderBy,
      orderDescending,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_BATCH_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/batch-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(doBATCH_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_BATCH_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v1/web/batch-fetch',
      ...result,
    }),
  )
}

export function* batchFetchSaga() {
  yield takeEvery(REQUEST_BATCH_FETCH as any, requestBatchFetch)
}
