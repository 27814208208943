import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import CronParser from 'cron-parser'
import {selectTheme} from '../../store'
import {RIF, useClickOutside} from '../../lib'
import {ButtonMore, ReminderRemovePop} from '..'
import {TaskReminder} from '../../model'
import {BellIcon} from '../../asset/image'

export interface ReminderTagPropsT {
  setDisplayReminderPop: Dispatch<SetStateAction<boolean>>
  setEditingReminder: Dispatch<SetStateAction<TaskReminder | undefined>>
  reminder: TaskReminder
  index: number
  setReminderList: Dispatch<SetStateAction<TaskReminder[]>>
}

export const ReminderTag = (props: ReminderTagPropsT) => {
  const {color} = selectTheme()
  const {setDisplayReminderPop, setEditingReminder, reminder, index, setReminderList} = props

  const reminderType = reminder.type
  const [wording, setWording] = useState('')

  const [displayBrick, setDisplayBrick] = useState(false)
  const brickRef = useRef<HTMLInputElement | null>(null)
  useClickOutside(brickRef, () => setDisplayBrick(false))
  const [displayRemovePop, setDisplayRemovePop] = useState(false)

  const formatCronString = (cronParseObj: any) => {
    const {dayOfWeek, hour, minute} = cronParseObj.fields

    const timeString = `${hour[0].toString().padStart(2, '0')}:${minute[0].toString().padStart(2, '0')}`
    const AMPM = hour[0] < 12 ? 'AM' : 'PM'

    // (0 or 7 is Sun) from 'cron-parser'
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const formattedDaysOfWeek = dayOfWeek.map((index: number) => daysOfWeek[index])

    let formattedString = 'Repeats'

    if (dayOfWeek.length >= 7) {
      formattedString += ` daily at ${timeString} ${AMPM}`
    } else if (dayOfWeek.length === 1) {
      formattedString += ` every ${formattedDaysOfWeek[0]} at ${timeString} ${AMPM}`
    } else {
      const lastDayOfWeek = formattedDaysOfWeek.pop()
      formattedString += ` every ${formattedDaysOfWeek.join(', ')} and ${lastDayOfWeek} at ${timeString} ${AMPM}`
    }
    return formattedString
  }

  const formatISOString = (dateString: string) => {
    const utcDate = new Date(dateString)
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000
    return new Date(utcDate.getTime() + timezoneOffset).toLocaleString(undefined, options)
  }

  useEffect(() => {
    if (reminderType === 'cron') {
      const cronInterval = CronParser.parseExpression(reminder.value)
      setWording(formatCronString(cronInterval))
    }
    if (reminderType === 'date') {
      setWording(formatISOString(reminder.value))
    }
  }, [reminderType])

  const handleRemoveReminder = () => {
    setReminderList((prev) => prev.filter((item) => item !== reminder))
  }

  return (
    <div
      data-testid='reminder_tag'
      onClick={(e: any) => {
        if (e.target.id === 'removePopBackgroundId') return e.preventDefault()
        setDisplayReminderPop(true)
        setEditingReminder(reminder)
      }}
      key={reminder.value}
      css={{
        width: '100%',
        height: '70px',
        borderRadius: '5px',
        background: color.white,
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
        cursor: 'pointer',
        ':hover': {
          background: color.hover,
        },
      }}
    >
      <div css={{display: 'flex', alignItems: 'center'}}>
        <img src={BellIcon} width={20} height={20} />
        <div css={{marginLeft: '16px'}}>
          <p css={{color: color.grey_400, fontSize: '12px', marginBottom: '4px'}}>Reminder {index + 1}</p>
          <p>{wording}</p>
        </div>
      </div>

      <span
        ref={brickRef}
        css={{
          color: color.grey_400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '24px',
          position: 'relative',
          cursor: 'pointer',
          borderRadius: '4px',
        }}
      >
        <ButtonMore
          type='button'
          onClick={(e: any) => {
            setDisplayBrick(!displayBrick)
            e.stopPropagation()
          }}
        />
        {RIF(
          displayBrick,
          <div
            css={{
              width: '88px',
              height: 'fit-content',
              padding: '5px 00px',
              border: `1px solid ${color.grey_160}`,
              borderRadius: '5px',
              position: 'absolute',
              right: 0,
              top: 25,
              background: color.white,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              onClick={(e: any) => {
                setDisplayRemovePop(true)
                e.stopPropagation()
              }}
              id='displayRemoveBrickId'
              css={{
                width: '100%',
                padding: '6px',
                textAlign: 'center',
                ':hover': {
                  background: 'rgba(156, 158, 174, 0.05)',
                },
              }}
            >
              Remove
            </div>
          </div>,
        )}
      </span>

      {RIF(
        displayRemovePop,
        <ReminderRemovePop
          onClick={(e: any) => e.stopPropagation()}
          {...{
            closeAction: () => setDisplayRemovePop(false),
            doAction: handleRemoveReminder,
            reminder,
          }}
        />,
      )}
    </div>
  )
}
