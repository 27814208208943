import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doADHERENCE_PROJECT_PARTICIPANT_LIST_SET} from '../state'

export const REQUEST_PROJECT_PARTICIPANT_LIST = 'REQUEST_PROJECT_PARTICIPANT_LIST'
export const doREQUEST_PROJECT_PARTICIPANT_LIST = createDoRequestAction(REQUEST_PROJECT_PARTICIPANT_LIST)

export const requestProjectParticipantListActionCreators = {
  doREQUEST_PROJECT_PARTICIPANT_LIST,
}

export function* requestProjectParticipantList({payload}: any): SagaIterator {
  debug('saga*requestProjectParticipantList')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {projectId, accessToken } = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v2/web/project-participant-list`,
    accessToken,
    data: {
      projectId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_PARTICIPANT_LIST,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v2/web/project-participant-list',
        ...result,
        error,
      }),
    )
  }

  console.log('proejct participant list fetch: ', result.payload)

  yield put(
    doADHERENCE_PROJECT_PARTICIPANT_LIST_SET({
      projectId,
      ...result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_PARTICIPANT_LIST,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v2/web/project-participant-list',
      ...result,
    }),
  )
}

export function* projectParticipantListSaga() {
  yield takeEvery(REQUEST_PROJECT_PARTICIPANT_LIST, requestProjectParticipantList)
}
