import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {doPROJECT_SETTINGS_LIST_SET, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'

export const REQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH = 'REQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH'
export const doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH = createDoRequestAction(
  REQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
)

export const requestIdentityProjectSettingsListFetchActionCreators = {
  doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
}

interface Payload {
  type: string
  payload: {
    accessToken: string
    requestId: string
  }
}

export function* requestIdentityProjectSettingsListFetch({payload}: Payload): SagaIterator {
  debug('saga*requestIdentityProjectSettingsListFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {accessToken} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-project-settings-list-fetch`,
    accessToken,
    data: {},
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-project-settings-list-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(doPROJECT_SETTINGS_LIST_SET([...result.payload]))

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* identityProjectSettingsListFetchSaga() {
  yield takeEvery(REQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH, requestIdentityProjectSettingsListFetch)
}
