export enum CollectionType {
  DeviceLogdata = 'device_logdata',
  GarminConnectData = 'garmin_connect_data',
  AnalysisData = 'analysis_data',
}

export enum TaskDataType {
  ToDoTaskRecord = 'todo_task_record',
  TimerTaskRecord = 'timer_task_record',
  QuestionnaireTaskRecord = 'questionnaire_task_record',
  MovesenseStopwatchEvent = 'movesense_stopwatch_event',
  GarminStopwatchEvent = 'garmin_stopwatch_event',
}

export enum GarminDeviceLogDataType {
  GarminBBI = 'garmin_bbi',
  GarminEnhancedBBI = 'garmin_enhanced_bbi',
  GarminStep = 'garmin_step',
  GarminPulseOx = 'garmin_pulse_ox',
  GarminAcc = 'garmin_acc',
  GarminHeartRate = 'garmin_heart_rate',
  GarminRespiration = 'garmin_respiration',
  GarminStress = 'garmin_stress',
  GarminZeroCrossing = 'garmin_zero_crossing',
  GarminActigraphy = 'garmin_actigraphy',
  GarminActigraphy2 = 'garmin_actigraphy2',
  GarminActigraphy3 = 'garmin_actigraphy3',
}

export type GarminDeviceSubmitDataType = GarminDeviceLogDataType

export enum GarminDeviceStreamDataType {
  GarminAcc = 'garmin_acc',
}

export enum MovesenseDeviceStreamDataType {
  MovesenseImu = 'movesense_imu',
  MovesenseAcc = 'movesense_acc',
  MovesenseGyroscope = 'movesense_gyroscope',
  MovesenseMagnetometer = 'movesense_megetometer',
  MovesenseEcg = 'movesense_ecg',
  MovesenseRr = 'movesense_rr',
}

export enum DexcomDeviceDataType {
  DexcomRecord = 'dexcom_record',
}

export enum AnalysisDataType {
  ActigraphySleep = 'actigraphy_sleep',
  HRV = 'hrv',
}

export enum DeviceDailyDataType {
  GarminDailySummary = 'garmin_daily_summary',
}
