import {IndividualCorrelationResultItem} from '../../../shared/analysis'
import {CorrelationMatrix} from './base/correlation_matrix'
import {CorrelationGraphItem, CorrelationGraphProps} from './model/correlation_graph'
import {CorrelationGraph} from './base/correlation_graph'
import {IndividualCorrelationLatexConverter} from './conversion/latex_converter'
import {CorrelationMatrixNoRenderProps} from './model/correlation_matrix'

const renderItem = <I extends CorrelationGraphItem<I['data']>>({
  item,
  convertLatex,
  props: {itemStyle: style, onClickGraph: onClick},
}: Pick<CorrelationGraphProps<I>, 'item' | 'convertLatex'> & {
  props: CorrelationMatrixNoRenderProps<I>
}) => {
  return <CorrelationGraph item={item} convertLatex={convertLatex} style={style} onClick={onClick} />
}

export const IndividualCorrelationMatrix = (props: CorrelationMatrixNoRenderProps<IndividualCorrelationResultItem>) => {
  return (
    <CorrelationMatrix
      {...props}
      renderItem={(item) => renderItem({item, convertLatex: IndividualCorrelationLatexConverter, props})}
    />
  )
}
