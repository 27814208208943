// ratios generated using minor-scale on https://type-scale.com
// 1rem = 16px , 1em = 14px
const fontSize = {
  base: '1em', // 14px
  h1: '1.383em',
  h2: '1.296em',
  h3: '1.4286em', // =~20px
  h4: '1.2857em', // =~18px
  h5: '1.1429em', // =~16px
  h6: '1.0000em', // =~14px
  h7: '0.8571em', // =~12px
  h8: '0.7143em', // =~10px
}

const fontWeight = {
  thin: 200,
  normal: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  thick: 700,
}

const color = {
  primary: '#3455F4',
  black: '#1F1F1F',
  white: '#FFFFFF',
  transparent: '#00000000',

  grey_600: '#676973',
  grey_400: '#9A9BA2',
  grey_300: '#B3B4B9',
  grey_160: '#D6D7DA',
  grey_100: '#E6E6E8',
  grey_80: '#EBEBEC',
  grey_70: '#EDEEEF',
  grey_60: '#F0F0F1',
  grey_50: '#F2F3F3',
  grey_20: '#FAFAFA',

  attention: '#F1906E',
  warning: '#EB7257',
  upgrade: '#FDB90B',
  highlightGreen: '#5DAE7F',

  disabled: '#C2C3C7',
  background: '#F7F8F8',
  hover: '#F5F5F6',
  lightboxBackground: '#00000040',
  backgroundBlue: 'rgba(239, 241, 254, 1)',
  eletricBlue: 'rgba(52, 85, 244, 1)',

  // task color
  taskGreen: '#65BD8A',
  taskYellow: '#ECB751',
  taskPurple: '#9AABFF',
  taskOrange: '#F1906E',
  taskBlue: '#8EC7FC',
  taskRed: '#EC5151',
  taskGrey: '#9C9EAE',

  // TODO: check figma, after ACC implementation should be removed
  paleBlue: '#3455F40F', // use in garmin device background
  dexcomGreen: '#63A844',
  lightYellow: '#FFF9EB',

  borderLight: '#EBEBEC',
  textIcon: {
    secondary: '#676973',
    light: '#9A9BA2',
    link: '#3455F4',
  },
  border: {
    _80: '#EBEBEC',
    _100: '#E6E6E8',
    _160: '#D6D7DA',
    blue: {
      blue: '#3455F4',
      dark: '#C2CCFC',
    },
  },
  surface: {
    dark: '#4D505C',
    blue: {
      light: '#F6F7FF',
    },
    grey: {
      background: '#F7F8F8',
      light: '#F7F8F8',
      dark: '#F2F3F3',
    },
    orange: {
      light: '#FEF6F3',
    },
    hover: {
      white: '#F6F6F6'
    },
  },
  tag: {
    blue: {
      light: '#EFF1FE',
      dark: '#5CAEFB',
    },
    yellow: {
      light: '#FDF5E2',
      dark: '#ECAE0D',
    }
  }
}

const tagColor = {
  Green: '#48A770',
  Yellow: '#ECAE0D',
  Purple: '#A57BFF',
  Blue: '#5CAEFB',
  Red: '#EC5151',
  Grey: '#808397',
  Orange: '#ED9442',
}

const tagBackgroundColorOpacity = 0.1

export const colorTranslator = (index) => {
  return taskColorList[index % 7]
}

const taskColorList = [
  '#65BD8A', // taskGreen
  '#ECB751', // taskYellow
  '#C8AEFF', // taskPurple
  '#F1906E', // taskOrange
  '#8EC7FC', // taskBlue
  '#EC5151', // taskRed
  '#9C9EAE', // taskGrey
]

const pad = {
  xs: '.25rem',
  small: '.3125rem', // 5px
  mediumSmall: '.625rem', // 10px
  medium: '.875rem', // 14px
  slightlyLarger: '1rem', // 16px
  large: '1.15rem', // 20px
  widthPadding: '1.5625rem', // 25px
  xl: '1.875rem', // 30px
}

export const create_theme_init_state = () => {
  return {
    pad,
    color,
    fontSize,
    fontWeight,
    taskColorList,
    tagColor,
    tagBackgroundColorOpacity,
    colorTranslator,
  }
}
