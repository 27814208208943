import { IEntityModel } from './base'
import { IProject } from './project'
import { ITask } from './task'
import { IWorkspace } from './workspace'

// NOTE: if you add anoter type make sure to remove it from getBatteryPercentageSupport if necessary
export enum GarminDeviceType {
  Vivosmart3 = 'vivosmart3',
  Vivosmart4 = 'vivosmart4',
  Fenix6 = 'fenix6',
  Fenix6Pro = 'fenix6Pro',
  Fenix6s = 'fenix6s',
  Fenix6sPro = 'fenix6sPro',
  Fenix6xPro = 'fenix6xPro',
  Venu = 'venu',
  VenuSq = 'venuSq',
  Fenix6ProSolar = 'fenix6ProSolar',
  Fenix6sProSolar = 'fenix6sProSolar',
  Fenix6xProSolar = 'fenix6xProSolar',
  Venu2 = 'venu2',
  Venu2s = 'venu2s',
  Venu2Plus = 'venu2Plus',
}

export interface IGarminDevice extends IEntityModel {
  taskId: string
  projectId: string
  workspaceId: string

  task?: ITask
  project?: IProject
  workspace?: IWorkspace

  type: GarminDeviceType

  batteryPercentageSupport: boolean

  bbiEnable: boolean
  enhancedBbiEnable: boolean
  stepsEnable: boolean
  stressEnable: boolean
  pulseOxEnable: boolean
  heartRateEnable: boolean
  respirationEnable: boolean
  accelerometerEnable: boolean
  zeroCrossingEnable: boolean
  actigraphyEnable: boolean

  zeroCrossingSampleRate: number
  stressSampleRate: number
  heartRateSampleRate: number
  pulseOxSampleRate: number
  respirationSampleRate: number
  actigraphySampleRate: number
  actigraphyZeroCrossingThreshold: number
  actigraphyEnergyThreshold: number
}
