import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doMETHOD_ADD_TASK, doTEMP_TASK_DATA_REPLACE_TEMP_TASK} from '../state'

export const REQUEST_TASK_TODO_CREATE = 'REQUEST_TASK_TODO_CREATE'
export const doREQUEST_TASK_TODO_CREATE = createDoRequestAction(REQUEST_TASK_TODO_CREATE)

export const requestTaskTodoCreateActionCreators = {
  doREQUEST_TASK_TODO_CREATE,
}

interface Payload {
  payload: {
    accessToken: string
    requestId: string
    name: string
    description?: string
    methodId: string
    scheduleRepeat: boolean
    scheduleDescription: string
    reminderList?: Record<string, string>[]
    color: string
    tempTaskId?: string
  }
}

export function* requestTaskTodoCreate({payload}: Payload): SagaIterator {
  debug('saga*requestTaskTodo')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        name: v.string().exist(),
        description: v.string().optional(),
        methodId: v.string().uuid().exist(),
        scheduleRepeat: v.boolean(),
        scheduleDescription: v.string().optional(),
        reminderList: v.array().optional(),
        color: v.string().exist(),
        tempTaskId: v.string().uuid().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {
    name,
    description,
    methodId,
    scheduleRepeat,
    scheduleDescription,
    reminderList,
    accessToken,
    color,
    tempTaskId,
  } = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-todo-create`,
    accessToken,
    data: {
      name,
      description,
      methodId,
      scheduleRepeat,
      scheduleDescription,
      reminderList,
      color,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_TODO_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/task-todo-create',
        ...result,
        error,
      }),
    )
  }

  if (tempTaskId) {
    yield put(
      doTEMP_TASK_DATA_REPLACE_TEMP_TASK({
        tempTaskId,
        newId: result.payload.id,
      }),
    )
  } else {
    yield put(doMETHOD_ADD_TASK({...result.payload}))
  }

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* taskTodoCreateSaga() {
  yield takeEvery(REQUEST_TASK_TODO_CREATE as any, requestTaskTodoCreate)
}
