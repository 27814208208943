import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {doBATCH_PARTICIPANT_TAG_LIST_ADD, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'

export const REQUEST_PARTICIPANT_TAG_ADD = 'REQUEST_PARTICIPANT_TAG_ADD'
export const doREQUEST_PARTICIPANT_TAG_ADD = createDoRequestAction(REQUEST_PARTICIPANT_TAG_ADD)

export const requestParticipantTagAddActionCreators = {
  doREQUEST_PARTICIPANT_TAG_ADD,
}

export function* requestParticipantTagAdd({payload}: any): SagaIterator {
  debug('saga*requestParticipantTagAdd')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        batchId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        tagIds: v.array().items(v.string().uuid().exist()).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {accessToken, participantId, batchId, tagIds} = payload
  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/participant-tag-add`,
    accessToken,
    data: {
      participantId,
      tagIds,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_TAG_ADD,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/participant-tag-add',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doBATCH_PARTICIPANT_TAG_LIST_ADD({
      batchId,
      participantId,
      tags: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* participantTagAddSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_TAG_ADD, requestParticipantTagAdd)
}
