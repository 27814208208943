import { Dispatch, SetStateAction } from 'react'
import {v4} from 'uuid'

/* create an actionCreator for updating state */
export const createAction = (type: string) => (payload?: any) => ({
  type,
  payload,
})

export interface CreateDoRequestActionInput {
  setRequestId: Dispatch<SetStateAction<null | string>>
  payload: {[key: string]: any}
}

interface CreateDoRequestActionOutput {
  [key: string]: any
}

/* create an actionCreator for creating api requests with error handling */
export const createDoRequestAction =
  (type: string) =>
  ({setRequestId, payload}: CreateDoRequestActionInput): CreateDoRequestActionOutput => {
    const requestId: string = v4()
    setRequestId(requestId)
    return {
      type,
      payload: {
        ...payload,
        requestId,
      },
    }
  }
