import {
  assertPartialSchema,
  createAction,
  useSelector,
  v,
  setSessionStorage,
  loadSessionStorage,
  t,
  copyArrayWithReplacedItem,
} from '../../../lib'
import {IBatch, IParticipant, ITag} from '../../../shared/db'

export enum BatchActionType {
  BATCH_SET = 'BATCH_SET',
  BATCH_DELETE = 'BATCH_DELETE',
  BATCH_RESET_PARTICIPANT = 'BATCH_RESET_PARTICIPANT',
  BATCH_ADD_PARTICIPANT = 'BATCH_ADD_PARTICIPANT',
  BATCH_UPDATE_PARTICIPANT = 'BATCH_UPDATE_PARTICIPANT',
  BATCH_DELETE_PARTICIPANT = 'BATCH_DELETE_PARTICIPANT',
  BATCH_PARTICIPANT_CREDENTIALS_UPDATE = 'BATCH_PARTICIPANT_CREDENTIALS_UPDATE',
  BATCH_PARTICIPANT_TAG_LIST_SET = 'BATCH_PARTICIPANT_TAG_LIST_SET',
  BATCH_PARTICIPANT_TAG_LIST_ADD = 'BATCH_PARTICIPANT_TAG_LIST_ADD',
  BATCH_PARTICIPANT_TAG_LIST_UPDATE = 'BATCH_PARTICIPANT_TAG_LIST_UPDATE',
  BATCH_PARTICIPANT_TAG_LIST_REMOVE = 'BATCH_PARTICIPANT_TAG_LIST_REMOVE',
}

export const doBATCH_SET = createAction(BatchActionType.BATCH_SET)
export const doBATCH_DELETE = createAction(BatchActionType.BATCH_DELETE)
export const doBATCH_RESET_PARTICIPANT = createAction(BatchActionType.BATCH_RESET_PARTICIPANT)
export const doBATCH_ADD_PARTICIPANT = createAction(BatchActionType.BATCH_ADD_PARTICIPANT)
export const doBATCH_UPDATE_PARTICIPANT = createAction(BatchActionType.BATCH_UPDATE_PARTICIPANT)
export const doBATCH_DELETE_PARTICIPANT = createAction(BatchActionType.BATCH_DELETE_PARTICIPANT)
export const doBATCH_PARTICIPANT_CREDENTIALS_UPDATE = createAction(BatchActionType.BATCH_PARTICIPANT_CREDENTIALS_UPDATE)
export const doBATCH_PARTICIPANT_TAG_LIST_SET = createAction(BatchActionType.BATCH_PARTICIPANT_TAG_LIST_SET)
export const doBATCH_PARTICIPANT_TAG_LIST_ADD = createAction(BatchActionType.BATCH_PARTICIPANT_TAG_LIST_ADD)
export const doBATCH_PARTICIPANT_TAG_LIST_UPDATE = createAction(BatchActionType.BATCH_PARTICIPANT_TAG_LIST_UPDATE)
export const doBATCH_PARTICIPANT_TAG_LIST_REMOVE = createAction(BatchActionType.BATCH_PARTICIPANT_TAG_LIST_REMOVE)

interface BatchState {
  [key: string]: any
}

interface RootState {
  batch: BatchState
}

/* selector */
export const selectBatchData = () => {
  return useSelector((state: RootState) => state.batch)
}

export const batchActionCreators = {
  doBATCH_SET,
  doBATCH_DELETE,
  doBATCH_RESET_PARTICIPANT,
  doBATCH_ADD_PARTICIPANT,
  doBATCH_UPDATE_PARTICIPANT,
  doBATCH_DELETE_PARTICIPANT,
  doBATCH_PARTICIPANT_CREDENTIALS_UPDATE,
  doBATCH_PARTICIPANT_TAG_LIST_ADD,
  doBATCH_PARTICIPANT_TAG_LIST_REMOVE,
  doBATCH_PARTICIPANT_TAG_LIST_SET,
}

export const batchDefaultState: BatchState = {}

type Action =
  | {
      type: BatchActionType.BATCH_SET
      payload: {
        id: string
        name: string
        description: string
        workspaceId: string
        projectId: string
        methodId: string
        participantList: Record<string, any>[]
      }
    }
  | {
      type: BatchActionType.BATCH_DELETE
      payload: {
        id: string
      }
    }
  | {
      type: BatchActionType.BATCH_RESET_PARTICIPANT
      payload: {
        id: string
      }
    }
  | {
      type: BatchActionType.BATCH_ADD_PARTICIPANT
      payload: {
        batchId: string
        participant: Record<string, any>
      }
    }
  | {
      type: BatchActionType.BATCH_UPDATE_PARTICIPANT
      payload: {
        batchId: string
        participant: Record<string, any>
      }
    }
  | {
      type: BatchActionType.BATCH_DELETE_PARTICIPANT
      payload: {
        batchId: string
        participantId: string
      }
    }
  | {
      type: BatchActionType.BATCH_PARTICIPANT_CREDENTIALS_UPDATE
      payload: {
        batchId: string
        participantId: string
        credentials: {
          participantId: string
          loginCode: string
          expiresInMilliseconds: number
          expiresUnixTimestamp: number
        }
      }
    }
  | {
      type: BatchActionType.BATCH_PARTICIPANT_TAG_LIST_ADD
      payload: {
        batchId: string
        participantId: string
        tags: ITag[]
      }
    }
  | {
      type: BatchActionType.BATCH_PARTICIPANT_TAG_LIST_SET
      payload: {
        batchId: string
        participantId: string
        tags: ITag[]
      }
    }
  | {
      type: BatchActionType.BATCH_PARTICIPANT_TAG_LIST_UPDATE
      payload: {
        batchId?: string
        projectId?: string
        participantId?: string
        tags: ITag[]
      }
    }
  | {
      type: BatchActionType.BATCH_PARTICIPANT_TAG_LIST_REMOVE
      payload: {
        batchId?: string
        projectId?: string
        participantId?: string
        tagIds: string[]
      }
    }


export const batchReducer = (state = batchDefaultState, {type, payload}: Action): BatchState => {
  if (!Object.values(BatchActionType).includes(type)) {
    return state
  }
  const newState = {...state}
  switch (type) {
    case BatchActionType.BATCH_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          name: v.string().optional(),
          description: v.string().optional().allow(null, ''),
          workspaceId: v.string().uuid().optional(),
          projectId: v.string().uuid().optional(),
          methodId: v.string().uuid().optional().allow(null, ''),
          participantList: v.array().optional(),
        }),
      })
      // store to same id (res batch already in reducer)
      const batch = newState[payload.id] as IBatch
      const newParticipantList = (payload.participantList ? [...payload.participantList] : []) as IParticipant[]
      if (batch && batch.id === payload.id) {
        const updatedParticipantList = (batch.participantList ?? [])
          .concat(newParticipantList)
          .reduce((resultArray: IParticipant[], currentParticipant) => {
            const index = resultArray.findIndex(({id}) => id === currentParticipant.id)
            if (index !== -1) {
              resultArray[index] = currentParticipant
            } else {
              resultArray.push(currentParticipant)
            }
            return resultArray
          }, [])
        newState[payload.id] = {
          ...batch,
          participantList: updatedParticipantList
        }
      } else {
        newState[payload.id] = {
          ...payload,
          participantList: newParticipantList,
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })
      const {id} = payload
      const batch = newState[id] as IBatch
      if (batch && batch.participantList?.length) {
        const participantList = batch.participantList
        setSessionStorage({
          totalParticipants: loadSessionStorage()?.totalParticipants - participantList.length,
        })
      }
      delete newState[id]
      return {...newState}
    }
    case BatchActionType.BATCH_RESET_PARTICIPANT: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })
      const {id} = payload
      const batch = newState[id] as IBatch
      if (batch) {
        if (batch.participantList?.length) {
          const participantList = batch.participantList
          setSessionStorage({
            totalParticipants: loadSessionStorage()?.totalParticipants - participantList.length,
          })
        }
        newState[id] = {
          ...batch,
          participantList: []
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_ADD_PARTICIPANT: {
      assertPartialSchema({
        payload,
        schema: v.object({
          batchId: v.string().uuid().exist(),
          participant: v.object().exist(),
        }),
      })
      const {batchId} = payload
      const participant = payload.participant as IParticipant
      const batch = newState[batchId] as IBatch
      if (batch) {
        newState[batchId] = {
          ...batch,
          participantList: [...(batch.participantList ?? []), participant]
        }
        setSessionStorage({totalParticipants: loadSessionStorage()?.totalParticipants + 1})
      }
      return {...newState}
    }
    case BatchActionType.BATCH_UPDATE_PARTICIPANT: {
      assertPartialSchema({
        payload,
        schema: v.object({
          batchId: v.string().uuid().exist(),
          participant: v.object().exist(),
        }),
      })
      const {batchId} = payload
      const participant = payload.participant as IParticipant
      const batch = newState[batchId] as IBatch
      if (batch && batch.participantList?.length) {
        newState[batchId] = {
          ...batch,
          participantList: copyArrayWithReplacedItem({
            array: batch.participantList,
            predicate: (({id}) => id === participant.id),
            value: (target) => {
              return {
                ...participant,
                credentials: target.credentials, // TODO: remove this when BE res credential with API participant-update
              }
            }
          })
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_DELETE_PARTICIPANT: {
      assertPartialSchema({
        payload,
        schema: v.object({
          batchId: v.string().uuid().exist(),
          participantId: v.string().uuid().exist(),
        }),
      })
      const {batchId, participantId} = payload
      const batch = newState[batchId] as IBatch
      if (batch && batch.participantList?.length) {
        const participantList = batch.participantList
        const participantIndex = participantList.findIndex(({id}) => id === participantId)
        if (participantIndex !== -1) {
          participantList.splice(participantIndex, 1)
          newState[batchId] = {
            ...batch,
            participantList: [...participantList]
          }
          setSessionStorage({totalParticipants: loadSessionStorage()?.totalParticipants - 1})
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_PARTICIPANT_CREDENTIALS_UPDATE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          batchId: v.string().uuid().exist(),
          participantId: v.string().uuid().exist(),
          credentials: v.object({
            participantId: v.string().uuid().exist(),
            loginCode: v.string().optional(),
            expiresInMilliseconds: v.number().optional(),
            expiresUnixTimestamp: v.number().optional(),
          }),
        }),
      })
      const {batchId, participantId, credentials} = payload
      const batch = newState[batchId] as IBatch
      if (batch && batch.participantList?.length) {
        newState[batchId] = {
          ...batch,
          participantList: copyArrayWithReplacedItem({
            array: batch.participantList,
            predicate: (({id}) => id === participantId),
            value: (target) => {
              return {
                ...target,
                credentials: credentials
              }
            }
          })
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_PARTICIPANT_TAG_LIST_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          batchId: v.string().uuid().exist(),
          participantId: v.string().uuid().exist(),
          tags: v.array().items(v.object()).exist(),
        }),
      })
      const {batchId, participantId} = payload
      const tags = [...payload.tags]
      if (tags.length) {
        const batch = newState[batchId] as IBatch
        if (batch && batch.participantList?.length) {
          newState[batchId] = {
            ...batch,
            participantList: copyArrayWithReplacedItem({
              array: batch.participantList,
              predicate: (({id}) => id === participantId),
              value: (target) => {
                return {
                  ...target,
                  tagList: [...tags.sort((a, b) => a.index - b.index)]
                }
              }
            })
          }
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_PARTICIPANT_TAG_LIST_ADD: {
      assertPartialSchema({
        payload,
        schema: v.object({
          batchId: v.string().uuid().exist(),
          participantId: v.string().uuid().exist(),
          tags: v.array().items(v.object()).exist(),
        }),
      })
      const {batchId, participantId} = payload
      const newTagList = [...payload.tags]
      if (newTagList.length) {
        const updateTagList = (currentTagList: ITag[] | undefined, newTagList: ITag[]): ITag[] => {
          return  (currentTagList ?? [])
            .concat(newTagList)
            .reduceRight((resultArray: ITag[], currentTag:ITag) => {
              if (!resultArray.find(({id}) => id === currentTag.id)) {
                resultArray.push(currentTag)
              }
              return resultArray
            }, [])
            .sort((a, b) => a.index - b.index)
        }
        const batch = newState[batchId] as IBatch
        if (batch && batch.participantList?.length) {
          newState[batchId] = {
            ...batch,
            participantList: copyArrayWithReplacedItem({
              array: batch.participantList,
              predicate: (({id}) => id === participantId),
              value: (target) => {
                return {
                  ...target,
                  tagList: updateTagList(target.tagList, newTagList)
                }
              }
            })
          }
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_PARTICIPANT_TAG_LIST_UPDATE: {
      assertPartialSchema({
        payload,
        schema: v
          .object({
            batchId: v.string().uuid(),
            projectId: v.string().uuid(),
            participantId: v.string().uuid(),
            tags: v.array().items(v.object()).exist(),
          })
          .without('projectId', ['batchId', 'participantId'])
          .and('batchId', 'participantId'),
      })
      const {projectId, tags: updatedTagList } = payload
      const updateTagList = (participant: IParticipant): IParticipant => {
        if (participant.tagList?.length) {
          const resultTagList = participant.tagList
            .reduce((resultArray: ITag[], currentTag: ITag) => {
              const updatedTag = updatedTagList.find(({id}) => id === currentTag.id)
              resultArray.push(updatedTag ?? currentTag)
              return resultArray
            }, [])
            .sort((a, b) => a.index - b.index)
          return {
            ...participant,
            tagList: resultTagList
          }
        } else {
          return participant
        }
      }
      if (updatedTagList.length) {
        if (projectId) {
          const updatedBatchList = Object.values<IBatch>(newState)
            .filter((batch) => batch.projectId === projectId)
            .map((batch) => {
              return {
                ...batch,
                participantList: batch.participantList?.map(updateTagList)
              }
            })
          for (const batch of updatedBatchList) {
            newState[batch.id] = batch
          }
        } else {
          const batchId = t.unwrap(payload.batchId)
          const participantId = t.unwrap(payload.participantId)
          const batch = newState[batchId] as IBatch
          if (batch && batch.participantList?.length) {
            newState[batch.id] = {
              ...batch,
              participantList: copyArrayWithReplacedItem({
                array: batch.participantList,
                predicate: (({id}) => id === participantId),
                value: updateTagList
              })
            }
          }
        }
      }
      return {...newState}
    }
    case BatchActionType.BATCH_PARTICIPANT_TAG_LIST_REMOVE: {
      assertPartialSchema({
        payload,
        schema: v
          .object({
            batchId: v.string().uuid(),
            projectId: v.string().uuid(),
            participantId: v.string().uuid(),
            tagIds: v.array().items(v.string().uuid()).exist(),
          })
          .without('projectId', ['batchId', 'participantId'])
          .and('batchId', 'participantId'),
      })
      const {projectId, tagIds: targetRemoveIdList} = payload
      const removeTagList = (participant: IParticipant): IParticipant => {
        return {
          ...participant,
          tagList: participant.tagList
            ?.filter((tag) => !targetRemoveIdList.includes(tag.id))
            ?.sort((a, b) => a.index - b.index)
        }
      }
      if (targetRemoveIdList.length) {
        if (projectId) {
          const updatedBatchList = Object.values<IBatch>(newState)
            .filter((batch) => batch.projectId === projectId)
            .map((batch) => {
              return {
                ...batch,
                participantList: batch.participantList?.map(removeTagList)
              }
            })
          for (const batch of updatedBatchList) {
            newState[batch.id] = batch
          }
        } else {
          const batchId = t.unwrap(payload.batchId)
          const participantId = t.unwrap(payload.participantId)
          const batch = newState[batchId] as IBatch
          if (batch && batch.participantList?.length) {
            const participantList = batch.participantList
            const participantIndex = participantList.findIndex(({id}) => id === participantId)
            if (participantIndex !== -1) {
              removeTagList(participantList[participantIndex])
            }
            newState[batchId] = {
              ...batch,
              participantList: copyArrayWithReplacedItem({
                array: batch.participantList,
                predicate: (({id}) => id === participantId),
                value: removeTagList
              })
            }
          }
        }
      }
      return {...newState}
    }
    default:
      throw new Error(`batch action ${type} not handled`)
  }
}
