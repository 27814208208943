import {ProjectCollaboratorType, WorkspaceCollaboratorType} from '../../shared/db'
import {selectCollaborators, selectProfile} from '../../store'
import {_, useCurrentWorkspaceState} from '..'
import {useParams} from 'react-router-dom'

type RoleInWorkspace = WorkspaceCollaboratorType | 'owner'
export type RoleInProject = WorkspaceCollaboratorType | ProjectCollaboratorType | 'owner'

export const useRoleInProject = (arg?: {
  //will default to current selected project id (id in the url path)
  projectId?: string
}): RoleInProject => {
  const profile = selectProfile()
  const collaboratorState = selectCollaborators()
  const projectId = arg?.projectId || useParams().projectId
  const { workspaceId, workspace } = useCurrentWorkspaceState({projectId})
  if (!collaboratorState) {
    return WorkspaceCollaboratorType.Viewer
  }
  if (!workspaceId || !workspace) {
    return WorkspaceCollaboratorType.Viewer
  }
  if (!profile) {
    return WorkspaceCollaboratorType.Viewer
  }
  if (workspace.ownerProfile && workspace.ownerProfile.identityId === profile.identityId) {
    return 'owner'
  }
  if (collaboratorState[workspaceId]) {
    const collaborator = _.find(
      collaboratorState[workspaceId],
      ['identityId', profile.identityId]
    )
    if (collaborator) {
      if (collaborator.type !== 'project_collaborator' || !projectId) {
        return collaborator.type as RoleInWorkspace
      } else {
        const ProjectCollaborator = _.find(
          collaborator.projectCollaboratorList,
          ['projectId', projectId]
        )
        if (ProjectCollaborator) {
          return ProjectCollaborator.type
        }
      }
    }
  }
  return WorkspaceCollaboratorType.Viewer
}