import {concat, differenceBy, sortBy, uniqBy} from 'lodash'

import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'

export const PROJECT_SET = 'PROJECT_SET'
export const doPROJECT_SET = createAction(PROJECT_SET)

export const PROJECT_LIST_SET = 'PROJECT_LIST_SET'
export const doPROJECT_LIST_SET = createAction(PROJECT_LIST_SET)

export const PROJECT_DELETE = 'PROJECT_DELETE'
export const doPROJECT_DELETE = createAction(PROJECT_DELETE)

export const PROJECT_STATUS_UPDATE = 'PROJECT_STATUS_UPDATE'
export const doPROJECT_STATUS_UPDATE = createAction(PROJECT_STATUS_UPDATE)

export const PROJECT_TAG_LIST_SET = 'PROJECT_TAG_LIST_SET'
export const doPROJECT_TAG_LIST_SET = createAction(PROJECT_TAG_LIST_SET)

export const PROJECT_TAG_LIST_ADD = 'PROJECT_TAG_LIST_ADD'
export const doPROJECT_TAG_LIST_ADD = createAction(PROJECT_TAG_LIST_ADD)

export const PROJECT_TAG_LIST_REMOVE = 'PROJECT_TAG_LIST_REMOVE'
export const doPROJECT_TAG_LIST_REMOVE = createAction(PROJECT_TAG_LIST_REMOVE)

/* selector */
export const selectProjectData = () => {
  return useSelector((state) => state.project)
}

export const projectActionCreators = {
  doPROJECT_SET,
  doPROJECT_LIST_SET,
}

export const projectDefaultState = {}

const schema = v.object({
  id: v.string().uuid().exist(),
  name: v.string().exist().allow('', null),
  workspaceId: v.string().uuid().optional(),
  description: v.string().optional().allow('', null),
  investigator: v.string().optional().allow('', null),
  organization: v.string().optional().allow('', null),
  contactDescription: v.string().optional().allow('', null),
  participantInstructions: v.string().optional().allow('', null),
  status: v.string().optional(),
  methodList: v.array().optional(),
  batchList: v.array().optional(),
  tagList: v.array().optional(),
})

const projectTagSchema = v.object({
  id: v.string().uuid().exist(),
  tagList: v.array().exist(),
})

const assertProjectExists = (state, id) => {
  if (!Object.keys(state).includes(id)) {
    throw new Error(`project id ${id} not in state`)
  }
}

export const projectReducer = (state = projectDefaultState, {type, payload}) => {
  let newState = {...state}
  switch (type) {
    case PROJECT_SET:
      assertPartialSchema({
        payload,
        schema,
      })

      newState[payload.id] = payload
      return newState

    case PROJECT_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.array().items(schema),
      })

      payload.forEach((item) => {
        newState[item.id] = item
      })

      return newState

    case PROJECT_DELETE:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })

      delete newState[payload.id]
      return newState

    // for now only used in project reset,
    // project reset will auto change status by backend
    // do this action just because change project status previously
    // then it won't show orange live edit bar in project setup page for 1 sec
    case PROJECT_STATUS_UPDATE:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })
      newState[payload.id] = {
        ...newState[payload.id],
        status: 'draft',
      }
      return newState

    case PROJECT_TAG_LIST_SET:
      assertPartialSchema({
        payload,
        schema: projectTagSchema,
      })
      assertProjectExists(newState, payload.id)
      newState[payload.id] = {
        ...newState[payload.id],
        tagList: sortBy(payload.tagList, ({index}) => index),
      }
      return newState

    case PROJECT_TAG_LIST_ADD:
      assertPartialSchema({
        payload,
        schema: projectTagSchema,
      })
      assertProjectExists(newState, payload.id)
      newState[payload.id] = {
        ...newState[payload.id],
        tagList: sortBy(
          uniqBy(concat(payload.tagList, newState[payload.id].tagList), ({id}) => id),
          ({index}) => index,
        ),
      }
      return newState

    case PROJECT_TAG_LIST_REMOVE:
      assertPartialSchema({
        payload,
        schema: projectTagSchema,
      })
      assertProjectExists(newState, payload.id)
      newState[payload.id] = {
        ...newState[payload.id],
        tagList: sortBy(
          differenceBy(newState[payload.id].tagList, payload.tagList, ({id}) => id),
          ({index}) => index,
        ),
      }
      return newState

    default:
      return state
  }
}
