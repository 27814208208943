import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'
import {doAUTH_SET, doREQUEST_ERROR, doREQUEST_COMPLETE, doSETTINGS_SET} from '../state'

export const REQUEST_PROJECT_CONFIG_UPLOAD = 'REQUEST_PROJECT_CONFIG_UPLOAD'
export const doREQUEST_PROJECT_CONFIG_UPLOAD = createDoRequestAction(REQUEST_PROJECT_CONFIG_UPLOAD)

export const requestProjectConfigUploadActionCreators = {
  doREQUEST_PROJECT_CONFIG_UPLOAD,
}

export function* requestProjectConfigUpload({payload}: any): SagaIterator {
  debug('saga*requestProjectConfigUpload')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        workspaceId: v.string().uuid().exist(),
        participantCount: v.number().exist(),
        project: v.object().exist(),
        todoTaskList: v.array().optional(),
        timerTaskList: v.array().optional(),
        questionnaireTaskList: v.array().optional(),
        garminDeviceTask: v.object().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-config-upload`,
    data: payload,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_CONFIG_UPLOAD,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-config-upload',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* projectConfigUploadSaga() {
  yield takeEvery(REQUEST_PROJECT_CONFIG_UPLOAD, requestProjectConfigUpload)
}
