import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  SagaIterator,
  assertPartialSchema,
  call,
} from '../../lib'

import {insertGarminDirectDataToDb} from '../db/db_data_setter'
import {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doPARTICIPANT_GARMIN_TIMESERIES_DATA_SET,
  doPARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET,
} from '../state'

export const REQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH = 'REQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH'
export const doREQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH = createDoRequestAction(
  REQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH,
)

export const requestVisualizerGarminDirectDataFetchActionCreators = {
  doREQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH: doREQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH,
}

export function* requestVisualizerGarminDirectDataFetch({payload}: any): SagaIterator {
  debug('saga*requestVisualizerGarminDirectDataFetch')

  try {
    assertPartialSchema({
      payload,
      schema: v.object({
        participantId: v.string().uuid().required(),
        yymmddIndex: v.number().required().example(231011),
        garminDeviceTaskConfig: v.object().required(),
        dataTypeList: v.array().items(v.string().required()).optional().allow(null),
        projection: v.object().optional().allow(null),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantId, yymmddIndex, garminDeviceTaskConfig, dataTypeList, projection, accessToken} = payload

  // db check -> no data -> api fetch -> db set -> update state

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/garmin-direct-data-fetch`,
    accessToken,
    data: {
      participantId,
      yymmddIndex,
      dataTypeList,
      projection,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/garmin-direct-data-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doPARTICIPANT_GARMIN_TASK_TIMELINE_DATA_SET({
      participantId,
      yymmddIndex,
      garminDeviceTaskConfig,
      dataTypeList,
      data: result.payload,
    }),
  )

  const dbSetReuslt = yield call(async () => {
    return await insertGarminDirectDataToDb(
      participantId,
      yymmddIndex,
      dataTypeList,
      garminDeviceTaskConfig,
      result.payload,
      // true // store RawData
    )
  })

  yield put(
    doPARTICIPANT_GARMIN_TIMESERIES_DATA_SET({
      participantId,
      yymmddIndex,
      dbOperationResultList: dbSetReuslt,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result.payload,
    }),
  )
}

export function* visualizeGarminDirectDataFetchSaga() {
  yield takeEvery(REQUEST_VISUALIZER_GARMIN_DIRECT_DATA_FETCH, requestVisualizerGarminDirectDataFetch)
}
