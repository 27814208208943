import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'
import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const REQUEST_PARTICIPANT_FETCH = 'REQUEST_PARTICIPANT_FETCH'
export const doREQUEST_PARTICIPANT_FETCH = createDoRequestAction(REQUEST_PARTICIPANT_FETCH)

export const requestParticipantFetchActionCreators = {
  doREQUEST_PARTICIPANT_FETCH,
}

export function* requestParticipantFetch({payload}: any): SagaIterator {
  debug('saga*requestParticipantFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        accessToken: v.string().exist(),
        requestId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        requestId: payload.requestId,
        error,
      }),
    )
  }

  const {participantId, accessToken} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/participant-fetch`,
    accessToken,
    data: {
      participantId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/participant-fetch',
        ...result,
        error,
      }),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      requestId: payload.requestId,
      ...result,
    }),
  )
}

export function* participantFetchSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_FETCH, requestParticipantFetch)
}
