import {MathJax} from 'better-react-mathjax'
import Plot, {PlotParams} from 'react-plotly.js'

import {
  CorrelationGraphProps,
  CorrelationGraphItem,
  CorrelationGraphOnClickSourceEvent,
  CorrelationGraphStyle,
  CorrelationSubGraphProps,
} from '../model/correlation_graph'

type OnClickSource = (event: CorrelationGraphOnClickSourceEvent) => void

const CorrelationGraphStats = (props: {latex: string[]; style: CorrelationGraphStyle; onClick: OnClickSource}) => {
  const style: CorrelationGraphStyle = {
    ...props.style,
    flexDirection: 'column',
  }

  return (
    <div style={style}>
      {props.latex?.map((line, index) => (
        <MathJax key={index} onClick={props.onClick}>
          {line}
        </MathJax>
      ))}
    </div>
  )
}

const CorrelationGraphPlot = (props: {graph: string; style: CorrelationGraphStyle; onClick: OnClickSource}) => {
  const style: CorrelationGraphStyle = {
    ...props.style,
    color: undefined,
  }

  const params = JSON.parse(props.graph) as PlotParams

  // update listeners
  params.onClick = props.onClick

  // update layout
  params.layout = {
    ...params.layout,
    title: undefined,
    margin: {
      t: 0,
      b: 0,
      l: 0,
      r: 0,
      pad: 0,
    },
    paper_bgcolor: style.backgroundColor,
    plot_bgcolor: style.backgroundColor,
    yaxis: {
      visible: false,
    },
    xaxis: {
      visible: false,
    },
    showlegend: false,
  }

  // update config
  params.config = {
    ...params.config,
    editable: false,
    displaylogo: false,
    displayModeBar: false,
  }

  return <Plot {...params} style={style} />
}

const DefaultCorrelationGraphStyle: Readonly<CorrelationGraphStyle> = {
  width: '188px',
  height: '188px',
  color: '#9a9ba2', // only works for data panel
  backgroundColor: '#fafafa',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const CorrelationGraph = <I extends CorrelationGraphItem<I['data']>>(props: CorrelationGraphProps<I>) => {
  const style = {
    ...DefaultCorrelationGraphStyle,
    ...props.style,
  }

  const onClick = (event: CorrelationGraphOnClickSourceEvent) => {
    return props.onClick?.({
      ...event,
      coord: props.item.coord,
    })
  }

  const graph = props.item.graph
  if (graph) {
    return <CorrelationGraphPlot graph={graph} style={style} onClick={onClick} />
  }
  const latex = props.convertLatex(props.item)
  if (latex.length) {
    return <CorrelationGraphStats latex={latex} style={style} onClick={onClick} />
  }
  return <div style={style} />
}

export const CorrelationSubGraph = (props: CorrelationSubGraphProps) => (
  <CorrelationGraphPlot
    graph={props.item.graph}
    style={{
      ...DefaultCorrelationGraphStyle,
      ...props.style,
    }}
    onClick={(event) => props.onClick?.({...event, coord: props.item.coord})}
  />
)
