import {AccountPlan} from '../../model'
import {_, useCurrentWorkspaceState} from '..'
import {selectPurchase} from '../../store'

export const useCurrentWorkspaceAccountPlan = (arg?: {
  projectId?: string
}): AccountPlan | undefined => {
  const purchaseState = selectPurchase()
  const { workspaceId } = useCurrentWorkspaceState({ projectId: arg?.projectId })
  if (!workspaceId) {
    return undefined
  } else {
    const purchaseList = purchaseState[workspaceId] || []
    if (!purchaseList.length) return 'Free'
    if (_.find(purchaseList, (p) => p.product?.plan === 'advanced')) return 'Advanced'
    if (_.find(purchaseList, (p) => p.product?.plan === 'basic')) return 'Basic'
    return undefined
  }
}
