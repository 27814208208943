import {useEffect, Dispatch, SetStateAction} from 'react'
import {Controller} from 'react-hook-form'
import {selectTheme} from '../../store'
import {v, useForm, RIF, useCurrentWorkspaceAccountPlan} from '../../lib'
import {TaskStopwatchMovesenseStreamCopyT} from '../../model'
import {Label, RequiredTag, Select, Tooltip, Checkbox} from '..'
import {MotionTrackIcon, EcgIcon} from '../../asset/image'
import {components, OptionProps} from 'react-select'

const schema = v.object({
  imuHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
  accHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
  gyroscopeHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
  magnetometerHz: v.number().valid(13, 26, 52, 104, 208).optional().allow(null),
  ecgHz: v.number().valid(125, 250, 500).optional().allow(null),
  timeLimitSeconds: v.number().valid(300, 600, 900, 1800, 3600, 10800, 21600, 43200).optional().allow(null),
})

export interface AddTaskMovesenseSectionPropsT {
  movesenseStreamTaskCopy: TaskStopwatchMovesenseStreamCopyT
  setMovesenseStreamTaskCopy: Dispatch<SetStateAction<TaskStopwatchMovesenseStreamCopyT>>
  movesenseReadOnly: boolean
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

export const AddTaskMovesenseSection = (props: AddTaskMovesenseSectionPropsT) => {
  const {color, fontSize, fontWeight, pad} = selectTheme()

  const {movesenseStreamTaskCopy, setMovesenseStreamTaskCopy, movesenseReadOnly, setDisplayChangePlanPage} = props
  const accountPlan = useCurrentWorkspaceAccountPlan()

  const {
    imuEnable,
    imuHz,
    accEnable,
    accHz,
    gyroscopeEnable,
    gyroscopeHz,
    magnetometerEnable,
    magnetometerHz,
    ecgEnable,
    ecgHz,
    rrEnable,
    timeLimitSeconds,
  } = movesenseStreamTaskCopy

  const imuHzOption = [
    {value: 13, label: '12.5 Hz'},
    {value: 26, label: '26 Hz', isDisabled: accountPlan === 'Free'},
    {value: 52, label: '52 Hz', isDisabled: accountPlan === 'Free'},
    {value: 104, label: '104 Hz', isDisabled: accountPlan === 'Free'},
    {value: 208, label: '208 Hz', isDisabled: accountPlan === 'Free'},
  ]

  const ecgHzOptions = [
    {value: 125, label: '125 Hz'},
    {value: 250, label: '250 Hz', isDisabled: accountPlan === 'Free'},
    {value: 500, label: '500 Hz', isDisabled: accountPlan === 'Free'},
  ]

  const timeLimitSecondsOptions = [
    {value: 300, label: '5 min'},
    {value: 600, label: '10 min'},
    {value: 900, label: '15 min'},
    {value: 1800, label: '30 min', isDisabled: accountPlan === 'Free'},
    {value: 3600, label: '1 hr', isDisabled: accountPlan === 'Free'},
    {value: 10800, label: '3 hr', isDisabled: accountPlan === 'Free'},
    {value: 21600, label: '6 hr', isDisabled: accountPlan === 'Free'},
    {value: 43200, label: '12 hr', isDisabled: accountPlan === 'Free'},
  ]

  const convertToOptionsForm = (num: number) => {
    if (num == 13) return {value: num, label: '12.5 Hz'}
    return {value: num, label: `${num} Hz`}
  }

  const convertTaskMaxLengthValueToOptionFormat = (value: number | undefined) => {
    const option = timeLimitSecondsOptions.find((option) => option.value === value)
    return {value: value ?? 300, label: option?.label ?? '5 min'}
  }

  const {watch, control} = useForm({schema})

  const Option = (props: OptionProps) => {
    return (
      <components.Option
        {...props}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {props.label}
        {RIF(
          props.isDisabled,
          <div
            onClick={() => {
              if (props.isDisabled) {
                setDisplayChangePlanPage(true)
              }
            }}
            css={{
              borderRadius: '2px',
              color: 'white',
              padding: '4px 6px',
              background: color.upgrade,
              fontSize: fontSize.h8,
              cursor: 'pointer',
            }}
          >
            Upgrade
          </div>,
        )}
      </components.Option>
    )
  }

  useEffect(() => {
    setMovesenseStreamTaskCopy((prevState) => ({
      ...prevState,
      imuHz: watch('imuHz')?.value,
      accHz: watch('accHz')?.value,
      gyroscopeHz: watch('gyroscopeHz')?.value,
      magnetometerHz: watch('magnetometerHz')?.value,
      ecgHz: watch('ecgHz')?.value,
      timeLimitSeconds: watch('timeLimitSeconds')?.value,
    }))
  }, [
    watch('imuHz'),
    watch('accHz'),
    watch('gyroscopeHz'),
    watch('magnetometerHz'),
    watch('ecgHz'),
    watch('timeLimitSeconds'),
  ])

  useEffect(() => {
    if (watch('ecgHz')?.value !== 125) {
      setMovesenseStreamTaskCopy((prevState) => ({
        ...prevState,
        rrEnable: false,
      }))
    }
  }, [watch('ecgHz')])

  return (
    <>
      <div
        data-testid='add_task_ms_section'
        css={{
          marginBottom: '40px',
          borderTop: `1px solid ${color.grey_100}`,
          paddingTop: '35px',
        }}
      >
        <div css={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
          <Label css={{margin: 0}}>Data Type Selection </Label>
          <RequiredTag />
        </div>
        <div>Choose the sensors (and the corresponding resolution) you want to turn on.</div>
        <div css={{fontSize: '12px', color: color.grey_600, marginTop: '8px'}}>
          Warning: Choosing both 104Hz IMU + 500Hz ECG may exceed Bluetooth limits, risking data loss.
          <br />
          Tip: 52Hz IMU + 250Hz ECG meet most user needs. Require more frequent data? We suggest increasing just one
          parameter.
        </div>

        {/* motion tracking */}
        <section css={{borderRadius: '8px', background: color.white, padding: '16px', marginTop: '16px'}}>
          <div css={{display: 'flex', alignItems: 'center'}}>
            <img src={MotionTrackIcon} width='20' height='20' css={{marginRight: '8px'}} />
            <span>Motion Tracking</span>
          </div>
          <div css={{display: 'flex', justifyContent: 'space-between', marginTop: '25px'}}>
            <span css={{fontSize: '12px', color: color.grey_600}}>Data Type</span>
            <div css={{display: 'flex'}}>
              <span css={{fontSize: '12px', color: color.grey_600, marginRight: '4px'}}>Sampling Rate</span>
              <Tooltip content='Choose how often the data is collected.'/>
            </div>
          </div>

          {/* IMU */}
          <div
            css={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              backgroundColor: color.white,
              width: '100%',
              minHeight: '65px',
              padding: '14px 0',
            }}
          >
            <div css={{width: '75%'}}>
              <label css={{display: 'flex', alignItems: 'start', cursor: 'pointer'}}>
                <Checkbox
                  from='_movesense_imuEnable' // for RTL
                  value={imuEnable}
                  disabled={movesenseReadOnly}
                  onChange={(e: any) =>
                    setMovesenseStreamTaskCopy((prevState) => ({
                      ...prevState,
                      imuEnable: e,
                    }))
                  }
                />
                <div css={{marginLeft: pad.mediumSmall, width: 'fit-content'}}>
                  <div css={{fontWeight: fontWeight.medium}}>Inertial Measurement Unit (IMU)</div>
                  <div css={{color: color.grey_600, fontSize: '12px', marginTop: '8px'}}>
                    IMU combines the following data types synchronized in the same data file, making it easier to
                    process.
                    <div css={{marginTop: '6px', color: color.grey_600, fontSize: '12px'}}>
                      • Accelerometer (3-axis)
                    </div>
                    <div css={{marginTop: '6px', color: color.grey_600, fontSize: '12px'}}>• Gyrometer (3-axis)</div>
                    <div css={{marginTop: '6px', color: color.grey_600, fontSize: '12px'}}>• Magnetometer (3-axis)</div>
                  </div>
                </div>
              </label>
            </div>
            <Controller
              control={control}
              defaultValue={convertToOptionsForm(imuHz)}
              name='imuHz'
              render={({field: {name, value, onChange}}) => (
                <Select
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  isDisabled={!imuEnable || movesenseReadOnly}
                  options={imuHzOption}
                  components={{Option}}
                  css={{width: '30%', minWidth: '104px', marginLeft: '5px'}}
                />
              )}
            />
          </div>

          {/* UI update, leave IMU temporary */}

          {/* accelerometer */}
          {/* <div
            css={{
              borderBottom: `1px solid ${color.grey_100}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: color.white,
              width: '100%',
              minHeight: '65px',
              padding: '14px 0',
            }}>
            <div css={{width: '75%'}}>
              <label css={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <Checkbox
                  value={accEnable}
                  disabled={movesenseReadOnly}
                  onChange={(e: any) =>
                    setMovesenseStreamTaskCopy((prevState) => ({
                      ...prevState,
                      accEnable: e,
                    }))
                  }
                />
                <div css={{marginLeft: pad.mediumSmall, fontWeight: fontWeight.medium, width: 'fit-content'}}>
                  Accelerometer (3-axis)
                </div>
              </label>
            </div>
            <Controller
              control={control}
              defaultValue={convertToOptionsForm(accHz)}
              name='accHz'
              render={({field: {name, value, onChange}}) => (
                <Select
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  isDisabled={!accEnable || movesenseReadOnly}
                  options={imuHzOption}
                  css={{width: '25%', minWidth: '104px', marginLeft: '5px'}}
                />
              )}
            />
          </div> */}
          {/* gyroscope */}
          {/* <div
            css={{
              borderBottom: `1px solid ${color.grey_100}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: color.white,
              width: '100%',
              minHeight: '65px',
              padding: '14px 0',
            }}>
            <div css={{width: '75%'}}>
              <label css={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <Checkbox
                  value={gyroscopeEnable}
                  disabled={movesenseReadOnly}
                  onChange={(e: any) =>
                    setMovesenseStreamTaskCopy((prevState) => ({
                      ...prevState,
                      gyroscopeEnable: e,
                    }))
                  }
                />
                <div css={{marginLeft: pad.mediumSmall, fontWeight: fontWeight.medium, width: 'fit-content'}}>
                  Gyrometer (3-axis)
                </div>
              </label>
            </div>
            <Controller
              control={control}
              defaultValue={convertToOptionsForm(gyroscopeHz)}
              name='gyroscopeHz'
              render={({field: {name, value, onChange}}) => (
                <Select
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  isDisabled={!gyroscopeEnable || movesenseReadOnly}
                  options={imuHzOption}
                  css={{width: '25%', minWidth: '104px', marginLeft: '5px'}}
                />
              )}
            />
          </div> */}
          {/* Magnetometer */}
          {/* <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: color.white,
              width: '100%',
              minHeight: '65px',
              marginBottom: '-16px',
            }}>
            <div css={{width: '75%'}}>
              <label css={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <Checkbox
                  value={magnetometerEnable}
                  disabled={movesenseReadOnly}
                  onChange={(e: any) =>
                    setMovesenseStreamTaskCopy((prevState) => ({
                      ...prevState,
                      magnetometerEnable: e,
                    }))
                  }
                />
                <div css={{marginLeft: pad.mediumSmall, fontWeight: fontWeight.medium, width: 'fit-content'}}>
                  Magnometer (3-axis)
                </div>
              </label>
            </div>
            <Controller
              control={control}
              defaultValue={convertToOptionsForm(magnetometerHz)}
              name='magnetometerHz'
              render={({field: {name, value, onChange}}) => (
                <Select
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  isDisabled={!magnetometerEnable || movesenseReadOnly}
                  options={imuHzOption}
                  css={{width: '25%', minWidth: '104px', marginLeft: '5px'}}
                />
              )}
            />
          </div> */}
        </section>

        {/* ECG */}
        <section
          css={{
            borderRadius: '8px',
            background: color.white,
            padding: '16px',
            height: 'fit-content',
            marginTop: '16px',
          }}
        >
          <div css={{display: 'flex', alignItems: 'center'}}>
            <img src={EcgIcon} width='20' height='20' css={{marginRight: '8px'}} />
            <span>ECG</span>
          </div>
          <div css={{display: 'flex', justifyContent: 'space-between', marginTop: '25px'}}>
            <span css={{fontSize: '12px', color: color.grey_600}}>Data Type</span>
            <div css={{display: 'flex'}}>
              <span css={{fontSize: '12px', color: color.grey_600, marginRight: '4px'}}>Sampling Rate</span>
              <Tooltip content='Choose how often the data is collected.'/>
            </div>
          </div>

          <div
            css={{
              borderBottom: `1px solid ${color.grey_100}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: color.white,
              width: '100%',
              minHeight: '65px',
              padding: '14px 0',
            }}
          >
            <div css={{width: '75%'}}>
              <label css={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <Checkbox
                  from='_movesense_ecgEnable' // for RTL
                  value={ecgEnable}
                  disabled={movesenseReadOnly}
                  onChange={(e: any) =>
                    setMovesenseStreamTaskCopy((prevState) => ({
                      ...prevState,
                      ecgEnable: e,
                    }))
                  }
                />
                <div css={{marginLeft: pad.mediumSmall, fontWeight: fontWeight.medium, width: 'fit-content'}}>
                  ECG Waveform
                </div>
              </label>
            </div>
            <Controller
              control={control}
              defaultValue={convertToOptionsForm(ecgHz)}
              name='ecgHz'
              render={({field: {name, value, onChange}}) => (
                <Select
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  isDisabled={!ecgEnable || movesenseReadOnly}
                  options={ecgHzOptions}
                  components={{Option}}
                  css={{width: '25%', minWidth: '104px', marginLeft: '5px'}}
                />
              )}
            />
          </div>

          <div
            css={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              backgroundColor: color.white,
              width: '100%',
              minHeight: '65px',
              padding: '14px 0',
              marginBottom: '-16px',
            }}
          >
            <div css={{width: '75%'}}>
              <label css={{display: 'flex', alignItems: 'start', cursor: 'pointer'}}>
                <Checkbox
                  value={rrEnable}
                  disabled={movesenseReadOnly || ecgHz !== 125}
                  onChange={(e: any) =>
                    setMovesenseStreamTaskCopy((prevState) => ({
                      ...prevState,
                      rrEnable: e,
                    }))
                  }
                />
                <div css={{marginLeft: pad.mediumSmall, width: 'fit-content'}}>
                  <div css={{fontWeight: fontWeight.medium, color: ecgHz === 125 ? color.black : color.disabled}}>
                    RR Interval
                  </div>
                  <div
                    css={{color: ecgHz === 125 ? color.grey_600 : color.disabled, fontSize: '12px', marginTop: '8px'}}
                  >
                    Note: RR-interval only works with ECG at 125 Hz due to internal firmware limitations.
                  </div>
                </div>
              </label>
            </div>
            <div css={{color: ecgHz === 125 ? color.black : color.disabled}}>Each Beat</div>
          </div>
        </section>
      </div>
      {/* task max length */}
      <div
        css={{
          borderTop: `1px solid ${color.grey_100}`,
          paddingTop: '35px',
          marginTop: '35px',
          marginBottom: '40px',
        }}
        data-testid='task_max_length_section'
      >
        <div css={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
          <Label css={{margin: 0}}>Task Max Length</Label>
          <RequiredTag />
        </div>
        <div
          css={{
            color: color.grey_600,
            marginBottom: '20px',
          }}
        >
          In order to optimize battery usage, data storage, and participant experience, please specify the amount of
          time required. If the participant does not stop the task beforehand, the task will automatically stop after
          the specified time.
        </div>
        <Controller
          control={control}
          defaultValue={convertTaskMaxLengthValueToOptionFormat(timeLimitSeconds)}
          name='timeLimitSeconds'
          render={({field: {name, value, onChange}}) => (
            <Select
              {...{
                name,
                value,
                onChange,
              }}
              menuPlacement='top'
              options={timeLimitSecondsOptions}
              components={{Option}}
              css={{width: '25%', minWidth: '104px', marginLeft: '5px'}}
            />
          )}
        />
      </div>
    </>
  )
}
