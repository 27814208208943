import {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {_, t, useCurrentProjectState} from '../../lib'

import {
  selectAuth,
  selectBatchData,
  createDispatchActions,
  selectProjectData,
  selectAdherenceData,
  selectMethod,
} from '../../store'

import {Adherence, TaskStateType, TaskType} from '../../model'

export interface AttemptRequestParticipantStatusProps {
  clearCache?: boolean
  requestAdherence?: {durationBeforeToday: number}
  requestGarminDirectBbiDigest?: {yymmddIndexStart: number; yymmddIndexEnd: number}
  requestGarminConnectWearTime?: {yymmddIndexStart: number; yymmddIndexEnd: number}
}

export const AttemptRequestParticipantStatus = (props: AttemptRequestParticipantStatusProps) => {
  const {
    doREQUEST_BATCH_FETCH,
    doREQUEST_METHOD_FETCH,
    doREQUEST_PROJECT_DATA_DIGEST,
    doREQUEST_PROJECT_PARTICIPANT_LIST,
    doREQUEST_PROJECT_GARMIN_BBI_DATA_DIGEST_FETCH,
    doREQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH,
    doADHERENCE_DELETE,
  }: any = createDispatchActions()
  const {clearCache, requestAdherence, requestGarminDirectBbiDigest, requestGarminConnectWearTime} = props
  const navigate = useNavigate()
  const [requestId, setRequestId] = useState(null)
  const [requestBatchFetchId, setRequestBatchFetchId] = useState(null)

  const accessToken = selectAuth().accessToken
  const { projectId, project } = useCurrentProjectState()
  const batchId = project?.batchList?.[0]?.id
  const methodId = project?.methodList?.[0].id || ''
  const batchData = selectBatchData()
  const method = selectMethod()

  const garminDeviceTask: TaskStateType | undefined = _.find(method?.taskList, {type: TaskType.GarminDevice})
  const garminDeviceEnable: boolean = garminDeviceTask ? true : false
  const garminConnectEnabled: boolean = method?.garminConnectEnable
  const dexcomDataEnabled: boolean = method.dexcomIntegrationId ? true : false
  const movesenseDeviceEnabled: boolean = method?.movesenseDeviceEnable

  const projectAdherence = projectId ? selectAdherenceData()?.[projectId] : undefined
  const currentAdherenceList: Adherence[] = projectAdherence?.adherenceList || []

  useEffect(() => {
    if (!accessToken) return navigate('/')
  }, [])

  useEffect(() => {
    if (!batchId) return
    if (!batchData[batchId]) {
      doREQUEST_BATCH_FETCH({
        setRequestId: setRequestBatchFetchId,
        payload: {
          accessToken,
          batchId,
        },
      })
    }
  }, [batchId])

  useEffect(() => {
    if (methodId) {
      if (!method || method.id !== methodId) {
        doREQUEST_METHOD_FETCH({
          setRequestId,
          payload: {
            accessToken,
            methodId,
          },
        })
      }
    }
  }, [methodId])

  useEffect(() => {
    if (projectId) {
      if (clearCache) {
        doADHERENCE_DELETE({projectId: projectId})
        doREQUEST_PROJECT_PARTICIPANT_LIST({
          setRequestId,
          payload: {
            accessToken,
            projectId,
          },
        })
      } else if (!currentAdherenceList || currentAdherenceList.length == 0) {
        doREQUEST_PROJECT_PARTICIPANT_LIST({
          setRequestId,
          payload: {
            accessToken,
            projectId,
          },
        })
      }
    }
  }, [projectId])

  useEffect(() => {
    if (requestAdherence) {
      if (project && method.id && projectAdherence?.adherenceList) {
        const today = new Date()
        const queryStartDate = new Date(today)
        queryStartDate.setDate(queryStartDate.getDate() - requestAdherence.durationBeforeToday)
        const yymmIndexStart = convertDateToYYMMIndex(queryStartDate as Date)
        const yymmIndexEnd = convertDateToYYMMIndex(today as Date)
        const yymmIndexList = [...new Set([yymmIndexStart, yymmIndexEnd])]

        const needFetchData =
          yymmIndexList.filter((yymmIndex) => {
            return !projectAdherence.projectDataDigest?.coveredYYMMIndex.includes(yymmIndex)
          }).length > 0

        // task data fetch will inlcude all task digest so only need to do once
        // use coveredYYMMIndex to detemine whether task data has been fetched or not  
        const requestTaskData = !(projectAdherence.projectDataDigest?.coveredYYMMIndex) 

        if (needFetchData) {
          doREQUEST_PROJECT_DATA_DIGEST({
            setRequestId,
            payload: {
              accessToken,
              projectId,
              yymmIndexList: yymmIndexList,
              requestTaskData,
              requestGarminData: garminConnectEnabled || garminDeviceEnable,
              requestDexcomData: dexcomDataEnabled,
              garminTaskId: garminDeviceTask?.id,
            },
          })
        }
      }
    }
  }, [method.id, projectAdherence?.adherenceList])

  useEffect(() => {
    if (requestGarminDirectBbiDigest) {
      const {yymmddIndexStart, yymmddIndexEnd} = requestGarminDirectBbiDigest
      let requestYymmddIndexList: number[] = t.toYYMMDDRange(yymmddIndexStart, yymmddIndexEnd)

      if (projectAdherence?.projectDataDigest?.coveredGarminDirectBbiYymmddIndexList) {
        requestYymmddIndexList = requestYymmddIndexList.filter((yymmddIndex) => {
          return !projectAdherence?.projectDataDigest?.coveredGarminDirectBbiYymmddIndexList?.includes(yymmddIndex)
        })
      }

      if (requestYymmddIndexList.length) {
        doREQUEST_PROJECT_GARMIN_BBI_DATA_DIGEST_FETCH({
          setRequestId,
          payload: {
            accessToken,
            projectId,
            yymmddIndexList: requestYymmddIndexList,
          },
        })
      }
    }
  }, [requestGarminDirectBbiDigest])

  useEffect(() => {
    if (requestGarminConnectWearTime) {
      const {yymmddIndexStart, yymmddIndexEnd} = requestGarminConnectWearTime
      let requestYymmddIndexList: number[] = t.toYYMMDDRange(yymmddIndexStart, yymmddIndexEnd)

      if (projectAdherence?.projectDataDigest?.coveredGarminConnectWearTimeDataYymmddIndexList) {
        requestYymmddIndexList = requestYymmddIndexList.filter((yymmddIndex) => {
          return !projectAdherence?.projectDataDigest?.coveredGarminConnectWearTimeDataYymmddIndexList?.includes(yymmddIndex)
        })
      }

      if (requestYymmddIndexList.length) {
        doREQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH({
          setRequestId,
          payload: {
            accessToken,
            projectId,
            yymmddIndexList: requestYymmddIndexList,
          },
        })
      }
    }
  }, [requestGarminConnectWearTime])
}

const convertDateToYYMMIndex = (date: Date): number => {
  const year = date.getUTCFullYear() % 100 // take 2 digits
  const month = date.getUTCMonth() + 1
  return year * 100 + month
}
